import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MAILJET_TOKEN } from 'utils/common';
import { SubscriptionButton } from 'components';

import logo from 'images/logo.svg';
import facebook from 'images/facebook.svg';
import instagram from 'images/instagram.svg';
import linkedin from 'images/linkedin.svg';

function Footer() {
  const { t } = useTranslation();
  let mailjetFn = window.displayWidget;
  const year = new Date().getFullYear();

  const [isMjLoaded, setIsMjLoaded] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (typeof window.displayWidget === 'function') {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        mailjetFn = window.displayWidget;
        // activate eventListener on the button
        // this only activate once for all subscription buttons in the application
        mailjetFn();
        clearInterval(timer);
        setIsMjLoaded(true);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <footer className="Section Section--alabaster">
      <div className="Section-content">
        <div className="Footer">
          <div className="t-flex">
            <a href="/">
              <img src={logo} alt="logo" className="Logo" />
            </a>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6">
              <ul className="Footer-navigationList">
                <li className="Footer-navigationListItem">
                  <a href="/security" className="Footer-navigationAction">
                    {t('footer_safety')}
                  </a>
                </li>

                <li className="Footer-navigationListItem">
                  <a href="/pricing" className="Footer-navigationAction">
                    {t('footer_pricing')}
                  </a>
                </li>

                <li className="Footer-navigationListItem">
                  <a href="/imprint" className="Footer-navigationAction">
                    {t('footer_imprint')}
                  </a>
                </li>

                <li className="Footer-navigationListItem">
                  <a href="/privacy" className="Footer-navigationAction">
                    {t('footer_privacy')}
                  </a>
                </li>

                <li className="Footer-navigationListItem">
                  <a href="/terms" className="Footer-navigationAction">
                    {t('footer_terms')}
                  </a>
                </li>

                <li className="Footer-navigationListItem">
                  <a href="/contact" className="Footer-navigationAction">
                    {t('footer_contact')}
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-6 d-flex flex-column">
              <h2 className="Footer-title">
                {t('footer_title_subscribe').toUpperCase()}
              </h2>

              <p className="u-pb-20">{t('footer_text_subscribe')}</p>

              <SubscriptionButton
                onClick={mailjetFn}
                isVisible={isMjLoaded}
                dataToken={MAILJET_TOKEN}
              />
            </div>

            <div className="col-lg-2 col-md-6 offset-lg-1">
              <h2 className="Footer-title">
                {t('footer_title_mail').toUpperCase()}
              </h2>

              <div className="u-pb-20 u-flex-1">
                <a href="mailto:info@ninebarc.com" className="Footer-mail">
                  info@ninebarc.com
                </a>
              </div>

              <p>
                {t('footer_text_mail1')}
                <span className="u-color-persian-red"> ♥ </span>
                {t('footer_text_mail2', { year })}
              </p>
            </div>

            <div className="col-lg-2 col-md-6 d-flex justify-content-center">
              <div>
                <h2 className="Footer-title">
                  {t('footer_title_social').toUpperCase()}
                </h2>

                <ul className="d-flex">
                  <li className="u-mr-20">
                    <a
                      href="https://www.facebook.com/Ninebarc/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="u-noUnderline"
                    >
                      <img src={facebook} className="d-block" alt="facebook" />
                    </a>
                  </li>

                  <li className="u-mr-20">
                    <a
                      href="https://www.instagram.com/ninebarc/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="u-noUnderline"
                    >
                      <img
                        src={instagram}
                        className="d-block"
                        alt="instagram"
                      />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.linkedin.com/company/ninebarc"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="u-noUnderline"
                    >
                      <img src={linkedin} className="d-block" alt="linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
