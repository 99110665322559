import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FadeInUpAnimation } from 'components';

import safe from 'images/safe.png';
import safe_column1_mobile from 'images/safe-column1-mobile.png';
import security_column2_mobile from 'images/security-column2-mobile.png';
import security_column3_mobile from 'images/security-column3-mobile.png';
import key from 'images/key.svg';
import shield from 'images/shield.svg';
import smallTablet from 'images/small-tablet.svg';
import folder from 'images/folder.svg';
import fingerprint from 'images/fingerprint.svg';
import monitoring from 'images/monitoring.svg';

function SafetyPage() {
  const { t, i18n } = useTranslation();

  const [headerHeight, setHeaderHeight] = React.useState(0);

  const langResponsiveLink =
    i18n.language === 'en'
      ? 'https://gdpr-info.eu/'
      : 'https://dsgvo-gesetz.de/';

  const headRef = React.useRef<HTMLDivElement>(null);

  const getHeight = () => {
    if (headRef?.current?.clientHeight) {
      setHeaderHeight(headRef.current?.clientHeight);
    }
  };

  window.onresize = getHeight;
  window.onload = getHeight;

  React.useLayoutEffect(() => {
    getHeight();
  }, []);

  const dataFeatures = [
    {
      image: safe_column1_mobile,
      title: t('protection_title'),
      description: t('protection_description'),
      delay: 0.5,
    },
    {
      image: security_column2_mobile,
      title: t('access_title'),
      description: t('access_description'),
      delay: 0.7,
      link: langResponsiveLink,
      linkText: t('access_description_link_text'),
    },
    {
      image: security_column3_mobile,
      title: t('monitoring_title'),
      description: t('monitoring_description'),
      delay: 0.9,
    },
  ];

  return (
    <FadeInUpAnimation>
      <main className="Page-content">
        <FadeInUpAnimation>
          <div className="Section Section--white" ref={headRef}>
            <div
              className="t-absolute t-left-0 t-bg-alpha-100 t-w-full t-h-full"
              style={{ height: `${headerHeight}px` }}
            />
            <div className="Section-content t-relative">
              <div className="FirstBlock">
                <div className="row u-h-100">
                  <div className="col-lg-6 u-pt-10 u-pb-10 d-flex flex-column justify-content-center u-h-100">
                    <h1 className="Typography Typography--landingMainTitle u-weight-700 u-pb-30">
                      {t('safe_title')}
                    </h1>

                    <p className="Typography Typography--landingSubTitle">
                      {t('safe_description')}
                    </p>
                  </div>

                  <div className="col-lg-6 t-justify-end t-hidden lg:t-flex">
                    <div className="FirstBlock-image t-bottom-0 t-flex t-items-start">
                      <img
                        src={safe}
                        className="t-h-full"
                        alt=""
                        role="presentation"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInUpAnimation>

        <FadeInUpAnimation className="Section Section--white u-pt-120 u-pb-120 u-pt-md-30 u-pb-md-0">
          <div className="Section-content u-pb-20 u-pb-md-20">
            <div className="row u-text-sm-center">
              {dataFeatures.map(
                ({ image, title, delay, description, link, linkText }) => (
                  <FadeInUpAnimation
                    className="col-md-4 u-pb-30"
                    delay={delay}
                    key={title}
                  >
                    <div className="t-flex t-justify-center t-items-end md:t-justify-start t-h-72">
                      <img
                        className="u-pb-30 t-max-h-72 md:t-max-h-64 lg:t-max-h-72"
                        src={image}
                        alt=""
                        role="presentation"
                      />
                    </div>
                    <h2 className="Typography Typography--landingQuote u-weight-700 u-pb-16 u-pr-50 u-pr-md-0">
                      {title}
                    </h2>

                    <p className="Typography Typography--landingMain u-pr-50 u-pr-md-0">
                      {description}
                      {!!link && !!linkText && (
                        <a
                          href={link}
                          className="u-ml-4 u-color-jelly-bean u-noUnderline u-noOutline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {linkText}
                        </a>
                      )}
                    </p>
                  </FadeInUpAnimation>
                )
              )}
            </div>
          </div>

          <div className="Section-content d-flex justify-content-center u-pt-70 u-pb-70">
            <a
              href="https://app.ninebarc.de/register"
              className={`Button Button--primary Button--large`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('pricing:get_started')}
            </a>
          </div>
        </FadeInUpAnimation>

        <FadeInUpAnimation className="Section Section--white u-pb-40">
          <div className="Section-content">
            <h2 className="Typography Typography--landingTitleLower u-weight-700 u-text-center u-pb-80 u-pb-md-30">
              {t('safe_info_title')}
            </h2>

            <FadeInUpAnimation className="row t-flex t-justify-center">
              <div className="col-md-5">
                <div className="IconBlock">
                  <div className="IconBlock-header">
                    <div className="IconBlock-image">
                      <img src={fingerprint} alt="" role="presentation" />
                    </div>

                    <h3 className="Typography Typography--landingQuote u-weight-700">
                      {t('safe_info_access_title')}
                    </h3>
                  </div>

                  <div className="IconBlock-content">
                    <p className="Typography Typography--landingMain">
                      {t('safe_info_access_description')}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-5 offset-md-1">
                <div className="IconBlock">
                  <div className="IconBlock-header">
                    <div className="IconBlock-image">
                      <img src={monitoring} alt="" role="presentation" />
                    </div>

                    <h3 className="Typography Typography--landingQuote u-weight-700">
                      {t('safe_info_monitoring_title')}
                    </h3>
                  </div>

                  <div className="IconBlock-content">
                    <p className="Typography Typography--landingMain">
                      {t('safe_info_monitoring_description')}
                    </p>
                  </div>
                </div>
              </div>
            </FadeInUpAnimation>

            <FadeInUpAnimation
              className="row t-flex t-justify-center"
              delay={0.5}
            >
              <div className="col-md-5">
                <div className="IconBlock">
                  <div className="IconBlock-header">
                    <div className="IconBlock-image">
                      <img src={key} alt="" role="presentation" />
                    </div>

                    <h3 className="Typography Typography--landingQuote u-weight-700">
                      {t('safe_info_encription_title')}
                    </h3>
                  </div>

                  <div className="IconBlock-content">
                    <p className="Typography Typography--landingMain">
                      {t('safe_info_encription_description')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 offset-md-1">
                <div className="IconBlock">
                  <div className="IconBlock-header">
                    <div className="IconBlock-image">
                      <img src={shield} alt="" role="presentation" />
                    </div>

                    <h3 className="Typography Typography--landingQuote u-weight-700">
                      {t('safe_info_protocols_title')}
                    </h3>
                  </div>

                  <div className="IconBlock-content">
                    <p className="Typography Typography--landingMain">
                      {t('safe_info_protocols_description')}
                    </p>
                  </div>
                </div>
              </div>
            </FadeInUpAnimation>

            <FadeInUpAnimation
              className="row t-flex t-justify-center"
              delay={0.7}
            >
              <div className="col-md-5">
                <div className="IconBlock">
                  <div className="IconBlock-header">
                    <div className="IconBlock-image">
                      <img src={smallTablet} alt="" role="presentation" />
                    </div>

                    <h3 className="Typography Typography--landingQuote u-weight-700">
                      {t('safe_info_auth_title')}
                    </h3>
                  </div>

                  <div className="IconBlock-content">
                    <p className="Typography Typography--landingMain">
                      {t('safe_info_auth_description')}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-5 offset-md-1">
                <div className="IconBlock">
                  <div className="IconBlock-header">
                    <div className="IconBlock-image">
                      <img src={folder} alt="" role="presentation" />
                    </div>

                    <h3 className="Typography Typography--landingQuote u-weight-700">
                      {t('safe_info_password_title')}
                    </h3>
                  </div>

                  <div className="IconBlock-content">
                    <p className="Typography Typography--landingMain">
                      {t('safe_info_password_description')}
                    </p>
                  </div>
                </div>
              </div>
            </FadeInUpAnimation>
          </div>
        </FadeInUpAnimation>

        <FadeInUpAnimation className="Section Section--white ">
          <div className="Section-content u-pb-100 u-pb-md-30 t-w-full md:t-min-w-120">
            <div className="row t-w-full t-flex t-justify-center">
              <div className="col-md-5">
                <div className="FaqContact">
                  <h2 className="Typography Typography--landingQuote u-weight-700 u-pb-16">
                    {t('safe_contact_title')}
                  </h2>

                  <p className="Typography Typography--landingMain">
                    {t('safe_contact_description')}
                  </p>

                  <div className="FaqContact-button t-flex t-justify-center">
                    <Link
                      to="/contact"
                      className="Button Button--primary d-inline-flex"
                    >
                      {t('safe_contact_button')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInUpAnimation>
      </main>
    </FadeInUpAnimation>
  );
}

export default SafetyPage;
