import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContactsForm, FadeInUpAnimation } from 'components';

// the below is for next gen pictures
// import ImgNextGen from './ImgNextGen';
// import teamFallback from '../images/team.png';
// import teamWebP from '../images/team.png';
// import teamJrx from '../images/team.png';
// import teamJp2 from '../images/team.png';

import team1 from 'images/team1.jpg';
import team2 from 'images/team2.jpg';
import team3 from 'images/team3.jpg';
import team4 from 'images/team4.jpg';
import team5 from 'images/team5.jpg';
import team from 'images/img-team-header.png';
import people from 'images/people.png';
import author1 from 'images/author1.png';
import author2 from 'images/author2.png';
import author3 from 'images/author3.svg';

function AboutUsPage() {
  const { t } = useTranslation();

  const [headerHeights, setHeaderHeights] = React.useState({
    ref1: 0,
    ref2: 0,
    ref3: 0,
  });
  const contentRef1 = React.useRef<HTMLDivElement>(null);
  const contentRef2 = React.useRef<HTMLDivElement>(null);
  const contentRef3 = React.useRef<HTMLDivElement>(null);

  const getHeight = () => {
    Object.keys(headerHeights).forEach((key) => {
      const height =
        (key === 'ref1'
          ? contentRef1
          : key === 'ref2'
          ? contentRef2
          : contentRef3
        )?.current?.clientHeight || 0;
      setHeaderHeights((prev) => ({ ...prev, [key]: height }));
    });
  };

  window.onresize = getHeight;
  window.onload = getHeight;

  React.useLayoutEffect(() => {
    getHeight();
  }, []);

  return (
    <main className="Page-content">
      <FadeInUpAnimation>
        <div className="Section" ref={contentRef1}>
          <div
            className="t-absolute t-left-0 t-bg-alpha-100 t-w-full"
            style={{ height: `${headerHeights.ref1}px` }}
          />

          <div className="Section-content t-relative">
            <div className="FirstBlock">
              <div className="row u-h-100">
                <div className="col-lg-6 u-pt-10 u-pb-10 d-flex flex-column justify-content-center u-h-100">
                  <h1 className="Typography Typography--landingMainTitle u-weight-700 u-pb-30">
                    {t('team_title')}
                  </h1>

                  <p className="Typography Typography--landingSubTitle">
                    {t('team_description')}
                  </p>
                </div>

                <div className="col-lg-6 justify-content-end t-hidden lg:t-flex">
                  <div className="FirstBlock-image t-bottom-0 t-flex t-items-start">
                    <img
                      src={team}
                      className="t-h-full"
                      alt="Different green leaves and big text: Team"
                    />
                    {/* <ImgNextGen
                    srcWebp={teamWebP}
                    srcJrx={teamJrx}
                    srcJp2={teamJp2}
                    fallback={teamFallback}
                    alt="Different green leaves and big text: Team"
                    className="u-w-100"
                  /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInUpAnimation>

      <FadeInUpAnimation>
        <div className="Section" ref={contentRef2}>
          <div
            className="t-absolute t-left-0 t-bg-beta-50 t-w-full t-z-0"
            style={{ height: `${headerHeights.ref2}px` }}
          />
          <div className="Section-content u-pt-80 u-pb-120 u-pt-md-30 u-pb-md-30 t-relative">
            <h2 className="Typography Typography--landingMainTitle u-weight-700 u-pb-70 u-pb-md-20 ">
              {t('team_story_title')}
            </h2>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_paragraph1')}
            </p>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_paragraph2')}
            </p>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_paragraph3')}
            </p>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_paragraph4')}
            </p>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_paragraph5')}
            </p>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_paragraph6')}
            </p>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_paragraph7')}
            </p>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_paragraph8')}
            </p>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_paragraph9')}
            </p>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_paragraph10')}
            </p>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_paragraph11')}
            </p>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_paragraph12')}
            </p>

            <p className="Typography Typography--landingMain u-pb-24">
              {t('team_story_author1')}
            </p>

            <div className="row d-flex justify-content-center">
              <div className="col-3 col-md-2">
                <p className="Typography Typography--landingMain">
                  {t('team_story_author2')}
                </p>
              </div>

              <div className="col-3 col-md-2 offset-lg-1">
                <p className="Typography Typography--landingMain">
                  {t('team_story_author3')}
                </p>
              </div>

              <div className="col-3 col-md-2 offset-lg-2">
                <p className="Typography Typography--landingMain">
                  {t('team_story_author4')}
                </p>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-4 col-md-2 offset-lg-1">
                <img
                  className="u-w-100"
                  alt=""
                  src={author1}
                  role="presentation"
                />
              </div>

              <div className="col-4 col-md-3 offset-lg-1">
                <img
                  className="u-w-100"
                  alt=""
                  src={author2}
                  role="presentation"
                />
              </div>

              <div className="col-4 col-md-3 offset-lg-1">
                <img
                  className="u-w-100"
                  alt=""
                  src={author3}
                  role="presentation"
                />
              </div>
            </div>
          </div>
        </div>
      </FadeInUpAnimation>

      <FadeInUpAnimation className="Section Section--white">
        <div className="Section-content u-pt-120 u-pb-80 u-pt-md-30 u-pb-md-30">
          <h2 className="Typography Typography--landingMainTitle u-weight-700 u-pb-30">
            {t('team_block_title')}
          </h2>

          <ul className="row d-flex justify-content-center">
            <li className="col-lg-3 col-md-4 col-6 u-pb-20">
              <figure className="CrewGroup">
                <div className="CrewGroup-image">
                  <img
                    className="u-w-100"
                    alt=""
                    src={team1}
                    aria-labelledby="team1"
                  />
                </div>
                <figcaption className="u-text-center" id="team1">
                  <p className="u-pt-20 u-weight-700">{t('team1_name')}</p>
                  <p>{t('team1_position')}</p>
                </figcaption>
              </figure>
            </li>
            <li className="col-lg-3 col-md-4 col-6 offset-lg-1 u-pb-20">
              <figure className="CrewGroup">
                <div className="CrewGroup-image">
                  <img
                    className="u-w-100"
                    alt=""
                    src={team2}
                    aria-labelledby="team2"
                  />
                </div>
                <figcaption className="u-text-center" id="team2">
                  <p className="u-pt-20 u-weight-700">{t('team2_name')}</p>
                  <p>{t('team2_position')}</p>
                </figcaption>
              </figure>
            </li>
            <li className="col-lg-3 col-md-4 col-6 offset-lg-1 u-pb-20">
              <figure className="CrewGroup">
                <div className="CrewGroup-image">
                  <img
                    className="u-w-100"
                    alt=""
                    src={team3}
                    aria-labelledby="team3"
                  />
                </div>
                <figcaption className="u-text-center" id="team3">
                  <p className="u-pt-20 u-weight-700">{t('team3_name')}</p>
                  <p>{t('team3_position')}</p>
                </figcaption>
              </figure>
            </li>
            <li className="col-lg-3 col-md-4 col-6 u-pb-20">
              <figure className="CrewGroup">
                <div className="CrewGroup-image">
                  <img
                    className="u-w-100"
                    alt=""
                    src={team4}
                    aria-labelledby="team4"
                  />
                </div>
                <figcaption className="u-text-center" id="team4">
                  <p className="u-pt-20 u-weight-700">{t('team4_name')}</p>
                  <p>{t('team4_position')}</p>
                </figcaption>
              </figure>
            </li>
            <li className="col-lg-3 col-md-4 col-6 offset-lg-1 u-pb-20">
              <figure className="CrewGroup">
                <div className="CrewGroup-image">
                  <img
                    className="u-w-100"
                    alt=""
                    src={team5}
                    aria-labelledby="team5"
                  />
                </div>
                <figcaption className="u-text-center" id="team5">
                  <p className="u-pt-20 u-weight-700">{t('team5_name')}</p>
                  <p>{t('team5_position')}</p>
                </figcaption>
              </figure>
            </li>
          </ul>
        </div>
      </FadeInUpAnimation>

      <FadeInUpAnimation className="Section Section--white">
        <div className="Section-content u-pt-120 u-pb-120 u-pt-md-30 u-pb-md-30">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={people}
                className="u-w-100"
                alt=""
                role="presentation"
              />
            </div>

            <div className="col-lg-5 offset-lg-1 d-flex justify-content-center flex-column">
              <h2 className="u-pt-20 u-pb-24">
                <span className="Typography Typography--landingTitle u-weight-700">
                  {t('mission_title')}
                </span>
              </h2>

              <p className="Typography Typography--landingSubTitle u-pb-24 u-pr-80 u-pr-md-0">
                {t('mission_description')}
              </p>
            </div>
          </div>
        </div>
      </FadeInUpAnimation>

      <FadeInUpAnimation>
        <div className="Section" ref={contentRef3}>
          <div
            className="t-absolute t-left-0 t-bg-alpha-100 t-w-full"
            style={{ height: `${headerHeights.ref3}px` }}
          />
          <div className="Section-content u-pt-80 u-pb-80 t-relative">
            <div
              className="Card u-pt-60 u-pr-10 u-pb-60 u-pl-10 t-bg-gamma-400"
              style={{ backgroundColor: 'white' }}
            >
              <div className="row">
                <div className="col-md-5 offset-md-1">
                  <h2 className="Typography Typography--landingTitle u-pb-24 u-weight-700">
                    {t('team_contact_title')}
                  </h2>

                  <p className="Typography Typography--landingSubTitle u-pb-md-20">
                    {t('team_contact_description')}
                  </p>
                </div>

                <div className="col-md-4 offset-md-1">
                  <ContactsForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInUpAnimation>
    </main>
  );
}

export default AboutUsPage;
