import React from 'react';
import { useTranslation } from 'react-i18next';

const LearnMoreSection = () => {
  const { t } = useTranslation();

  const videoRef = React.useRef<HTMLImageElement>(null);
  const [videoHeight, setVideoHeight] = React.useState(279);

  const getHeightHeader = () => {
    if (videoRef?.current?.clientWidth) {
      setVideoHeight(Math.round((videoRef.current.clientWidth * 350) / 622));
    }
  };

  window.onresize = getHeightHeader;
  window.onload = getHeightHeader;

  React.useLayoutEffect(() => {
    getHeightHeader();
  }, []);

  return (
    <div className="t-w-full t-mt-16 md:t-mt-40">
      <p className="Typography Typography--landingMainTitle u-weight-700 t-mb-6">
        {t('learn_more_section_title')}
      </p>

      <div className="t-w-full t-flex t-justify-center">
        <div className="t-w-full t-max-w-md" ref={videoRef}>
          <iframe
            height={videoHeight}
            className="t-w-full"
            src="https://www.youtube.com/embed/bn9JoRzKtfc"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          />
        </div>
      </div>
    </div>
  );
};

export default LearnMoreSection;
