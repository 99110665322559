import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { isCurrentLangGerman } from 'helpers/common';

import priceEn from 'images/price-en.png';
import priceDe from 'images/price-de.png';
import checkIcon from 'images/check.svg';

const pricingPlans = [
  {
    header: 'pricing:options_yearly_header',
    description: 'pricing:options_yearly_description',
    price: 'pricing:options_yearly_price',
    priceDescription: 'pricing:options_yearly_price_desc',
  },
  // empty item
  {},
  {
    header: 'pricing:options_lifetime_header',
    description: 'pricing:options_lifetime_description',
    price: 'pricing:options_lifetime_price',
    priceDescription: 'pricing:options_lifetime_price_desc',
  },
];

function PricingPage({ lang }) {
  const { t } = useTranslation(['translation', 'pricing']);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const headRef = React.useRef<HTMLDivElement>(null);

  const getHeight = () => {
    if (headRef?.current?.clientHeight) {
      setHeaderHeight(headRef.current?.clientHeight);
    }
  };

  window.onresize = getHeight;
  window.onload = getHeight;

  React.useLayoutEffect(() => {
    getHeight();
  }, []);

  return (
    <main className="Page-content">
      <div className="Section Section--greenNoice Section--white" ref={headRef}>
        <div
          className="t-absolute t-left-0 t-bg-alpha-100 t-w-full t-h-full"
          style={{ height: `${headerHeight}px` }}
        />
        <div className="Section-content t-relative">
          <div className="FirstBlock">
            <div className="row u-h-100">
              <div className="col-lg-6 u-pt-10 u-pb-10 d-flex flex-column justify-content-center u-h-100">
                <h1 className="Typography Typography--landingMainTitle u-weight-700 u-pb-30">
                  {t('pricing:hero_title')}
                </h1>

                <p className="Typography Typography--landingSubTitle">
                  {t('pricing:hero_subtitle')}
                </p>
              </div>

              <div className="col-lg-6 t-hidden lg:t-flex justify-content-end">
                <div className="FirstBlock-image md:-t-bottom-6 xl:-t-bottom-7 t-flex t-items-start">
                  <img
                    src={isCurrentLangGerman(lang) ? priceDe : priceEn}
                    className="t-h-full"
                    alt="Different green leaves and big letters: P R I C E"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Section Section--white">
        <div className="Section-content u-pt-75 u-pb-75 d-flex flex-column justify-content-center align-items-center">
          <p className="Typography Typography--cardTitle u-weight-700 t-text-center t-mb-4">
            {t('pricing:options_header')}
          </p>
          <p className="typo-alpha t-text-center t-pb-12">
            {t('pricing:options_header_description')}
          </p>

          <div className="t-flex t-flex-col t-items-center lg:t-flex-row lg:t-justify-center lg:t-items-stretch t-w-full">
            {pricingPlans.map((pricingItem) =>
              !!pricingItem.header ? (
                <div
                  className="card t-p-8 xl:t-py-16 xl:t-px-20 md:t-w-auto"
                  key={pricingItem.header}
                >
                  <div className="t-max-w-112 t-w-96 xl:t-w-80 xl:t-min-w-112 t-h-full t-flex t-flex-col t-justify-between">
                    <p className="Typography Typography--cardTitle u-weight-700 t-text-center">
                      {t(pricingItem.header)}
                    </p>
                    <p className="Typography Typography--landingSubTitle t-font-semibold t-text-beta-500 t-text-center">
                      {t(pricingItem.description)}
                    </p>
                    <div>
                      <p className="Typography Typography--landingTitle u-weight-700 t-text-center t-text-alpha-600 t-mt-3">
                        {t(pricingItem.price)}
                      </p>
                      <p className="Typography Typography--normalText t-text-center t-text-beta-700">
                        {t(pricingItem.priceDescription)}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="t-m-10 t-flex t-items-center" key="or">
                  <p className="typo-alpha t-whitespace-nowrap">
                    {t('pricing:or')}
                  </p>
                </div>
              )
            )}
          </div>

          <div className="t-mt-14 t-flex t-justify-center">
            <a
              href="https://app.ninebarc.de/register"
              className={`Button Button--primary Button--large`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="t-px-4">{t('pricing:get_started')}</span>
            </a>
          </div>
        </div>
      </div>

      <div className="Section Section--white">
        <div className="Section-content t-px-6 t-mb-10">
          <p className="typo-alpha t-text-center t-mb-8">
            {t('pricing:section_pricing_title')}
          </p>

          <div className="row u-pt-40 u-pb-40">
            <div className="col-md-6 u-pr-60">
              <div className="d-flex u-pb-40 align-items-start">
                <img
                  className="Pricing-mark"
                  src={checkIcon}
                  alt="Check icon"
                />
                <p className="Typography Typography--landingListItem u-preline">
                  <Trans i18nKey="pricing:section_pricing_decription1" />
                </p>
              </div>
              <div className="d-flex u-pb-40 align-items-start">
                <img
                  className="Pricing-mark"
                  src={checkIcon}
                  alt="Check icon"
                />
                <p className="Typography Typography--landingListItem u-preline">
                  <Trans i18nKey="pricing:section_pricing_decription2" />
                </p>
              </div>
              <div className="d-flex u-pb-40 align-items-start">
                <img
                  className="Pricing-mark"
                  src={checkIcon}
                  alt="Check icon"
                />
                <p className="Typography Typography--landingListItem u-preline">
                  <Trans i18nKey="pricing:section_pricing_decription3" />
                </p>
              </div>
              <div className="d-flex u-pb-40 align-items-start">
                <img
                  className="Pricing-mark"
                  src={checkIcon}
                  alt="Check icon"
                />
                <p className="Typography Typography--landingListItem u-preline">
                  <Trans i18nKey="pricing:section_pricing_decription4" />
                </p>
              </div>
              <div className="d-flex align-items-start">
                <img
                  className="Pricing-mark"
                  src={checkIcon}
                  alt="Check icon"
                />
                <p className="Typography Typography--landingListItem u-preline">
                  <Trans i18nKey="pricing:section_pricing_decription5" />
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex u-pb-40 align-items-start">
                <img
                  className="Pricing-mark"
                  src={checkIcon}
                  alt="Check icon"
                />
                <p className="Typography Typography--landingListItem u-preline">
                  <Trans i18nKey="pricing:section_pricing_decription6" />
                </p>
              </div>
              <div className="d-flex u-pb-40 align-items-start">
                <img
                  className="Pricing-mark"
                  src={checkIcon}
                  alt="Check icon"
                />
                <p className="Typography Typography--landingListItem u-preline">
                  <Trans i18nKey="pricing:section_pricing_decription7" />
                </p>
              </div>
              <div className="d-flex u-pb-40 align-items-start">
                <img
                  className="Pricing-mark"
                  src={checkIcon}
                  alt="Check icon"
                />
                <p className="Typography Typography--landingListItem u-preline">
                  <Trans i18nKey="pricing:section_pricing_decription8" />
                </p>
              </div>
              <div className="d-flex u-pb-40 align-items-start">
                <img
                  className="Pricing-mark"
                  src={checkIcon}
                  alt="Check icon"
                />
                <p className="Typography Typography--landingListItem u-preline">
                  <Trans i18nKey="pricing:section_pricing_decription9" />
                </p>
              </div>
              <div className="d-flex align-items-start  u-pb-60" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PricingPage;
