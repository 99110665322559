import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import imgB2CHowPageEn from 'images/img-how-header-en.png';
import imgB2CHowPageDe from 'images/img-how-header-de.png';

interface B2CHowPageHeaderProps {
  english: boolean;
}

const B2CHowPageHeader = ({ english }: B2CHowPageHeaderProps) => {
  const { t } = useTranslation(['b2cHowPage']);

  return (
    <div className="t-flex t-items-center t-justify-between t-mt-5 md:t-mt-20">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        animate={{ y: 100 }}
        viewport={{ once: true }}
      >
        <p className="Typography Typography--landingMainTitle u-weight-700">
          {t('b2cHowPage:b2c_page_header_title')}
        </p>
        <p className="Typography Typography--landingSubTitle t-mt-6 md:t-mr-36">
          {t('b2cHowPage:b2c_page_header_subtitle')}
        </p>

        <div className="t-flex t-justify-center md:t-hidden">
          <img
            src={english ? imgB2CHowPageEn : imgB2CHowPageDe}
            className="t-z-10 t-mt-10 t-max-w-96"
            alt="homepage"
          />
        </div>
      </motion.div>

      <motion.div
        className="t-hidden md:t-block md:t-max-w-96 lg:t-max-w-sm"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
        animate={{ y: 100 }}
        viewport={{ once: true }}
        whileHover={{ scale: 1.3 }}
      >
        <img
          src={english ? imgB2CHowPageEn : imgB2CHowPageDe}
          className="t-z-10"
          alt="B2C page"
        />
      </motion.div>
    </div>
  );
};

export default B2CHowPageHeader;
