import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { ReactComponent as IconArrowDown } from 'images/icon-arrow-down.svg';
import { ReactComponent as IconArrowUp } from 'images/icon-arrow-up.svg';

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '-100%' },
};

const ulVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const liVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

type NavObject = {
  liClassName?: string;
  elClassName?: string;
  type?: string;
  text?: string;
  to?: string;
  href?: string;
};

interface MobileNavProps {
  navElements: NavObject[];
  subNavElements: NavObject[];
  showMobileMenu: boolean;
  setShowMobileMenu: (show: boolean) => void;
}

function MobileNav({
  navElements,
  subNavElements,
  showMobileMenu,
  setShowMobileMenu,
}: MobileNavProps) {
  const { t } = useTranslation();

  const [showSubMenu, setShowSubMenu] = useState(false);

  const hideMobileMenu = () => {
    setShowMobileMenu(false);
    setShowSubMenu(false);
  };

  const onCloseSubMenu = () => {
    setShowSubMenu(false);
  };

  const onToggleSubMenu = () => {
    setShowSubMenu((prev) => !prev);
  };

  return (
    <>
      <motion.nav
        animate={showMobileMenu ? 'open' : 'closed'}
        variants={variants}
        className="Header-navigation t-z-50 xl:t-hidden"
      >
        <motion.ul className="Header-navigationList" variants={ulVariants}>
          {navElements.map(
            ({ liClassName, elClassName, type, text, to }, index) => (
              <motion.li
                variants={liVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                className={liClassName}
                key={`mobile-nav-${index}`}
              >
                {type === 'link' && !!to && (
                  <Link
                    to={to}
                    className={elClassName}
                    onClick={hideMobileMenu}
                  >
                    {text}
                  </Link>
                )}
              </motion.li>
            )
          )}
          <motion.div
            className="Header-navigationListItem t-mb-0"
            variants={liVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <button
              className="t-flex t-items-center t-appearance-none t-bg-transparent t-text-alpha-600 t-h-9 t-justify-center t-outline-none t-tracking-widest t-px-3 t-text-md t-mt-0.5"
              onClick={onToggleSubMenu}
            >
              {t('nav_more')}
              {showSubMenu ? (
                <IconArrowUp className="t-ml-0.5 t-text-alpha-600 t-w-4 t-h-4" />
              ) : (
                <IconArrowDown className="t-ml-0.5 t-text-alpha-600 t-w-4 t-h-4" />
              )}
            </button>
          </motion.div>

          {showSubMenu && (
            <motion.div>
              <motion.ul className="t-w-full t-mb-6" variants={liVariants}>
                {subNavElements.map(
                  ({ liClassName, type, text, to, href }, index) => (
                    <motion.li
                      className={liClassName}
                      key={`nav-${index}`}
                      variants={liVariants}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      {type === 'link' && !!to && (
                        <Link
                          to={to}
                          className="t-flex t-items-center t-appearance-none t-text-alpha-600 t-outline-none t-tracking-widest t-px-3 t-text-md t-mt-2 t-ml-2"
                          onClick={() => {
                            hideMobileMenu();
                            onCloseSubMenu();
                          }}
                        >
                          {text}
                        </Link>
                      )}
                      {type === 'anchor' && (
                        <a
                          href={href}
                          className="t-flex t-items-center t-appearance-none t-text-alpha-600 t-outline-none t-tracking-widest t-px-3 t-text-md t-mt-2 t-ml-2"
                          onClick={() => {
                            hideMobileMenu();
                            onCloseSubMenu();
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {text}
                        </a>
                      )}
                    </motion.li>
                  )
                )}
                <motion.li
                  className="Header-navigationListItem"
                  variants={liVariants}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <a
                    href="https://app.ninebarc.de/incident-report"
                    className="t-flex t-items-center t-appearance-none t-text-alpha-600 t-outline-none t-tracking-widest t-px-3 t-text-md t-mt-2 t-ml-2 t-uppercase"
                    onClick={() => {
                      hideMobileMenu();
                      onCloseSubMenu();
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('header_report_death')}
                  </a>
                </motion.li>
              </motion.ul>
            </motion.div>
          )}

          {navElements.map(
            ({ liClassName, elClassName, type, text, href }, index) => (
              <motion.li
                variants={liVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                className={liClassName}
                key={`mobile-nav-${index}`}
              >
                {type === 'anchor' && (
                  <a
                    href={href}
                    className={elClassName}
                    onClick={hideMobileMenu}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {text}
                  </a>
                )}
              </motion.li>
            )
          )}
        </motion.ul>
      </motion.nav>
    </>
  );
}

export default MobileNav;
