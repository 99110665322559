// https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/
import React from 'react';

const viewportContext = React.createContext<any>({});

interface ViewportProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

const ViewportProvider = ({ children }: ViewportProviderProps) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};

export { viewportContext, ViewportProvider, useViewport };
