import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import config from 'helpers/configHelper';
import App from './App';
import { MatomoWrapper, ViewportProvider } from 'components';

import './index.css';
import 'localization/i18n';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <MatomoWrapper siteId={config.matomoSiteId}>
        <ViewportProvider>
          <App />
        </ViewportProvider>
      </MatomoWrapper>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
