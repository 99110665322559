import { LANGUAGES } from 'constants/common';

export const isCurrentLangGerman = (currentLang) =>
  currentLang === LANGUAGES.DE;

export const scrollToElementId = (elementId) => {
  setTimeout(async () => {
    const element = document.getElementById(elementId);
    await element?.scrollIntoView({ behavior: 'smooth' });
  }, 100);
};
