import React from 'react';
import { useTranslation } from 'react-i18next';

import config from 'helpers/configHelper';

import jobs from 'images/jobs.png';

function JobsPage() {
  const { t } = useTranslation(['translation']);
  const jobsUrl = config.jobsUrl;

  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = jobsUrl || 'https://join.com/companies/ninebarc';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [jobsUrl]);

  const [headerHeight, setHeaderHeight] = React.useState(0);
  const headRef = React.useRef<HTMLDivElement>(null);

  const getHeight = () => {
    if (headRef?.current?.clientHeight) {
      setHeaderHeight(headRef.current?.clientHeight);
    }
  };

  React.useLayoutEffect(() => {
    getHeight();
  }, []);

  window.onresize = getHeight;
  window.onload = getHeight;

  return (
    <main className="Page-content">
      <div className="Section Section--white" ref={headRef}>
        <div
          className="t-absolute t-left-0 t-bg-alpha-100 t-w-full t-h-full"
          style={{ height: `${headerHeight}px` }}
        />
        <div className="Section-content t-relative">
          <div className="FirstBlock">
            <div className="row t-h-full">
              <div className="col-lg-6 u-pt-10 u-pb-10 t-flex t-flex-col t-justify-center t-h-full">
                <h1 className="Typography Typography--landingMainTitle u-weight-700">
                  {t('jobs_page_title')}
                </h1>
                <p className="Typography Typography--normalText t-text-beta-700 t-mt-2 lg:t-pr-20">
                  {t('jobs_page_desc')}
                </p>
              </div>

              <div className="col-lg-6 t-hidden lg:t-flex t-justify-end t-h-full">
                <div className="FirstBlock-image md:-t-bottom-14 xl:-t-bottom-12 t-flex t-items-start">
                  <img
                    src={jobs}
                    className="t-h-full"
                    alt="Different green leaves and big letters: J O B S"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="join-widget" className="Section-content t-min-h-80 t-mt-8">
        {!jobsUrl && (
          <div>
            <p>Currently we have no open position</p>
          </div>
        )}
      </div>
    </main>
  );
}

export default JobsPage;
