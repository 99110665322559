import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContactsForm } from 'components';

function ContactsPage() {
  const { t } = useTranslation();

  return (
    <main className="Page-content">
      <div className="Section Section--black-squeeze u-h-100">
        <div className="Section-content u-pt-80 u-pb-80 u-pt-md-10 u-pb-md-10">
          <div className="Card Card--white u-pt-60 u-pr-10 u-pb-60 u-pl-10 u-pt-md-30 u-pb-md-30">
            <div className="row">
              <div className="col-md-5 offset-md-1">
                <h2 className="Typography Typography--landingTitleLower u-pb-24 u-weight-700 u-preline">
                  {t('contact_title')}
                </h2>

                <p className="Typography Typography--landingSubTitle u-pb-md-20">
                  {t('team_contact_description')}
                </p>
              </div>

              <div className="col-md-4 offset-md-1">
                <ContactsForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ContactsPage;
