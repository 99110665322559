import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import insurLab from 'images/logos/insur-lab.png';
import deutscheStartups from 'images/logos/deutsche-startups.png';
import digitalPioneers from 'images/logos/digial-pioneers.png';
import insuretech from 'images/logos/insuretech.png';
import insider from 'images/logos/insider.png';
import zeitung from 'images/logos/zeitung.png';

const partners = [
  {
    name: 'INSURLAB GERMANY',
    logo: insurLab,
    link: 'https://insurlab-germany.com/de/ninebarc-erorbert-versicherungsbranche-im-sturm-venpace-investiert-insurtech-hub-munich-und-insurlab-germany-aus-koeln-starten-kooperationen/',
  },
  {
    name: 'Deutsche startups',
    logo: deutscheStartups,
    link: 'https://www.deutsche-startups.de/2020/06/02/10-startups-ganz-frisch/',
  },
  {
    name: 't3n  - digital pioneers',
    logo: digitalPioneers,
    link: 'https://t3n.de/magazin/startup-check-diese-6-startups-249533/2/',
  },
  {
    name: 'InsurTech Hub Munich (ITHM)',
    logo: insuretech,
    link: 'https://www.insurtech-munich.com/cultivating-collaboration-with-an-impact/',
  },
  {
    name: 'Business Insider',
    logo: insider,
    link: 'https://www.businessinsider.de/leben/unsere-spuren-im-netz-warum-digitale-nachlassverwalter-fuer-jeden-wichtig-werden-koennten-b/',
  },
  {
    name: 'Süddeutsche Zeitung',
    logo: zeitung,
    link: 'https://pressemitteilungen.sueddeutsche.de/ninebarc-gmbh-4786379',
  },
];

const NewsSection = () => {
  const { t } = useTranslation();

  return (
    <div className="t-w-full t-mt-16 md:t-mt-40 t-mb-32">
      <p className="typo-alpha t-text-delta-900 t-text-center t-mb-10">
        {t('ninebarc-in-the-news')}
      </p>
      <div className="row t-w-full">
        <div className="t-w-full">
          <ul className="t-flex t-items-center t-justify-center lg:t-justify-around t-flex-wrap">
            {partners.map(({ name, logo, link }) => (
              <motion.li
                className="t-my-5 md:t-my-0 t-mx-5"
                key={name}
                whileHover={{ scale: 1.2 }}
              >
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={name}
                >
                  <img src={logo} alt={name} />
                </a>
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NewsSection;
