import React, { useState, useRef } from 'react';
import { motion, useDomEvent } from 'framer-motion';

const transition = {
  type: 'spring',
  damping: 25,
  stiffness: 120,
};

interface ImageEnlargementProps {
  src: string;
  alt?: string;
  className?: string;
}
const ImageEnlargement = ({ src, alt, className }: ImageEnlargementProps) => {
  const [isOpen, setOpen] = useState(false);

  useDomEvent(useRef(window), 'scroll', () => isOpen && setOpen(false));

  const imgClassName = `${
    isOpen
      ? 't-fixed t-top-0 t-bottom-0 t-left-0 t-right-0 t-cursor-zoom-out t-flex t-justify-center t-items-center'
      : 't-cursor-zoom-in'
  } ${className || ''}`;

  return (
    <>
      {isOpen && (
        <img src={src} alt={alt} className={`${className} t-opacity-0`} />
      )}
      <div className={imgClassName}>
        <motion.div
          animate={{ opacity: isOpen ? 0.7 : 0 }}
          transition={transition}
          className={
            isOpen
              ? 't-fixed t-top-0 t-bottom-0 t-left-0 t-right-0 t-pointer-events-auto t-bg-beta-400 t-z-10'
              : ''
          }
          onClick={() => setOpen(false)}
        />
        <motion.img
          src={src}
          alt={alt || ''}
          onClick={() => setOpen(!isOpen)}
          layout={true}
          transition={transition}
          className={`t-z-20 t-max-w-full t-max-h-full ${
            isOpen ? 't-p-3' : ''
          }`}
        />
      </div>
    </>
  );
};

export default ImageEnlargement;
