import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { BASE_URL } from 'utils/common';

function ContactsForm() {
  const { t } = useTranslation();

  const initState = {
    name: '',
    surname: '',
    from: '',
    body: '',
  };

  const [contactFields, setContactFields] = useState(initState);
  const [contacted, setContacted] = useState(false);

  const onFieldChange = ({ target }) => {
    setContacted(false);
    const { name, value } = target;
    setContactFields({ ...contactFields, [name]: value });
  };

  const handleSubmit = async (e) => {
    await e.preventDefault();
    await axios.post(`${BASE_URL}/email/contact`, contactFields);
    setContactFields(initState);
    setContacted(true);
  };

  return (
    <form onSubmit={handleSubmit} className="Form">
      <div className="row">
        <div className="col-6">
          <div className="Form-group">
            <label className="Form-label" htmlFor="Name">
              {t('label_first_name')}
            </label>

            <input
              type="text"
              name="name"
              value={contactFields.name}
              onChange={onFieldChange}
              required={true}
              className="Field"
              id="Name"
            />
          </div>
        </div>

        <div className="col-6">
          <div className="Form-group">
            <label className="Form-label" htmlFor="Surname">
              {t('label_last_name')}
            </label>

            <input
              type="text"
              name="surname"
              required={true}
              value={contactFields.surname}
              onChange={onFieldChange}
              className="Field"
              id="Surname"
            />
          </div>
        </div>
      </div>

      <div className="Form-group u-pb-24">
        <label className="Form-label" htmlFor="Email">
          {t('label_email')}
        </label>

        <input
          type="email"
          name="from"
          required={true}
          value={contactFields.from}
          onChange={onFieldChange}
          className="Field"
          id="Email"
        />
      </div>

      <div className="Form-group u-pb-24">
        <label className="Form-label" htmlFor="Message">
          {t('label_message')}
        </label>

        <textarea
          rows={5}
          name="body"
          required={true}
          value={contactFields.body}
          onChange={onFieldChange}
          className="Field Field--textarea"
          id="Message"
        />
      </div>

      {contacted ? (
        <p className="typo-epsilon t-mb-4 t-text-alpha-600">
          {t('contact_message_send_success')}
        </p>
      ) : (
        <div className="u-pb-16">
          <span>{t('data_policy_link_text')}</span>
          <a
            href="/privacy"
            rel="noopener noreferrer nofollow"
            target="_blank"
            style={{ textDecoration: 'none', color: '#68a4d4' }}
          >
            {t('data_policy_link_here')}
          </a>
          <span>{t('data_policy_dot')}</span>
        </div>
      )}

      <button type="submit" className="Button Button--primary">
        {t('caption_send_message')}
      </button>
    </form>
  );
}

export default ContactsForm;
