import React from 'react';
import { Link } from 'react-router-dom';

function NotFoundPage() {
  return (
    <main className="Page-content">
      <div className="Section Section--white">
        <div className="Section-content">
          <div className="Task list">
            <h2 className="u-pb-24 u-text-center">
              <span className="Typography Typography--landingTitle u-weight-700">
                404: Page Not Found
              </span>
            </h2>

            <div className="row">
              <div className="col-md-6 offset-md-3 d-flex justify-content-center">
                <p className="Typography Typography--subTitle u-weight-600 u-pb-20">
                  We're sorry, the page you requested could not be found. Please
                  go back to the home page or contact us at support@ninebarc.com
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 offset-md-3 d-flex justify-content-center">
                <Link className="Button Button--secondary d-inline-flex" to="/">
                  GO HOME
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default NotFoundPage;
