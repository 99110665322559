import React from 'react';
import { motion } from 'framer-motion';

interface IconRetentionSupport {
  className?: string;
}

const IconRetention = ({ className }: IconRetentionSupport) => {
  return (
    <motion.svg
      width="210"
      height="239"
      viewBox="0 0 210 239"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M105 238.961C162.99 238.961 210 191.951 210 133.961C210 75.971 162.99 28.9609 105 28.9609C47.0101 28.9609 0 75.971 0 133.961C0 191.951 47.0101 238.961 105 238.961Z"
        fill="#DEE9F3"
      />
      <mask
        id="mask0_15421_24643"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="28"
        width="210"
        height="211"
      >
        <path
          d="M105 238.961C162.99 238.961 210 191.951 210 133.961C210 75.971 162.99 28.9609 105 28.9609C47.0101 28.9609 0 75.971 0 133.961C0 191.951 47.0101 238.961 105 238.961Z"
          fill="#D6E8F6"
        />
      </mask>
      <motion.g
        mask="url(#mask0_15421_24643)"
        initial={{ rotate: 0 }}
        animate={{ rotate: [0, -6, 0] }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
      >
        <path
          d="M152.183 88.3406C151.233 82.6244 148.24 75.8145 139.192 75.8145C137.89 75.8145 136.798 76.7319 136.587 78.0374L134.862 88.27C134.193 92.3278 133.243 96.1739 132.081 99.7023C128.842 109.547 124.758 114.593 117.506 117.698C114.02 119.18 111.204 120.45 110.077 120.979H96.5232C95.0798 120.979 93.8828 122.179 93.8828 123.626V171.648C93.8828 173.095 95.0798 174.295 96.5232 174.295H109.725L115.393 178.917C120.181 182.798 126.166 184.915 132.327 184.915H148.451C149.895 184.915 151.092 183.716 151.092 182.269C151.092 181.281 150.564 180.434 149.754 179.976V127.048H146.48L152.007 99.1025C152.641 95.3976 152.747 91.7632 152.183 88.3406Z"
          fill="#EFD1B4"
        />
        <path
          d="M96.4895 116.215H75.9295C74.4861 116.215 73.2891 117.415 73.2891 118.861V175.529C73.2891 176.975 74.4861 178.175 75.9295 178.175H96.4895C97.933 178.175 99.1299 176.975 99.1299 175.529V118.861C99.1299 117.379 97.933 116.215 96.4895 116.215Z"
          fill="#413D45"
        />
        <path
          d="M142.575 177.329C142.575 173.095 145.99 169.708 150.179 169.708C145.954 169.708 142.575 166.285 142.575 162.086C142.575 157.852 145.99 154.465 150.179 154.465C145.954 154.465 142.575 151.042 142.575 146.843C142.575 142.609 145.99 139.222 150.179 139.222C145.954 139.222 142.575 135.799 142.575 131.6C142.575 127.366 145.99 123.979 150.179 123.979H141.518C137.294 123.979 133.914 127.401 133.914 131.6C133.914 135.834 137.329 139.222 141.518 139.222C137.294 139.222 133.914 142.644 133.914 146.843C133.914 151.077 137.329 154.465 141.518 154.465C137.294 154.465 133.914 157.887 133.914 162.086C133.914 166.285 137.329 169.708 141.518 169.708C137.294 169.708 133.914 173.13 133.914 177.329C133.914 181.563 137.329 184.951 141.518 184.951H150.179C145.954 184.951 142.575 181.528 142.575 177.329Z"
          fill="#D7AE88"
        />
        <path
          d="M75.9293 110.676H31.4646C30.0212 110.676 28.8242 111.875 28.8242 113.322V181.034C28.8242 182.481 30.0212 183.68 31.4646 183.68H75.9293C77.3727 183.68 78.5697 182.481 78.5697 181.034V113.322C78.5697 111.875 77.3727 110.676 75.9293 110.676Z"
          fill="#4B81AC"
        />
        <path
          d="M28.8242 173.518V181.033C28.8242 182.48 30.0212 183.68 31.4646 183.68H75.9293C77.3727 183.68 78.5697 182.48 78.5697 181.033V173.518H28.8242Z"
          fill="#1E5D8F"
        />
        <path
          d="M61.8809 168.951C64.2141 168.951 66.1056 167.055 66.1056 164.717C66.1056 162.378 64.2141 160.482 61.8809 160.482C59.5477 160.482 57.6562 162.378 57.6562 164.717C57.6562 167.055 59.5477 168.951 61.8809 168.951Z"
          fill="#1E5D8F"
        />
        <path
          d="M176.266 123.871H148.7C143.806 123.871 139.828 127.858 139.828 132.763V134.245C139.828 139.149 143.806 143.137 148.7 143.137H176.266C181.159 143.137 185.138 139.149 185.138 134.245V132.728C185.138 127.858 181.159 123.871 176.266 123.871Z"
          fill="#EFD1B4"
        />
        <path
          d="M176.266 134.527H148.7C145.074 134.527 141.94 132.34 140.567 129.199C140.11 130.293 139.828 131.493 139.828 132.728V134.21C139.828 139.114 143.806 143.101 148.7 143.101H176.266C181.159 143.101 185.138 139.114 185.138 134.21V132.728C185.138 131.457 184.891 130.258 184.398 129.199C183.025 132.34 179.927 134.527 176.266 134.527Z"
          fill="#D7AE88"
        />
        <path
          d="M179.505 137.811H148.735C143.842 137.811 139.863 141.798 139.863 146.702V148.184C139.863 153.089 143.842 157.076 148.735 157.076H179.505C184.398 157.076 188.377 153.089 188.377 148.184V146.702C188.341 141.798 184.398 137.811 179.505 137.811Z"
          fill="#EFD1B4"
        />
        <path
          d="M179.505 148.572H148.735C145.074 148.572 141.94 146.349 140.603 143.174C140.145 144.232 139.898 145.432 139.898 146.667V148.149C139.898 153.054 143.877 157.041 148.77 157.041H179.505C184.398 157.041 188.376 153.054 188.376 148.149V146.667C188.376 145.432 188.13 144.232 187.672 143.174C186.299 146.349 183.131 148.572 179.505 148.572Z"
          fill="#D7AE88"
        />
        <path
          d="M175.527 151.748H149.474C144.194 151.748 139.863 156.053 139.863 161.381C139.863 166.674 144.158 171.014 149.474 171.014H175.527C180.807 171.014 185.138 166.709 185.138 161.381C185.138 156.088 180.843 151.748 175.527 151.748Z"
          fill="#EFD1B4"
        />
        <path
          d="M175.527 162.369H149.474C145.743 162.369 142.468 160.217 140.884 157.076C140.215 158.382 139.863 159.864 139.863 161.416C139.863 166.709 144.158 171.049 149.474 171.049H175.527C180.807 171.049 185.138 166.744 185.138 161.416C185.138 159.864 184.75 158.382 184.117 157.076C182.532 160.181 179.294 162.369 175.527 162.369Z"
          fill="#D7AE88"
        />
        <path
          d="M168.38 165.684H149.474C144.194 165.684 139.863 169.988 139.863 175.316C139.863 180.644 144.158 184.949 149.474 184.949H168.38C173.661 184.949 177.991 180.644 177.991 175.316C177.991 169.988 173.661 165.684 168.38 165.684Z"
          fill="#EFD1B4"
        />
        <path
          d="M168.38 176.304H149.474C145.743 176.304 142.468 174.152 140.884 171.012C140.215 172.317 139.863 173.764 139.863 175.352C139.863 180.644 144.158 184.985 149.474 184.985H168.38C173.661 184.985 177.991 180.68 177.991 175.352C177.991 173.799 177.604 172.317 176.97 171.012C175.35 174.152 172.112 176.304 168.38 176.304Z"
          fill="#D7AE88"
        />
      </motion.g>
      <motion.g
        initial={{ opacity: 0, scale: 0, x: 50, y: 60 }}
        transition={{
          duration: 1.5,
          ease: 'easeOut',
          repeat: Infinity,
          repeatDelay: 1.5,
        }}
        animate={{
          scale: 1,
          opacity: 1,
          animationFillMode: 'forwards',
          x: 0,
          y: 0,
        }}
      >
        <path
          d="M77.5097 35.867C76.9485 34.1795 75.5288 32.9552 73.779 32.6904L62.4879 31.036L57.4366 20.7782C56.6442 19.1899 55.0595 18.1973 53.2767 18.1973C51.4939 18.1973 49.9092 19.1899 49.1168 20.7782L44.0655 31.036L32.7744 32.6904C31.0246 32.9552 29.5719 34.1464 29.0437 35.867C28.4824 37.5546 28.9446 39.3745 30.2322 40.6319L38.3869 48.6064L36.4721 59.8569C36.1749 61.6106 36.8682 63.3643 38.3209 64.3901C39.1463 64.9857 40.0707 65.2835 41.0281 65.2835C41.7545 65.2835 42.5138 65.1181 43.2071 64.7541L53.3097 59.4267L63.4123 64.7541C64.997 65.5813 66.8459 65.449 68.2985 64.3901C69.7512 63.3312 70.4445 61.6106 70.1474 59.8569L68.2325 48.6064L76.3872 40.6319C77.6088 39.3745 78.071 37.5546 77.5097 35.867Z"
          fill="#40946A"
        />

        <path
          d="M73.7787 32.6932L73.5145 32.6602L60.7708 45.3003C60.1105 45.929 59.8133 46.8555 59.9784 47.7489L62.9828 64.5584L63.3789 64.7569C64.9637 65.5841 66.8125 65.4518 68.2652 64.3929C69.7178 63.334 70.4111 61.6134 70.114 59.8597L68.1991 48.6093L76.3538 40.6347C77.6414 39.3773 78.0706 37.5574 77.5424 35.8698C76.9481 34.1823 75.5285 32.958 73.7787 32.6932Z"
          fill="#2B7B54"
        />
        <path
          d="M129.673 17.6367C129.112 15.9491 127.692 14.7248 125.943 14.4601L114.651 12.8056L109.633 2.58098C108.841 0.992683 107.223 0 105.473 0C103.69 0 102.106 0.992683 101.313 2.58098L96.2621 12.8387L84.9709 14.4932C83.2211 14.7579 81.7685 15.9491 81.2402 17.6698C80.712 19.3904 81.1412 21.1772 82.4288 22.4346L90.5835 30.4092L88.6686 41.6596C88.3715 43.4133 89.0648 45.1671 90.5175 46.1928C91.9701 47.2517 93.819 47.3841 95.4037 46.5568L105.506 41.2294L115.609 46.5568C116.302 46.9208 117.029 47.0863 117.788 47.0863C118.745 47.0863 119.703 46.7885 120.495 46.1928C121.948 45.134 122.641 43.4133 122.344 41.6596L120.429 30.4092L128.584 22.4346C129.772 21.1772 130.235 19.3242 129.673 17.6367Z"
          fill="#40946A"
        />
        <path
          d="M120.425 46.1938C121.878 45.135 122.571 43.4143 122.274 41.6606L120.359 30.4102L128.514 22.4356C129.802 21.1782 130.231 19.3583 129.703 17.6707C129.141 15.9832 127.722 14.7589 125.972 14.4941H125.873L112.931 27.0681C112.271 27.6968 111.973 28.6233 112.139 29.5167L114.483 43.1496L115.044 46.26L115.572 46.5247C116.265 46.8887 116.992 47.0541 117.751 47.0541C118.676 47.0872 119.6 46.7894 120.425 46.1938Z"
          fill="#2B7B54"
        />
        <path
          d="M181.768 35.867C181.206 34.1795 179.787 32.9552 178.037 32.6904L166.746 31.036L161.694 20.7782C160.902 19.1899 159.317 18.1973 157.535 18.1973C155.752 18.1973 154.167 19.1899 153.375 20.7782L148.323 31.036L137.032 32.6904C135.282 32.9552 133.83 34.1464 133.302 35.867C132.74 37.5546 133.202 39.3745 134.49 40.6319L142.645 48.6064L140.73 59.8569C140.433 61.6106 141.126 63.3643 142.579 64.3901C144.031 65.449 145.88 65.5813 147.465 64.7541L157.568 59.4267L167.67 64.7541C168.363 65.1181 169.09 65.2835 169.849 65.2835C170.807 65.2835 171.764 64.9857 172.556 64.3901C174.009 63.3312 174.702 61.6106 174.405 59.8569L172.49 48.6064L180.645 40.6319C181.867 39.3745 182.296 37.5546 181.768 35.867Z"
          fill="#40946A"
        />
        <path
          d="M181.771 35.8698C181.21 34.1823 179.79 32.958 178.04 32.6932L177.842 32.6602L165.032 45.2672C164.372 45.8959 164.075 46.8224 164.24 47.7158L166.947 64.3598L167.641 64.7238C168.334 65.0878 169.06 65.2532 169.82 65.2532C170.777 65.2532 171.735 64.9554 172.527 64.3598C173.98 63.301 174.673 61.5803 174.376 59.8266L172.461 48.5762L180.616 40.6016C181.87 39.3773 182.299 37.5574 181.771 35.8698Z"
          fill="#2B7B54"
        />
      </motion.g>
    </motion.svg>
  );
};

export default IconRetention;
