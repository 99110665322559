import React from 'react';

function PrivacyPage() {
  return (
    <main className="Page-content">
      <div className="Section Section--white">
        <div className="Section-content">
          <div className="Task list">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h1 className="Typography Typography--landingTitle u-weight-700 u-pb-24">
                  Datenschutzerklärung
                </h1>
                <p>
                  <strong>Datenschutz</strong>{' '}
                </p>
                <br />
                <p>
                  Im Folgenden informieren wir über die Verarbeitung
                  personenbezogener Daten bei Nutzung unserer Webseite
                  http://ninebarc.com. Personenbezogene Daten sind alle Daten,
                  die auf Sie persönlich beziehbar sind, z.B. Name, Adresse,
                  E-Mail-Adressen, Nutzerverhalten.
                </p>
                <br />
                <br />
                <p>
                  <strong>1) Name und Kontaktdaten des Verantwortlichen</strong>
                </p>
                <br />
                <p>
                  Verantwortlicher gem. Art. 4 Abs. 7
                  EU-Datenschutz-Grundverordnung (DSGVO) ist{' '}
                </p>
                <br />
                <p>Die Ninebarc GmbH vertreten durch die Geschäftsführer: </p>
                <br />
                <p>Dr. Johann Kempe und Viktor Hettich </p>
                <p>Skalitzer Str. 85-86 </p>
                <p>DE-10997 Berlin </p>
                <br />
                <br />
                <p>
                  <strong>2) Aufrufen unserer Webseite</strong>
                </p>
                <br />
                <p>
                  Beim Aufrufen unserer Webseite werden durch den von Ihnen
                  benutzten Browser automatisch Informationen an den Server
                  unserer Webseite gesendet und temporär in einem Log-File
                  gespeichert. Einige dieser Dienste sind zum Funktionieren der
                  Webseite unbedingt erforderlich (essenziell), während andere
                  uns helfen, unsere Webseite zu verbessern und deren Nutzung zu
                  analysieren (nicht-essenziell).
                </p>
                <br />
                <p>
                  a) Bei der Nutzung unserer Webseite werden folgende
                  Informationen ohne Ihr Zutun erfasst und bis zur
                  automatisierten Löschung gespeichert:{' '}
                </p>
                <br />
                <p>- die IP-Adresse des anfragenden Rechners, </p>
                <p>- das Datum und die Uhrzeit des Zugriffs, </p>
                <p>- der Name und die URL der abgerufenen Datei, </p>
                <p>
                  - die Webseite, von der aus der Zugriff erfolgte
                  (Referrer-URL),{' '}
                </p>
                <p>
                  - der von Ihnen verwendete Browser und ggfs. das
                  Betriebssystem Ihres Rechners sowie der Name Ihres
                  Access-Providers.{' '}
                </p>
                <br />
                <p>
                  b) Die aufgelisteten Daten werden von uns erhoben und
                  verarbeitet, um einen reibungslosen Verbindungsaufbau und eine
                  komfortable Nutzung unserer Webseite zu gewährleisten. Darüber
                  hinaus dienen sie uns zur Auswertung der Systemsicherheit und
                  -stabilität sowie zu administrativen Zwecken, um unsere
                  Leistungen stets verbessern zu können (Webseitenoptimierung).{' '}
                </p>
                <br />
                <p>
                  c) Die Rechtsgrundlage der Datenverarbeitung für die
                  essenziellen Dienste zum Funktionieren der Webseite ist Art. 6
                  Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt
                  aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem
                  Fall verwenden wir die erhobenen Daten zu dem Zweck nutzen,
                  Rückschlüsse auf Ihre Person zu ziehen.{' '}
                </p>
                <br />
                <p>
                  Die Rechtsgrundlage für die nicht-essenziellen Dienste ist
                  Ihre freiwillige Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a
                  DSGVO. Die Einwilligung in die Nutzung der nicht-essentiellen
                  Dienste, die Cookies und ähnliche Technologien einsetzen, kann
                  jederzeit mit Wirkung für die Zukunft widerrufen werden.{' '}
                </p>
                <br />
                <p>
                  Die Daten werden gelöscht, sobald sie für die Erreichung des
                  Zweckes ihrer Erhebung nicht mehr erforderlich sind.{' '}
                </p>
                <br />
                <p>
                  d) Der Betrieb unseres Online-Angebots erfolgt über einen
                  Hosting-Dienstleistungsanbieter aus Deutschland. Der Anbieter,
                  mit dem wir eine Vereinbarung zur Auftragsverarbeitung gemäß
                  Art. 28 DSGVO abgeschlossen haben, stellt uns Infrastruktur
                  und Speicherplatz für unsere Webseite und E-Mail-Postfächer
                  auf seinen Servern innerhalb Deutschlands zur Verfügung und
                  übernimmt die Wartung, den technischen Support sowie den
                  Anlagenbetrieb. Hierbei werden vom Anbieter in unserem Auftrag
                  personenbezogene Daten verarbeitet, die Sie uns bei der
                  Nutzung unserer Dienste mitteilen, zum Beispiel Ihren Namen
                  und Ihre E-Mail-Adresse für die Vertragsdurchführung. Für
                  einen reibungslosen und sicheren Betrieb unseres
                  Online-Angebots zu sorgen stellt zudem unser berechtigtes
                  Interesse gemäß Art. 6 Abs. 1 S. 1 f DSGVO dar.{' '}
                </p>
                <br />
                <p>
                  e) Welche auf unserer Webseite verwendeten Cookies und
                  Tracking-Tools essenziell und welche nicht essenziell sind und
                  wie ihre Daten dafür verwendet werden, wird unter den
                  entsprechenden Ziffern genauer erläutert.{' '}
                </p>
                <br />
                <br />
                <p>
                  <strong>3) Vertragsschluss</strong>
                </p>
                <br />
                <p>
                  a) Wenn Sie sich auf der Plattform des Verantwortlichen
                  registrieren und unsere Leistungen in Anspruch nehmen,
                  schließend Sie einen Vertrag mit dem Verantwortlichen. Für den
                  Abschluss, die Durchführung oder die Beendigung des Vertrages
                  mit Ihnen erheben wir die erforderlichen Daten. Hierzu zählen
                  insbesondere folgende Informationen:
                </p>
                <br />
                <p>- Anrede, Vorname, Nachname,</p>
                <p>- eine gültige E-Mail-Adresse,</p>
                <p>- Anschrift,</p>
                <p>- Telefonnummer,</p>
                <p>- Informationen, die Gegenstand des Vertrages sind.</p>
                <br />
                <p>b) Die Erhebung dieser Daten erfolgt,</p>
                <br />
                <p>- um Sie als unseren Kunden identifizieren zu können;</p>
                <p>- um für Sie den Vertrag erfüllen zu können;</p>
                <p>- zur Korrespondenz mit Ihnen;</p>
                <p>- zur Rechnungsstellung;</p>
                <p>
                  - zur Abwicklung von evtl. vorliegenden Haftungsansprüchen
                  sowie der Geltendmachung etwaiger Ansprüche gegen Sie;
                </p>
                <br />
                <p>
                  c) Die Erfüllung unserer vertraglichen Verpflichtungen erfolgt
                  in weitem Umfang unter Einschaltung sog. Dritter
                  (Auftragsverarbeiter), die uns z.B. Speicherplatz und
                  Verarbeitungskapazitäten in ihren Rechenzentren zur Verfügung
                  stellen und nach unserer Weisung auch personenbezogene Daten
                  in unserem Auftrag verarbeiten. Diese Dienstleister
                  verarbeiten Daten entweder ausschließlich in Deutschland oder
                  in der EU. Soweit es sich bei den Auftragsverarbeitern um
                  US-Firmen handelt, verarbeiten diese die Daten ausschließlich
                  in der EU. Die Speicherung von Daten erfolgt zudem stets
                  verschlüsselt.
                </p>
                <br />
                <p>
                  Wir nutzen auch Dienste von Amazon Web Services EMEA SARL,
                  Luxemburg (siehe: https://aws.amazon.com/de/legal/aws-emea#1)
                  zur Verarbeitung Ihrer personenbezogenen Daten. Als
                  Speicherort wurden im Vertrag mit AWS ausdrücklich nur solche
                  Regionen benannt, die in Deutschland gelegen sind. Zudem
                  bestünde bei einem Datentransfer und -Verarbeitungen an bzw.
                  durch Amazon.com in den USA ein angemessenes Datenschutzniveau
                  auf Grundlage eines von der EU-Kommission gemäß dem
                  Prüfverfahren nach Art. 93 Absatz 2 DSGVO erlassenen
                  Datenschutzvertrags (sog. EU-Standardvertragsklauseln/Standard
                  Contractual Clauses), den wir mit AWS abgeschlossen haben.
                </p>
                <br />
                <p>
                  d) Die Datenverarbeitung erfolgt auf Ihre Anfrage hin und ist
                  nach Art. 6 Abs. 1 S. 1 lit. b DSGVO zu den genannten Zwecken
                  für die angemessene Bearbeitung des Auftrags und für die
                  beidseitige Erfüllung von Verpflichtungen aus dem Vertrag
                  erforderlich.
                </p>
                <br />
                <p>
                  Die für die Durchführung des Vertrages von uns erhobenen
                  personenbezogenen Daten werden bis zur Erfüllung der
                  vertraglichen Verpflichtungen, bis zum Ablauf der gesetzlichen
                  Aufbewahrungspflicht bzw. möglicher vertraglicher
                  Gewährleistungs- und Garantierechte gespeichert und danach
                  gelöscht, es sei denn, dass wir nach Artikel 6 Abs. 1 S. 1
                  lit. c DSGVO aufgrund von steuer- und handelsrechtlichen
                  Aufbewahrungs- und Dokumentationspflichten (aus HGB, StGB oder
                  AO) zu einer längeren Speicherung verpflichtet sind oder Sie
                  in eine darüber hinausgehende Speicherung nach Art. 6 Abs. 1
                  S. 1 lit. a DSGVO eingewilligt haben.
                </p>
                <br />
                <p>
                  Ein Widerspruchsrecht besteht für die oben dargestellten
                  Verarbeitungen Ihrer personenbezogenen Daten nicht, da diese
                  zur Erfüllung unserer vertraglichen und oder gesetzlichen
                  Verpflichtungen erforderlich sind.
                </p>
                <br />
                <p>
                  Sollten Ihre personenbezogenen Daten aufgrund Ihrer
                  ausdrücklichen Einwilligung verarbeitet (d. h. insbesondere
                  erhoben) worden sein, können Sie diese Einwilligung jederzeit
                  nach Art. 7 Abs. 3 DSGVO widerrufen. Dadurch wird jedoch nicht
                  die Rechtmäßigkeit der Verarbeitung Ihrer Daten bis zu Ihrem
                  Widerruf berührt.
                </p>
                <br />
                <br />
                <p>
                  <strong>4) Cookies (essenziell und nicht essenziell)</strong>
                </p>
                <br />
                <p>
                  a) Wie bereits unter 2) dargestellt, setzen wir auf unserer
                  Webseite auf Grundlage des Artikels 6 Abs. 1 S. 1 lit. f)
                  DSGVO sogenannte essenzielle Cookies ein. Hierbei handelt es
                  sich um kleine Dateien, die Ihr Browser automatisch erstellt
                  und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.)
                  gespeichert werden, wenn Sie unsere Seite besuchen. Cookies
                  richten auf Ihrem Endgerät keinen Schaden an, enthalten keine
                  Viren, Trojaner oder sonstige Schadsoftware. In dem Cookie
                  werden Informationen abgelegt, die sich jeweils im
                  Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben.
                  Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar
                  Kenntnis von Ihrer Identität erhalten. Der Einsatz von Cookies
                  dient einerseits dazu, die Nutzung unseres Angebots für Sie
                  angenehmer zu gestalten. So setzen wir sogenannte
                  Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten
                  unserer Webseite bereits besucht haben. Diese werden nach
                  Verlassen unserer Seite automatisch gelöscht. Darüber hinaus
                  setzen wir ebenfalls zum Zweck der Benutzerfreundlichkeit
                  temporäre Cookies ein, die für einen bestimmten festgelegten
                  Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie
                  unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen,
                  wird automatisch erkannt, dass Sie bereits bei uns waren und
                  welche Eingaben und Einstellungen sie getätigt haben, um diese
                  nicht noch einmal eingeben zu müssen.
                </p>
                <br />
                <p>
                  b) Zum anderen setzen wir Cookies ein, um die Nutzung unserer
                  Webseite statistisch zu erfassen und um unser Angebot für Sie
                  zu optimieren und auszuwerten. Diese Cookies ermöglichen es
                  uns, bei einem erneuten Besuch unserer Seite automatisch zu
                  erkennen, dass Sie bereits bei uns waren. Diese Cookies werden
                  nach einer jeweils definierten Zeit automatisch gelöscht. Die
                  meisten Browser akzeptieren Cookies automatisch. Sie können
                  Ihren Browser jedoch so konfigurieren, dass keine Cookies auf
                  Ihrem Computer gespeichert werden oder stets ein Hinweis
                  erscheint, bevor ein neues Cookie angelegt wird. Die
                  vollständige Deaktivierung von Cookies kann jedoch dazu
                  führen, dass Sie nicht alle Funktionen unserer Webseite nutzen
                  können. Die Speicherdauer der Cookies ist abhängig von ihrem
                  Verwendungszweck und nicht für alle gleich. Der Einsatz von
                  Cookies erfolgt für die genannten Zwecke zur Wahrung unserer
                  berechtigten Interessen.
                </p>
                <br />
                <p>
                  c) Nicht-essenzielle Cookies: Wir verwenden ein Tracking-Tool
                  von Matomo (https://matomo.org) ein Dienst der InnoCraft Ltd.,
                  150 Willis St, 6011 Wellington, New Zealand, NZBN 6106769. Bei
                  Matomo handelt es sich um ein Open-Source
                  Web-Analyse-Tracking-Tool. Matomo speichert Cookies auf Ihrem
                  Endgerät, die eine Analyse der Benutzung unserer Webseite
                  durch Sie ermöglichen. In diesem Zusammenhang werden
                  pseudonymisierte Nutzungsprofile ausschließlich auf unserem
                  Server erstellt und Cookies verwendet. Wir speichern dabei die
                  nachfolgenden Daten auf unserem Server:
                </p>
                <br />
                <p>- Browser-Typ/-Version,</p>
                <p>- verwendetes Betriebssystem,</p>
                <p>- Referrer-URL (die zuvor besuchte Seite),</p>
                <p>- Hostname des zugreifenden Rechners (IP-Adresse),</p>
                <p>- Uhrzeit der Serveranfrage,</p>
                <br />
                <p>
                  Für die Verarbeitung der Daten zum Zwecke des Nutzer-Trackings
                  (nicht-essenzielle Cookies) ist Rechtsgrundlage Ihre
                  Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO. Zweck der
                  Verarbeitung ist die bedarfsgerechte Gestaltung und
                  fortlaufende Optimierung unserer Seiten. Zum anderen setzen
                  wir die Tracking-Maßnahmen ein, um die Nutzung unserer
                  Webseite statistisch zu erfassen und zum Zwecke der
                  Optimierung unseres Angebotes für Sie auszuwerten.
                </p>
                <br />
                <p>
                  Sie können Ihre Einwilligung jederzeit nach Art. 7 Abs. 3
                  DSGVO (z.B. durch E-Mail an info@ninebarc.com widerrufen.
                  Dadurch wird jedoch nicht die Rechtmäßigkeit der Verarbeitung
                  Ihrer Daten bis zu Ihrem Widerruf berührt.
                </p>
                <br />
                <p>
                  Die Daten werden gelöscht, sobald sie für die Erreichung des
                  Zweckes ihrer Erhebung nicht mehr erforderlich sind. In der
                  Regel erfolgt eine Speicherung der Daten ohne Personenbezug
                  für eine Dauer von 90 Tagen. Danach werden die Daten gelöscht.
                </p>
                <br />
                <br />
                <p>
                  <strong>Beseitigungsmöglichkeit</strong>
                </p>
                <br />
                <p>
                  Die Auswertung können Sie verhindern, indem Sie vorhandene
                  Cookies löschen und eine Speicherung von Cookies in den
                  Einstellungen Ihres Webbrowsers deaktivieren. Wir weisen
                  darauf hin, dass Sie in diesem Fall möglicherweise nicht alle
                  Funktionen dieser Webseite vollumfänglich nutzen können.
                </p>
                <br />
                <p>
                  Wenn Sie das Tracking durch nicht-essentielle Cookies
                  ausschalten, schützen Sie Ihre Privatsphäre, verhindern aber
                  auch, dass wir aus Ihren Aktionen lernen und Ihnen und anderen
                  Benutzern eine bessere Erfahrung ermöglichen.
                </p>
                <br />
                <p>
                  Zurzeit wird Ihr Nutzerverhalten erfasst. Sie können hier
                  klicken, um das Tracking zu deaktivieren.
                </p>
                <br />
                <p>
                  Weitere Informationen zum Datenschutz finden Sie in der
                  Datenschutzerklärung von Matomo unter:
                  matomo.org/privacy-policy/
                </p>
                <br />
                <br />
                <p>
                  <strong>5) Kontaktformular und E-Mail-Kontakt</strong>
                </p>
                <br />
                <p>
                  a) Auf unserer Internetseite ist ein Kontaktformular
                  vorhanden, welches für die elektronische Kontaktaufnahme
                  genutzt werden kann. Nimmt ein Nutzer diese Möglichkeit wahr,
                  so werden die in der Eingabemaske eingegeben Daten an uns
                  übermittelt und gespeichert. Diese Daten sind:
                </p>
                <br />
                <p>- Name</p>
                <p>- E-Mail-Adresse</p>
                <p>- Telefonnummer</p>
                <br />
                <p>
                  Im Zeitpunkt der Absendung der Nachricht werden zudem folgende
                  Daten gespeichert:
                </p>
                <br />
                <p>- IP-Adresse des Nutzers</p>
                <p>- Datum und Uhrzeit der Kontaktanfrage</p>
                <br />
                <p>
                  b) Alternativ ist eine Kontaktaufnahme über die
                  bereitgestellte E-Mail-Adresse möglich. In diesem Fall werden
                  Ihre mit der E-Mail übermittelten personenbezogenen Daten
                  gespeichert. Es erfolgt in diesem Zusammenhang keine
                  Weitergabe der Daten an Dritte. Die Daten werden
                  ausschließlich für die Verarbeitung der Konversation
                  verwendet.
                </p>
                <br />
                <p>
                  c) Rechtsgrundlage für die Verarbeitung der Daten ist bei
                  Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a
                  DSGVO. Rechtsgrundlage für die Verarbeitung der Daten, die im
                  Zuge einer Übersendung einer Kontaktanfrage oder einer E-Mail
                  übermittelt werden, ist Art. 6 Abs. 1 lit. f DSGVO. Zielt der
                  E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist
                  zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1
                  lit. b DSGVO. Die Verarbeitung der personenbezogenen Daten aus
                  der Eingabemaske dient uns allein zur Bearbeitung der
                  Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail
                  liegt hieran auch das erforderliche berechtigte Interesse an
                  der Verarbeitung der Daten. Die sonstigen während des
                  Absendevorgangs verarbeiteten personenbezogenen Daten dienen
                  dazu, einen Missbrauch des Kontaktformulars zu verhindern und
                  die Sicherheit unserer informationstechnischen Systeme
                  sicherzustellen.
                </p>
                <br />
                <p>
                  d) Die Daten werden gelöscht, sobald sie für die Erreichung
                  des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für
                  die personenbezogenen Daten aus der Eingabemaske des
                  Kontaktformulars und diejenigen, die per E-Mail übersandt
                  wurden, ist dies dann der Fall, wenn die jeweilige
                  Konversation mit dem Nutzer beendet ist. Beendet ist die
                  Konversation dann, wenn sich aus den Umständen entnehmen
                  lässt, dass der betroffene Sachverhalt abschließend geklärt
                  ist. Die während des Absendevorgangs zusätzlich erhobenen
                  personenbezogenen Daten werden spätestens nach einer Frist von
                  sieben Tagen automatisch gelöscht.
                </p>
                <br />
                <br />
                <p>
                  <strong>6) Newsletter des Verantwortlichen</strong>
                </p>
                <br />
                <p>
                  Der Verantwortliche bietet Ihnen die Möglichkeit, sich auf
                  ninebarc.com für den Plattform-E-Mail-Newsletter anzumelden.
                  Die Versendung eines Newsletters erfolgt erst dann, wenn Sie
                  in dessen Erhalt unter Angabe Ihrer E-Mail-Adresse
                  eingewilligt haben. Der Einwilligungstext, dem die Reichweite
                  der zu erteilenden Einwilligung zu entnehmen ist, lautet wie
                  folgt:
                </p>
                <br />
                <p>
                  „Ich möchte den Newsletter von Ninebarc.come, betrieben von
                  Ninebarc GmbH, erhalten und über Angebote der Plattform, per
                  E-Mail informiert werden. Diese Einwilligung kann jederzeit
                  mit Wirkung für die Zukunft unter www.ninebarc.com/abmelden
                  oder am Ende jeder E-Mail widerrufen werden“.
                </p>
                <br />
                <p>
                  Für die Einwilligung setzt der Verantwortliche das sog.
                  Double-Opt-In-Verfahren ein: Nachdem Sie Ihre E-Mail-Adresse
                  in das Anmeldefeld eingegeben und Ihre Einwilligung zum Erhalt
                  des Newsletters erteilt haben, übersenden wir Ihnen einen
                  Bestätigungslink an die angegebene E-Mail-Adresse. Erst wenn
                  Sie diesen Bestätigungslink anklicken, wird Ihre Email-Adresse
                  in unseren Verteiler zum Versand des Newsletters aufgenommen.
                </p>
                <br />
                <p>
                  Dabei setzen wir sog. Dritte (Auftragsverarbeiter) gemäß Art.
                  28 DSGVO ein, die nach unserer Weisung auch personenbezogene
                  Daten in unserem Auftrag verarbeiten. Diese Dienstleister
                  verarbeiten Daten ausschließlich in der EU.
                </p>
                <br />
                <p>
                  Rechtsgrundlage für diese Datenverarbeitung ist Artikel 6
                  Absatz 1a DSGVO.
                </p>
                <br />
                <p>
                  Sie können Ihre Einwilligung jederzeit mit Wirkung für die
                  Zukunft per E-Mail an info@ninebarc.com widerrufen oder über
                  die Abmeldemöglichkeit am Ende eines jeden Newsletters
                  widerrufen.
                </p>
                <br />
                <br />
                <p>
                  <strong>7) Google Maps</strong>
                </p>
                <br />
                <p>
                  a) Auf unserer Webseite verwenden wir Google Maps (API) von
                  Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA
                  94043, USA (“Google”). Google Maps ist ein Webdienst zur
                  Darstellung von interaktiven (Land-)Karten, um geographische
                  Informationen visuell darzustellen. Über die Nutzung dieses
                  Dienstes wird Ihnen unser Standort angezeigt und eine etwaige
                  Anfahrt erleichtert.
                </p>
                <br />
                <p>
                  b) Bereits beim Aufrufen derjenigen Unterseiten, in die die
                  Karte von Google Maps eingebunden ist, werden Informationen
                  über Ihre Nutzung unserer Webseite (wie z.B. Ihre IP-Adresse)
                  an Server von Google in den USA übertragen und dort
                  gespeichert. Dies erfolgt unabhängig davon, ob Google ein
                  Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder
                  ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt
                  sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn
                  Sie die Zuordnung mit Ihrem Profil bei Google nicht wünschen,
                  müssen Sie sich vor Aktivierung des Buttons ausloggen. Google
                  speichert Ihre Daten (selbst für nicht eingeloggte Nutzer) als
                  Nutzungsprofile und wertet diese aus. Eine solche Auswertung
                  erfolgt insbesondere gemäß Art. 6 Abs. 1 lit.f DSGVO auf Basis
                  der berechtigten Interessen von Google an der Einblendung
                  personalisierter Werbung, Marktforschung und/oder
                  bedarfsgerechten Gestaltung seiner Webseite. Ihnen steht ein
                  Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile,
                  wobei Sie sich zur Ausübung dessen an Google richten müssen.
                </p>
                <br />
                <p>
                  c) Google LLC mit Sitz in den USA ist für das us-europäische
                  Datenschutzübereinkommen „Privacy Shield“ zertifiziert,
                  welches die Einhaltung des in der EU geltenden
                  Datenschutzniveaus gewährleistet.
                </p>
                <br />
                <p>
                  d) Wenn Sie mit der künftigen Übermittlung Ihrer Daten an
                  Google im Rahmen der Nutzung von Google Maps nicht
                  einverstanden sind, besteht auch die Möglichkeit, den
                  Webdienst von Google Maps vollständig zu deaktivieren, indem
                  Sie die Anwendung JavaScript in Ihrem Browser ausschalten.
                  Google Maps und damit auch die Kartenanzeige auf dieser
                  Internetseite kann dann nicht genutzt werden.
                </p>
                <br />
                <p>
                  Die Nutzungsbedingungen von Google können Sie unter
                  http://www.google.de/intl/de/policies/terms/regional.html
                  einsehen, die zusätzlichen Nutzungsbedingungen für Google Maps
                  finden Sie unter
                  https://www.google.com/intl/de_US/help/terms_maps.html.
                  Ausführliche Informationen zum Datenschutz im Zusammenhang mit
                  der Verwendung von Google Maps finden Sie auf der
                  Internetseite von Google („Google Privacy Policy“):
                  http://www.google.de/intl/de/policies/privacy/.
                </p>
                <br />
                <br />
                <p>
                  <strong>8) Google reCAPTCHA</strong>
                </p>
                <br />
                <p>
                  a) Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”)
                  auf unseren Webseites. Anbieter ist die Google Inc., 1600
                  Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”).
                  Google Inc. mit Sitz in den USA ist für das us-europäische
                  Datenschutzübereinkommen „Privacy Shield“ zertifiziert,
                  welches die Einhaltung des in der EU geltenden
                  Datenschutzniveaus gewährleistet.
                </p>
                <br />
                <p>
                  b) Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe
                  auf unseren Webseites (z.B. in einem Kontaktformular) durch
                  einen Menschen oder durch ein automatisiertes Programm
                  erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des
                  Webseitebesuchers anhand verschiedener Merkmale. Diese Analyse
                  beginnt automatisch, sobald der Webseitebesucher die Webseite
                  betritt. Zur Analyse wertet reCAPTCHA verschiedene
                  Informationen aus (z.B. IP-Adresse, Verweildauer des
                  Webseitebesuchers auf der Webseite oder vom Nutzer getätigte
                  Mausbewegungen). Die bei der Analyse erfassten Daten werden an
                  Google weitergeleitet.
                </p>
                <br />
                <p>
                  c) Die reCAPTCHA-Analysen laufen vollständig im Hintergrund.
                  Webseitebesucher werden nicht darauf hingewiesen, dass eine
                  Analyse stattfindet. Die Datenverarbeitung erfolgt auf
                  Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                  Webseitenbetreiber hat ein berechtigtes Interesse daran, seine
                  Webangebote vor missbräuchlicher automatisierter Ausspähung
                  und vor SPAM zu schützen.
                </p>
                <br />
                <p>
                  Weitere Informationen zu Google reCAPTCHA sowie die
                  Datenschutzerklärung von Google entnehmen Sie folgenden Links:
                  https://www.google.com/intl/de/policies/privacy/ und
                  https://www.google.com/recaptcha/intro/android.html.
                </p>
                <br />
                <br />
                <p>
                  <strong>9) Google Web Fonts</strong>
                </p>
                <br />
                <p>
                  a) Diese Seite nutzt zur einheitlichen Darstellung von
                  Schriftarten so genannte Web Fonts, die von Google
                  bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr
                  Browser die benötigten Web Fonts in ihren Browsercache, um
                  Texte und Schriftarten korrekt anzuzeigen.
                </p>
                <br />
                <p>
                  b) Zu diesem Zweck muss der von Ihnen verwendete Browser
                  Verbindung zu den Servern von Google aufnehmen. Hierdurch
                  erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse
                  unsere Webseite aufgerufen wurde. Die Nutzung von Google Web
                  Fonts erfolgt im Interesse einer einheitlichen und
                  ansprechenden Darstellung unserer Online-Angebote. Dies stellt
                  ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
                  DSGVO dar.
                </p>
                <br />
                <p>
                  Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
                  Standardschrift von Ihrem Computer genutzt. Weitere
                  Informationen zu Google Web Fonts finden Sie unter
                  https://developers.google.com/fonts/faq und in der
                  Datenschutzerklärung von Google:
                  https://www.google.com/policies/privacy/.
                </p>
                <br />
                <br />
                <p>
                  <strong>10) Typekit-Schriftarten von Adobe</strong>
                </p>
                <br />
                <p>
                  Wir setzen auf Grundlage unserer berechtigten Interessen (d.h.
                  Interesse an der Analyse, Optimierung und wirtschaftlichem
                  Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1
                  lit. f. DSGVO) externe „Typekit“-Schriftarten des Anbieters
                  Adobe Systems Software Ireland Limited, 4–6 Riverwalk,
                  Citywest Business Campus, Dublin 24, Republic of Ireland ein.
                  Die ordentlich grafische Darstellung unserer Webseite ist als
                  berechtigtes Interesse anzusehen. Adobe ist unter dem
                  Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine
                  Garantie, das europäische Datenschutzrecht einzuhalten:
                  https://www.privacyshield.gov/participant?id=a2zt0000000TNo9AAG&status=Active.
                </p>
                <br />
                <br />
                <p>
                  <strong>
                    11) Weitergabe von Daten an Dritte und Verarbeitung im
                    Auftrag
                  </strong>
                </p>
                <br />
                <p>
                  a) Eine Übermittlung Ihrer persönlichen Daten an Dritte
                  (Auftragsverarbeiter) zu anderen als den bereits oben
                  benannten oder im Folgenden aufgeführten Zwecke findet nicht
                  statt.
                </p>
                <br />
                <p>
                  b) Mit der Betreuung unserer EDV-Anlage beauftragen wir
                  externe IT-Dienstleister, mit den Vereinbarungen zur
                  Auftragsverarbeitung geschlossen werden. Rechtsgrundlagen
                  hierfür ist Artikel 6 Absatz 1 Buchstabe f) DSGVO. Die
                  fachmännische Betreuung unseres EDV-Systems sind als ein
                  berechtigtes Interesse im Sinne dieser Vorschrift anzusehen.
                </p>
                <br />
                <p>
                  c) Zur Erfüllung der oben aufgeführten Zwecke arbeiten wir
                  teilweise mit Dienstleistern zusammen. Dabei beschränken wir
                  den Umfang der Daten, die übermittelt werden auf das
                  erforderliche Minimum. Selbstverständlich übermitteln wir
                  Daten nur in verschlüsselter Form. Die Auswahl der
                  Dienstleister, die in unserem Auftrag Daten für uns
                  verarbeiten, haben wir sorgfältig ausgewählt und dabei darauf
                  geachtet, dass Ihre Daten nur innerhalb der EU verarbeitet
                  werden. Gemäß Art. 28 EU DSGVO sind die Dienstleister zum
                  datenschutzkonformen Umgang bei der Verarbeitung verpflichtet
                  worden.
                </p>
                <br />
                <p>
                  Im Rahmen der oben dargestellten Auftragsverarbeitung
                  übermitteln wird Daten an:
                </p>
                <br />
                <p>- IT-Dienstleister, Cloud Anbieter</p>
                <p>- Behörden, staatliche Stellen</p>
                <p>- Kreditinstitute, Zahlungsdienstleister</p>
                <p>- Newsletter-Versanddienstleister</p>
                <p>- Telekommunikationsanbieter</p>
                <p>
                  - Unternehmensberater, Steuerberater, Rechtsanwälte,
                  Versicherungen
                </p>
                <br />
                <br />
                <p>
                  <strong>12) Recht auf Widerruf der Einwilligung</strong>
                </p>
                <br />
                <p>
                  Soweit Sie die Einwilligung zu der Verarbeitung der Sie
                  betreffenden personenbezogenen Daten gemäß Art. 6 Abs. 1 S. 1
                  a DSGVO gegeben haben, haben Sie gemäß Art. 7 Abs. 3 DSGVO das
                  Recht, Ihre Einwilligung jederzeit zu widerrufen. Die
                  Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
                  erfolgten Verarbeitung wird durch den Widerruf nicht berührt.
                </p>
                <br />
                <br />
                <p>
                  <strong>13) Betroffenenrecht</strong>
                </p>
                <br />
                <p>
                  Sie haben gegenüber uns als verantwortliche Stelle folgende
                  Rechte hinsichtlich der Sie betreffenden personenbezogenen
                  Daten:
                </p>
                <br />
                <p>a) Recht auf Auskunft</p>
                <p>
                  Gemäß Art. 15 DSGVO haben Sie ein Recht auf Auskunft über die
                  von uns verarbeiteten personenbezogenen Daten. Insbesondere
                  können Sie Auskunft über den Zweck der Verarbeitung, die
                  Kategorien der Daten, die Kategorien von Empfängern von Daten,
                  ,die voraussichtliche Speicherdauer, die Herkunft ihrer Daten,
                  sofern diese nicht bei uns erhoben wurden, sowie ggfs. über
                  das Bestehen einer automatisierten Entscheidungsfindung
                  einschließlich Profiling, das Bestehen des Rechts auf
                  Berichtigung, Löschung, auf Einschränkung der Verarbeitung
                  oder das Bestehen eines Widerspruchsrechts gegen die
                  Verarbeitung verlangen.
                </p>
                <br />
                <p>b) Recht auf Berichtigung oder Löschung.</p>
                <p>
                  Unter den Voraussetzungen des Art 16 DSGVO können Sie die
                  Berichtigung Sie betreffender unrichtiger personenbezogener
                  Daten, sowie gemäß Art. 17 DSGVO die Löschung Ihrer bei uns
                  gespeicherten personenbezogenen Daten verlangen, soweit nicht
                  die Verarbeitung zur Erfüllung einer rechtlichen
                  Verpflichtung, aus Gründen des öffentlichen Interesses oder
                  zur Geltendmachung, Ausübung oder Verteidigung von
                  Rechtsansprüchen erforderlich ist.
                </p>
                <br />
                <p>c) Recht auf Einschränkung der Verarbeitung,</p>
                <p>
                  Unter den Voraussetzungen des Art 18 DSGVO haben Sie ein Recht
                  auf Einschränkung der Verarbeitung.
                </p>
                <br />
                <p>d) Recht auf Widerspruch gegen die Verarbeitung</p>
                <p>
                  Sofern Ihre personenbezogenen Daten auf Grundlage von
                  berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO
                  verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO
                  Widerspruch gegen die Verarbeitung Ihrer personenbezogenen
                  Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus
                  Ihrer besonderen Situation ergeben oder sich der Widerspruch
                  gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein
                  generelles Widerspruchsrecht, das ohne Angabe einer besonderen
                  Situation von uns umgesetzt wird. Möchten Sie von Ihrem
                  Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine
                  E-Mail an info@ninebarc.com.
                </p>
                <br />
                <p>e) Recht auf Datenübertragbarkeit.</p>
                <p>
                  Sie haben das Recht, die sie betreffenden personenbezogenen
                  Daten, welche uns bereitgestellt wurden, in einem
                  strukturierten, gängigen und maschinenlesbaren Format zu
                  erhalten oder die Übermittlung an einen anderen
                  Verantwortlichen zu verlangen.
                </p>
                <br />
                <p>
                  Sie haben zudem das Recht, sich bei der
                  Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
                  personenbezogenen Daten durch uns zu beschweren:
                </p>
                <br />
                <p>
                  Berliner Beauftragte für Datenschutz und Informationsfreiheit
                </p>
                <p>Friedrichstr. 219</p>
                <p>DE-10969 Berlin</p>
                <br />
                <br />
                <p>
                  <strong>14) Datensicherheit</strong>
                </p>
                <br />
                <p>
                  a) Alle von Ihnen persönlich übermittelten Daten,
                  einschließlich Ihrer Zahlungsdaten, werden mit dem allgemein
                  üblichen und sicheren Standard SSL (Secure Socket Layer)
                  übertragen. SSL ist ein sicherer und erprobter Standard, der
                  z.B. auch beim Onlinebanking Verwendung findet. Sie erkennen
                  eine sichere SSL-Verbindung unter anderem an dem angehängten s
                  am http (also https://…) in der Adressleiste Ihres Browsers
                  oder am Schloss-Symbol im unteren Bereich Ihres Browsers.
                </p>
                <br />
                <p>
                  b) Wir bedienen uns im Übrigen geeigneter technischer und
                  organisatorischer Sicherheitsmaßnahmen, um Ihre bei uns
                  gespeicherten persönlichen Daten gegen Manipulation,
                  teilweisen oder vollständigen Verlust und gegen unbefugten
                  Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen
                  werden entsprechend der technologischen Entwicklung
                  fortlaufend verbessert.
                </p>
                <p>
                  <span>
                    <br />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PrivacyPage;
