import React from 'react';

import {
  AssistantSection,
  B2CHowPageHeader,
  FadeInUpAnimation,
  LegacyPlanSection,
} from 'components';

import { LANGUAGES, LOCAL_STORAGE_VARS } from 'constants/common';

function B2CHowPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isEnglishLang =
    localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE) === LANGUAGES.EN;

  const sections = [
    <B2CHowPageHeader english={isEnglishLang} />,
    <LegacyPlanSection english={isEnglishLang} />,
    <AssistantSection english={isEnglishLang} />,
  ];

  return (
    <div className="t-my-0 t-mx-auto t-max-w-xl t-px-5 md:t-px-0">
      {sections.map((section, idx) => (
        <FadeInUpAnimation key={`how-page-section-${idx}`}>
          {section}
        </FadeInUpAnimation>
      ))}
    </div>
  );
}

export default B2CHowPage;
