import React from 'react';
import { motion } from 'framer-motion';

interface FadeInUpAnimationProps {
  children: React.ReactNode;
  className?: string;
  duration?: number;
  delay?: number;
}

const FadeInUpAnimation = ({
  children,
  className,
  duration,
  delay,
}: FadeInUpAnimationProps) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: duration || 0.5, delay: delay || 0.3 }}
      animate={{ y: 100 }}
      viewport={{ once: true }}
      className={className || ''}
    >
      {children}
    </motion.div>
  );
};

export default FadeInUpAnimation;
