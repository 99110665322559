import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { isCurrentLangGerman } from 'helpers/common';

import { DesktopNav, MobileNav } from 'components';

import { LANGUAGES, LOCAL_STORAGE_VARS } from 'constants/common';

import logo from 'images/logo.svg';
import en from 'images/en.svg';
import de from 'images/de.svg';
import sandwich from 'images/sandwich.svg';
import cross from 'images/cross.svg';

type NavObject = {
  liClassName?: string;
  elClassName?: string;
  type?: string;
  text?: string;
  to?: string;
  href?: string;
};

function Header({ lang, setLang }) {
  const { t, i18n } = useTranslation();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const location = useLocation();
  const curPath = location.pathname;

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const hideMobileMenu = () => {
    setShowMobileMenu(false);
  };

  const handleShowMobileMenu = (show: boolean) => {
    setShowMobileMenu(show);
  };

  const navElements: NavObject[] = [
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction ${curPath === '/' && 'isActive'}`,
      type: 'link',
      text: t('header_home'),
      to: '/',
    },
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction ${
        curPath === '/how-ninebarc-works' && 'isActive'
      }`,
      type: 'link',
      text: t('header_function'),
      to: '/how-ninebarc-works',
    },
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction ${
        curPath === '/security' && 'isActive'
      }`,
      type: 'link',
      text: t('header_security'),
      to: '/security',
    },
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction ${
        curPath === '/reseller' && 'isActive'
      }`,
      type: 'link',
      text: t('header_reseller'),
      to: '/reseller',
    },
    {
      liClassName: 'Header-navigationListItem d-block d-xl-none',
      elClassName: `Button Button--secondary Button--navigation`,
      type: 'anchor',
      text: t('header_login'),
      href: 'https://app.ninebarc.de/login',
    },
    {
      liClassName: 'Header-navigationListItem d-block d-xl-none',
      elClassName: `Button Button--primary Button--navigation`,
      type: 'anchor',
      text: t('header_signin'),
      href: 'https://app.ninebarc.de/register',
    },
  ];

  const subNavElements: NavObject[] = [
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction ${
        curPath === '/pricing' && 'isActive'
      }`,
      type: 'link',
      text: t('header_pricing'),
      to: '/pricing',
    },
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction`,
      type: 'anchor',
      text: t('header_blog'),
      href: 'https://www.blog.ninebarc.de/',
    },
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction ${
        curPath === '/contact' && 'isActive'
      }`,
      type: 'link',
      text: t('header_contact'),
      to: '/contact',
    },
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction ${
        curPath === '/faq' && 'isActive'
      }`,
      type: 'link',
      text: t('header_faq'),
      to: '/faq',
    },
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction ${
        curPath === '/jobs' && 'isActive'
      }`,
      type: 'link',
      text: t('header_jobs'),
      to: '/jobs',
    },
  ];

  return (
    <header className="Section Section--white">
      <div className="Section-content">
        <div
          className={'Header t-relative' + (showMobileMenu ? ' isActive' : '')}
        >
          <button
            className="Header-mobileToggler"
            onClick={toggleMenu}
            type="button"
          >
            <img
              className="Header-mobileTogglerImage Header-mobileTogglerImage--sandwich"
              src={sandwich}
              alt="Open menu"
            />
            <img
              className="Header-mobileTogglerImage Header-mobileTogglerImage--cross"
              src={cross}
              alt="Close menu"
            />
          </button>

          <Link to="/">
            <img src={logo} alt="logo" className="Logo" />
          </Link>

          <MobileNav
            navElements={navElements}
            subNavElements={subNavElements}
            showMobileMenu={showMobileMenu}
            setShowMobileMenu={handleShowMobileMenu}
          />

          <DesktopNav
            navElements={navElements}
            subNavElements={subNavElements}
          />

          <ul className="SiteLanguage SiteLanguage--landing">
            <li className="u-mr-10 d-none d-xl-block">
              <a
                href="https://app.ninebarc.de/login"
                className={`Button Button--secondary Button--navigation`}
                onClick={hideMobileMenu}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('header_login')}
              </a>
            </li>
            <li className="u-mr-30 d-none d-xl-block">
              <a
                href="https://app.ninebarc.de/register"
                className={`Button Button--primary Button--navigation`}
                onClick={hideMobileMenu}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('header_signin')}
              </a>
            </li>
            <li>
              <button
                onClick={() => {
                  i18n.changeLanguage(LANGUAGES.EN);
                  localStorage.setItem(
                    LOCAL_STORAGE_VARS.SITE_LANGUAGE,
                    LANGUAGES.EN
                  );
                  setLang(LANGUAGES.EN);
                }}
                className={`SiteLanguage-button ${
                  !isCurrentLangGerman(lang) && 'isActive'
                }`}
                type="button"
              >
                <img
                  className="SiteLanguage-image"
                  src={en}
                  alt="Set English as site language"
                />
              </button>
            </li>

            <li>
              <button
                onClick={() => {
                  i18n.changeLanguage(LANGUAGES.DE);
                  localStorage.setItem(
                    LOCAL_STORAGE_VARS.SITE_LANGUAGE,
                    LANGUAGES.DE
                  );
                  setLang(LANGUAGES.DE);
                }}
                className={`SiteLanguage-button ${
                  isCurrentLangGerman(lang) && 'isActive'
                }`}
                type="button"
              >
                <img
                  className="SiteLanguage-image"
                  src={de}
                  alt="Set German as site language"
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
