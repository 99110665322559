import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { scrollToElementId } from 'helpers/common';
import { ImageEnlargement } from 'components';

import resellerPageDE from 'images/img-reseller-screenshot-de.png';
import resellerPageEN from 'images/img-reseller-screenshot-en.png';

interface ResellerPageHeaderProps {
  english: boolean;
}

const ResellerPageHeader = ({ english }: ResellerPageHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="t-flex t-items-center t-mt-5 md:t-mt-10">
      <motion.div
        className="md:t-w-144"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        animate={{ y: 100 }}
        viewport={{ once: true }}
      >
        <p className="Typography Typography--landingMainTitle u-weight-700">
          {t('reseller:page_header_title')}
        </p>
        <p className="Typography Typography--landingSubTitle t-mt-6 md:t-mr-36">
          {t('reseller:page_header_subtitle')}
        </p>

        <div className="t-flex t-justify-center md:t-max-w-128">
          <ImageEnlargement
            src={english ? resellerPageEN : resellerPageDE}
            alt="Reseller Page"
            className="t-z-0 md:t-hidden t-mt-10"
          />
        </div>

        <div className="t-flex t-justify-center md:t-justify-start t-mt-10">
          <button
            className="Button Button--primary Button--large t-uppercase"
            onClick={() => {
              scrollToElementId('FeedbackSection');
            }}
          >
            {t('reseller:page_header_button')}
          </button>
        </div>
      </motion.div>

      <motion.div
        className="t-hidden md:t-justify-center t-relative t-ml-5 md:t-flex t-w-full"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
        animate={{ y: 100 }}
        viewport={{ once: true }}
        style={{ maxWidth: '630px' }}
      >
        <ImageEnlargement
          src={english ? resellerPageEN : resellerPageDE}
          alt="Reseller Page"
          className="t-z-10"
        />

        <div className="t-absolute t-top-1/2 t-bottom-0 -t-translate-y-1/2 t-w-full t-h-72">
          <motion.div
            className="t-w-full t-h-full t-bg-delta-100 t-rounded"
            transition={{
              duration: 2,
            }}
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
          />
        </div>
      </motion.div>
    </div>
  );
};

export default ResellerPageHeader;
