import React from 'react';
import { useTranslation } from 'react-i18next';

import { Carousel } from 'components';

import imgLegacyPlan1En from 'images/img-ninebarc-legacy-plan-en.png';
import imgLegacyPlan2En from 'images/img-ninebarc-asset-en.png';
import imgLegacyPlan3En from 'images/img-ninebarc-beneficiary-access-en.png';
import imgLegacyPlan1De from 'images/img-ninebarc-legacy-plan-de.png';
import imgLegacyPlan2De from 'images/img-ninebarc-asset-de.png';
import imgLegacyPlan3De from 'images/img-ninebarc-beneficiary-access-de.png';

const ITEM_KEYS = {
  SAVE_ASSET: 'saveAssets',
  EASY_MANAGEMENT: 'easyManagement',
  DATA_TRANSFER: 'dataTransfer',
};

const carouselBreakPoints = [
  {
    width: 1,
    itemsToShow: 1,
    itemsToScroll: 1,
    initialActiveIndex: 0,
  },
];

interface LegacyPlanSectionProps {
  english: boolean;
}

const LegacyPlanSection = ({ english }: LegacyPlanSectionProps) => {
  const { t } = useTranslation(['b2cHowPage']);

  const items = [
    {
      key: ITEM_KEYS.SAVE_ASSET,
      title: t('b2cHowPage:legacy_section_content_1_title'),
      content: t('b2cHowPage:legacy_section_content_1_description'),
      image: english ? imgLegacyPlan1En : imgLegacyPlan1De,
    },
    {
      key: ITEM_KEYS.EASY_MANAGEMENT,
      title: t('b2cHowPage:legacy_section_content_2_title'),
      content: t('b2cHowPage:legacy_section_content_2_description'),
      image: english ? imgLegacyPlan2En : imgLegacyPlan2De,
    },
    {
      key: ITEM_KEYS.DATA_TRANSFER,
      title: t('b2cHowPage:legacy_section_content_3_title'),
      content: t('b2cHowPage:legacy_section_content_3_description'),
      image: english ? imgLegacyPlan3En : imgLegacyPlan3De,
    },
  ];

  return (
    <div
      className="t-mt-16 md:t-mt-32 md:t-pt-8 t-bg-beta-50"
      id="LegacyPlanSection"
    >
      <div className="t-flex t-flex-col t-items-center t-text-center t-mb-10">
        <p className="Typography Typography--landingMainTitle u-weight-700">
          {t('b2cHowPage:legacy_section_title')}
        </p>

        <p className="Typography Typography--landingTitle t-text-delta-700 t-font-semibold">
          {t('b2cHowPage:legacy_section_subtitle')}
        </p>

        <hr className="t-border t-border-beta-500 t-bg-beta-500 t-w-20 t-my-6" />

        <p className="Typography Typography--landingSubTitle t-max-w-md">
          {t('b2cHowPage:legacy_section_description')}
        </p>
      </div>

      <Carousel
        breakPoints={carouselBreakPoints}
        adjustedBtnTop={64}
        adjustedBtnSide={8}
        adjustedBtnSideLeft={0.5}
        edgeButtonEnabled={true}
      >
        {items.map(({ key, title, content, image }) => (
          <div
            key={key}
            className="t-w-full t-flex t-flex-col md:t-flex-row md:t-items-center t-my-6 t-outline-none"
          >
            <div className="t-flex t-justify-center t-items-center md:t-w-136 md:t-mx-20">
              <img
                src={image}
                alt="Legacy plan features"
                className="t-max-h-96 md:t-max-h-136"
              />
            </div>
            <div className="t-text-center t-mt-10 md:t-max-w-128 md:t-text-left md:t-mt-0">
              <p className="Typography Typography--title t-font-semibold t-text-delta-700">
                {title}
              </p>
              <p className="Typography Typography--normalText t-mt-12">
                {content}
              </p>
            </div>
          </div>
        ))}
      </Carousel>

      <div className="t-flex t-justify-center">
        <a
          href="https://app.ninebarc.de/register"
          className="Button Button--primary Button--large t-uppercase t-my-16"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('b2cHowPage:legacy_section_sign_up_button')}
        </a>
      </div>
    </div>
  );
};

export default LegacyPlanSection;
