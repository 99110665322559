import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { FadeInUpAnimation } from 'components';

import imgCreateDocument from 'images/img-create-document.png';
import imgMakeItEasier from 'images/img-make-it-easier.png';
import imgDocOrdered from 'images/img-assets-order.png';

const whyContentItems = [
  {
    id: 'why_1',
    image: imgDocOrdered,
    title: 'why_section_text_item_1_title',
    description: 'why_section_text_item_1_description',
  },
  {
    id: 'why_2',
    image: imgMakeItEasier,
    title: 'why_section_text_item_2_title',
    description: 'why_section_text_item_2_description',
  },
  {
    id: 'why_3',
    image: imgCreateDocument,
    title: 'why_section_text_item_3_title',
    description: 'why_section_text_item_3_description',
  },
];

const WhySection = () => {
  const { t } = useTranslation();
  const [hoveredElement, setHoveredElement] = React.useState('');
  const [elementHovered, setElementHovered] = React.useState(false);

  return (
    <div className="t-mt-16 md:t-mt-40">
      <p className="Typography Typography--landingMainTitle u-weight-700">
        {t('why_section_title')}
      </p>
      <div
        className="t-mt-10 t-flex t-flex-col md:t-flex-row md:t-justify-between"
        onMouseEnter={() => {
          setElementHovered(true);
        }}
        onMouseLeave={() => {
          setElementHovered(false);
        }}
      >
        {whyContentItems.map(({ id, image, title, description }, idx) => {
          const isHovered = elementHovered && hoveredElement === id;
          return (
            <FadeInUpAnimation
              className="t-mt-7 md:t-mt-0 md:t-max-w-72 t-mx-6 lg:t-mx-12"
              key={title}
            >
              <div
                className="t-flex t-flex-col t-items-center"
                onMouseEnter={() => {
                  setHoveredElement(whyContentItems[idx].id);
                }}
                onMouseLeave={() => {
                  setHoveredElement('');
                }}
              >
                <img src={image} className="t-h-32" alt={t(title)} />
                <div className="t-max-w-60 md:t-max-w-72">
                  <p
                    className={`typo-beta t-mt-8 t-text-center t-h-11 ${
                      isHovered ? 't-text-delta-500' : ''
                    }`}
                  >
                    {t(title)}
                  </p>

                  <div className="t-h-0.5 t-mt-2 t-flex t-justify-center">
                    {isHovered && (
                      <motion.div
                        className="t-bg-delta-500 t-w-24 t-h-0.5"
                        initial={{ opacity: 0, origin: 'c' }}
                        transition={{ duration: 0.5 }}
                        animate={{ scaleX: 2, opacity: 1 }}
                      />
                    )}
                  </div>

                  <p className="typo-delta t-mt-5 t-text-center">
                    {t(description)}
                  </p>
                </div>
              </div>
            </FadeInUpAnimation>
          );
        })}
      </div>
    </div>
  );
};

export default WhySection;
