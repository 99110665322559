import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import imgDashboardDe from 'images/img-dashboard-de.png';
import imgDashboardEn from 'images/img-dashboard-en.png';
import imgDashboardMobile from 'images/img-dashboard-mobile-en.png';
import imgDashboardMobileDe from 'images/img-dashboard-mobile-de.png';
import imgStep from 'images/img-step-by-step-planning.png';
import imgSecuredDoc from 'images/img-secured-document.png';
import imgBeneficiary from 'images/img-beneficiary.png';
import imgDataTracking from 'images/img-data-tracking.png';

const howContentItems = [
  {
    image: imgStep,
    description: 'how_section_text_item_1_title',
  },
  {
    image: imgSecuredDoc,
    description: 'how_section_text_item_2_title',
  },
  {
    image: imgBeneficiary,
    description: 'how_section_text_item_3_title',
  },
  {
    image: imgDataTracking,
    description: 'how_section_text_item_4_title',
  },
];

interface LegacyPlanSectionProps {
  english: boolean;
}

const HowSection = ({ english }: LegacyPlanSectionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const redirectToHowPage = () => {
    navigate('/how-ninebarc-works');
  };

  return (
    <div className="t-mt-16 md:t-mt-40">
      <p className="Typography Typography--landingMainTitle u-weight-700">
        {t('how_section_title')}
      </p>
      <div className="t-flex t-justify-center t-px-10 t-mt-5">
        <img
          src={english ? imgDashboardEn : imgDashboardDe}
          alt="Ninebarc's dashboard"
          className="t-hidden md:t-block"
        />
        <img
          src={english ? imgDashboardMobile : imgDashboardMobileDe}
          alt="Ninebarc's dashboard"
          className="md:t-hidden t-max-h-112"
        />
      </div>

      <div className="t-mt-10 t-flex t-flex-col t-items-center md:t-flex-row md:t-justify-between md:t-items-start">
        {howContentItems.map(({ image, description }) => (
          <motion.div
            className="t-max-w-56 t-mx-3 md:t-mx-6 t-flex t-flex-col t-items-center t-mt-8 md:t-mt-0"
            key={description}
            whileHover={{ scale: 1.2 }}
          >
            <div>
              <img src={image} alt={t(description)} className="t-h-20" />
            </div>
            <p className="typo-beta t-text-center t-mt-8 t-max-w-60">
              {t(description)}
            </p>
          </motion.div>
        ))}
      </div>

      <div className="t-flex t-justify-center t-mt-20">
        <button
          className="Button Button--primary Button--large t-uppercase"
          onClick={redirectToHowPage}
        >
          {t('how_section_cta_button')}
        </button>
      </div>
    </div>
  );
};

export default HowSection;
