import React from 'react';
import { motion } from 'framer-motion';

interface IconProfitProps {
  className?: string;
}

const IconProfit = ({ className }: IconProfitProps) => {
  return (
    <svg
      width="206"
      height="272"
      viewBox="0 0 206 272"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M103 206C159.885 206 206 159.885 206 103C206 46.1147 159.885 0 103 0C46.1147 0 0 46.1147 0 103C0 159.885 46.1147 206 103 206Z"
        fill="#DEE9F3"
      />
      <mask
        id="mask0_15421_24835"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="206"
        height="206"
      >
        <path
          d="M103 206C159.885 206 206 159.885 206 103C206 46.1147 159.885 0 103 0C46.1147 0 0 46.1147 0 103C0 159.885 46.1147 206 103 206Z"
          fill="#D6E8F6"
        />
      </mask>
      <g mask="url(#mask0_15421_24835)"></g>
      <path
        d="M91.3431 125.607C91.3431 125.607 86.8256 129.816 81.1796 127.096C75.5336 124.376 54.9125 109.82 55.5373 106.251C56.1622 102.682 65.8001 101.84 65.8001 101.84C65.8001 101.84 95.6512 125.442 91.3431 125.607Z"
        fill="#413D45"
      />
      <path
        d="M85.9143 125.061C89.7812 124.443 77.0593 114.324 72.8984 110.141C68.7374 105.958 66.054 104.006 66.054 104.006C66.054 104.006 58.9414 104.87 58.9414 106.252C58.9414 107.634 79.3383 123.936 79.3383 123.936C79.3383 123.936 84.1683 125.34 85.9143 125.061Z"
        fill="#DEE9F3"
      />
      <path
        d="M110.876 143.263C110.876 143.263 103.451 130.714 99.8962 127.366C96.3417 124.017 84.3329 117.533 82.5207 117.493C79.4037 117.426 93.2099 126.741 93.2099 126.741C93.2099 126.741 91.3279 138.871 93.0004 143.263C94.6729 147.656 112.243 153.512 112.243 153.512L110.876 143.263Z"
        fill="#E5C3A5"
      />
      <path
        d="M124.682 156.437C124.682 156.437 112.552 138.238 109.832 139.492C107.112 140.745 99.7969 156.437 99.7969 156.437C99.7969 156.437 121.116 178.609 121.116 174.423C121.116 170.236 124.682 156.437 124.682 156.437Z"
        fill="#1E5D8F"
      />
      <path
        d="M158.244 58.9785C158.244 58.9785 170.782 103.323 179.148 121.731C187.514 140.14 191.693 174.439 190.024 234.681C175.668 254.456 171.748 252.497 154.185 260.025C136.623 267.553 116.404 273.698 108.038 254.456C99.6715 235.213 99.4311 178.927 120.163 150.175C140.651 121.794 149.867 111.689 149.867 104.996C149.867 98.302 141.78 69.8552 141.78 69.8552L158.244 58.9785Z"
        fill="#E5C3A5"
      />
      <path
        d="M100.08 260.024C91.7098 240.778 99.4295 178.926 120.161 150.174C129.718 136.959 136.798 127.707 141.657 120.915C151.581 114.986 162.561 109.461 173.085 106.252C175.191 112.185 177.275 117.606 179.146 121.731C187.516 140.135 191.699 174.438 190.023 234.68C190.023 260.82 182.032 271.959 157.366 271.959C130.314 271.959 108.036 271.959 100.08 260.024Z"
        fill="#4B81AC"
      />
      <path
        d="M173.283 106.463C164.531 116.011 141.737 117.44 141.457 120.785C141.178 124.13 132.449 131.702 128.748 138.429C125.046 145.156 190.961 182.726 188.546 164.432C186.131 146.137 173.839 115.667 173.283 106.463Z"
        fill="#4B81AC"
      />
      <path
        d="M149.828 105.742C149.853 105.498 149.867 105.252 149.868 105.007C149.868 98.3131 141.781 69.8662 141.781 69.8662L158.245 58.9785C158.245 58.9785 161.16 69.2707 165.115 81.9999C159.991 97.2765 155.202 102.485 149.828 105.742Z"
        fill="#DCB899"
      />
      <path
        d="M112.064 80.6895C115.541 96.4549 126.432 107.67 139.022 104.582C148.252 102.321 162.444 89.4084 157.894 70.57C154.104 54.8744 141.334 44.2845 128.678 47.0781C116.023 49.8717 108.583 64.9203 112.064 80.6895Z"
        fill="#E5C3A5"
      />
      <path
        d="M97.7723 183.343C86.348 163.612 85.0725 145.185 76.9784 139.432C65.5835 131.346 56.2396 113.231 60.3639 112.518C64.4881 111.805 81.1798 127.096 81.1798 127.096C81.1798 127.096 76.8351 121.153 77.8716 120.657C78.9082 120.16 87.3184 126.262 88.6233 133.474C89.9282 140.686 106.08 173.819 119.647 184.435C133.214 195.051 109.197 203.071 97.7723 183.343Z"
        fill="#E5C3A5"
      />
      <path
        d="M171.781 133.633C192.024 138.672 177.617 189.549 160.55 225.811C148.193 252.068 123.155 231.381 108.813 218.04C104.258 214.669 101.259 211.251 99.2852 208.307C103.134 186.671 103.866 182.469 112.016 166.137C139.068 215.468 119.176 129.537 171.781 133.633Z"
        fill="#1E5D8F"
      />
      <path
        d="M165.697 129.243C191.156 131.794 177.214 195.341 158.599 220.998C139.985 246.655 113.494 221.836 105.124 207.052C96.7544 192.268 79.8164 167.141 79.8164 150.787C79.8164 147.722 86.091 147.093 93.6226 147.652C100.713 148.196 132.744 192.272 132.744 192.272C132.744 192.272 143.441 127.012 165.697 129.243Z"
        fill="#4B81AC"
      />
      <path
        d="M104.291 47.8063C88.7241 54.3602 98.0422 75.088 106.258 75.8672C114.473 76.6465 123.953 61.5096 128.827 60.9987C133.701 60.4878 127.842 69.453 132.179 71.5151C136.517 73.5772 140.567 76.9736 141.832 82.3587C143.096 87.7437 146.563 99.4694 140.898 99.9804C135.234 100.491 129.081 94.338 125.236 94.0991C122.808 93.9657 120.375 94.2555 118.046 94.9556C118.046 94.9556 127.816 108.46 134.991 109.229C142.166 109.997 149.988 99.5135 155.576 90.6512C161.163 81.7889 163.734 81.7742 163.734 81.7742C163.734 81.7742 161.31 82.2851 164.883 81.7742C168.456 81.2633 162.681 76.1503 162.424 60.9987C162.166 45.8471 155.498 37.9221 147.29 37.9221C139.082 37.9221 140.112 33.7464 127.577 34.0773C116.281 34.3676 107.941 46.2698 104.291 47.8063Z"
        fill="#413D45"
      />
      <path
        d="M138.516 78.7239C138.148 82.6312 140.159 86.0129 142.997 86.2739C145.834 86.5349 148.426 83.5832 148.79 79.6796C149.154 75.7759 147.147 72.3905 144.309 72.1258C141.471 71.8612 138.88 74.8202 138.516 78.7239Z"
        fill="#E5C3A5"
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.704 45.1953C46.4001 45.1953 36.4258 55.1696 36.4258 67.4735C36.4258 79.7775 46.4001 89.7518 58.704 89.7518C71.0079 89.7518 80.9823 79.7775 80.9823 67.4735C80.9823 55.1696 71.0079 45.1953 58.704 45.1953ZM56.2246 60.0458C56.2246 58.6787 57.3328 57.5704 58.6999 57.5704C60.067 57.5704 61.1753 58.6787 61.1753 60.0458V64.9957H66.1268C67.4939 64.9957 68.6022 66.104 68.6022 67.4711C68.6022 68.8382 67.4939 69.9464 66.1268 69.9464H61.1753V74.8979C61.1753 76.265 60.067 77.3733 58.6999 77.3733C57.3328 77.3733 56.2246 76.265 56.2246 74.8979V69.9464H51.2747C49.9076 69.9464 48.7993 68.8382 48.7993 67.4711C48.7993 66.104 49.9075 64.9957 51.2747 64.9957H56.2246V60.0458Z"
        fill="#40946A"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: [0, 1, 0], scale: [0, 1, 0] }}
        transition={{
          duration: 3,
          ease: 'easeInOut',
          repeat: Infinity,
        }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2199 108.848C30.6285 108.848 25.2852 114.191 25.2852 120.782C25.2852 127.374 30.6285 132.717 37.2199 132.717C43.8113 132.717 49.1547 127.374 49.1547 120.782C49.1547 114.191 43.8113 108.848 37.2199 108.848ZM35.895 116.803C35.895 116.07 36.4888 115.476 37.2211 115.476C37.9535 115.476 38.5472 116.07 38.5472 116.803V119.453H41.1977C41.9301 119.453 42.5238 120.047 42.5238 120.78C42.5238 121.512 41.9301 122.106 41.1977 122.106H38.5472V124.759C38.5472 125.491 37.9535 126.085 37.2211 126.085C36.4888 126.085 35.895 125.491 35.895 124.759V122.106H33.2412C32.5089 122.106 31.9151 121.512 31.9151 120.78C31.9151 120.047 32.5088 119.453 33.2412 119.453H35.895V116.803Z"
        fill="#40946A"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: [0, 1, 0], scale: [0, 1, 0] }}
        transition={{
          duration: 3,
          ease: 'easeInOut',
          repeat: Infinity,
        }}
      />
    </svg>
  );
};

export default IconProfit;
