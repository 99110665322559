import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import {
  Footer,
  SafetyPage,
  AboutUsPage,
  PricingPage,
  FaqPage,
  ImprintPage,
  PrivacyPage,
  TermsPage,
  ContactsPage,
  BeneficiaryHowToPage,
  NotFoundPage,
  HomePage,
  B2CHowPage,
  B2B2ResellerPage,
  Header,
  KeyHolderPage,
  ReleaseNotes,
  JobsPage,
  VBBBPrivacyPage,
} from 'components';

import { ReactComponent as IconArrowUp } from 'images/icon-arrow-up-no-color.svg';
import 'styles/App.scss?v=1.1';

declare global {
  interface Window {
    displayWidget: any;
  }
}

function App() {
  const { i18n } = useTranslation();
  const { trackPageView } = useMatomo();

  const [lang, setLang] = useState(i18n.language);
  const btnRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    trackPageView();
  }, []);

  const handleDisplayScrollButton = () => {
    if (btnRef?.current) {
      if (
        document.body.scrollTop > 600 ||
        document.documentElement.scrollTop > 600
      ) {
        btnRef.current.style.display = 'block';
      } else {
        btnRef.current.style.display = 'none';
      }
    }
  };

  window.onscroll = function () {
    handleDisplayScrollButton();
  };

  const scrollToTop = () => {
    setTimeout(async () => {
      await window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 100);
  };

  return (
    <div className="t-flex t-flex-col t-bg-gamma-400">
      <div
        className="t-fixed t-bottom-8 t-right-8 t-bg-transparent t-z-50"
        ref={btnRef}
      >
        <motion.button
          className="t-w-12 t-h-12 t-rounded-full t-bg-alpha-600 t-flex t-justify-center t-items-center t-opacity-80 t-outline-none"
          onClick={scrollToTop}
          whileHover={{ scale: 1.2 }}
        >
          <IconArrowUp className="t-w-7 t-h-7 t-text-gamma-400 t-opacity-80" />
        </motion.button>
      </div>
      <div className="t-relative">
        <Header lang={lang} setLang={setLang} />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/security" element={<SafetyPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/pricing" element={<PricingPage lang={lang} />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/jobs" element={<JobsPage />} />

          <Route path="/imprint" element={<ImprintPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/privacy-vbbb" element={<VBBBPrivacyPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/contact" element={<ContactsPage />} />
          <Route path="/beneficiary" element={<BeneficiaryHowToPage />} />
          <Route path="/how-ninebarc-works" element={<B2CHowPage />} />
          <Route path="/reseller" element={<B2B2ResellerPage />} />
          <Route path="/key-holder" element={<KeyHolderPage />} />
          <Route path="/release-notes" element={<ReleaseNotes />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>

        <Footer />
      </div>
    </div>
  );
}

export default App;
