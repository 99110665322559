const configVars = {
  beUrl: process.env.REACT_APP_BE_URL,
  feUrl: process.env.REACT_APP_FE_URL,
  mailjetToken: process.env.REACT_APP_MAILJET_TOKEN,
  jobsUrl: process.env.REACT_APP_JOIN_JOBS_URL,
  matomoTrackerBaseUrl: process.env.REACT_APP_MATOMO_BASE_URL,
  matomoSiteId: Number(process.env.REACT_APP_MATOMO_SITE_ID),
};

export default configVars;
