import React from 'react';

function TermsPage() {
  return (
    <main className="Page-content">
      <div className="Section Section--white">
        <div className="Section-content">
          <div className="Task list">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="u-overflow-hidden">
                  <h1 className="Typography Typography--landingQuote u-weight-700 u-pb-24">
                    ALLGEMEINE GESCHÄFTSBEDINGUNGEN
                  </h1>

                  <p>
                    <strong>§ 1 Geltungsbereich und Anbieter</strong>{' '}
                  </p>
                  <br />
                  <p>
                    (1) Die Allgemeinen Geschäftsbedingungen (nachfolgend „AGB"
                    genannt) regeln das Vertragsverhältnis zwischen Ninebarc
                    GmbH (nachfolgend Anbieter) und Ihnen (nachfolgend Nutzer),
                    in ihrer zum Zeitpunkt des Vertragsabschlusses gültigen
                    Fassung.
                  </p>
                  <br />
                  <p>
                    (2) Abweichende AGB des Nutzers werden zurückgewiesen. Bitte
                    lesen Sie diese Bedingungen aufmerksam, bevor Sie eine
                    Dienstleistung der Ninebarc GmbH in Anspruch nehmen.
                  </p>
                  <br />
                  <p>
                    (3) Auf Ninebarc bieten wir Ihnen folgende Dienstleistungen
                    an: Auf dem Onlineportal von Ninebarc können Sie
                    Vorsorgedokumente digital erstellen und Ihre Daten,
                    Dokumente und Kontakte sowie Ihren Tresor digital verwalten
                    und speichern.{' '}
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 2 Zustandekommen des Vertrages</strong>
                  </p>
                  <br />
                  <p>
                    (1) Verträge auf diesem Portal können ausschließlich in
                    deutscher Sprache abgeschlossen werden.{' '}
                  </p>
                  <br />
                  <p>
                    (2) Der Nutzer muss das 18. Lebensjahr vollendet haben.{' '}
                  </p>
                  <br />
                  <p>
                    (3) Der Zugang zur Nutzung des Ninebarc-Service setzt die
                    Anmeldung voraus.{' '}
                  </p>
                  <br />
                  <p>
                    (4) Mit der Anmeldung erkennt der Nutzer die vorliegenden
                    AGB an. Mit der Anmeldung entsteht ein Vertragsverhältnis
                    zwischen Ninebarc und dem angemeldeten Nutzer, das sich nach
                    den Regelungen dieser AGB richtet.{' '}
                  </p>
                  <br />
                  <p>
                    (5) Die Präsentation der Dienstleistung auf der Website
                    stellt kein rechtlich wirksames Angebot dar. Durch die
                    Präsentation der Dienstleistung wird der Kunde lediglich
                    dazu aufgefordert ein Angebot zu machen.{' '}
                  </p>
                  <br />
                  <p>
                    (6) Mit Bestellung eines kostenpflichtigen Dienstes geht der
                    angemeldete Nutzer ein weiteres, von der Anmeldung
                    getrenntes Vertragsverhältnis mit Ninebarc ein. Der Nutzer
                    wird vor Abschluss dieses Vertragsverhältnisses über den
                    jeweiligen kostenpflichtigen Dienst und die
                    Zahlungsbedingungen informiert. Das Vertragsverhältnis
                    entsteht, indem der Nutzer die Bestellung und
                    Zahlungsverpflichtung durch das Anklicken des Buttons
                    „zahlungspflichtigen Vertrag schließen" bestätigt.{' '}
                  </p>
                  <br />
                  <p>
                    (7) Sie stimmen zu, dass Sie Rechnungen elektronisch
                    erhalten. Elektronische Rechnungen werden Ihnen per E-Mail
                    oder in dem Kundenkonto der Webseite zur Verfügung gestellt.
                    Wir werden Sie für jede Dienstleistung darüber informieren,
                    ob eine elektronische Rechnung verfügbar ist. Weitere
                    Informationen über elektronische Rechnungen erhalten Sie auf
                    unserer Website.{' '}
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 3 Beschreibung des Leistungsumfanges</strong>
                  </p>
                  <br />
                  <p>
                    Der Leistungsumfang von Ninebarc besteht aus folgenden
                    Dienstleistungen:
                  </p>
                  <br />
                  <p>
                    Ninebarc betreibt ein Onlineportal, um Nutzern
                    Hilfestellungen bei deren individuellem digitalen Nachlass
                    zu geben. Hierbei kann sich der Nutzer kostenlos
                    registrieren und das Angebot von Ninebarc 7 Tage kostenlos
                    testen. Nach Ablauf des kostenfreien Zeitraums kommt ein
                    kostenpflichtiges Abonnement zustande, wenn der Nutzer nicht
                    innerhalb des kostenfreien Zeitraums widerspricht.{' '}
                  </p>
                  <br />
                  <p>
                    Die auf www.ninebarc.de sowie app.ninebarc.de
                    bereitgestellten Inhalte und Leistungen sind ausschließlich
                    für den Deutschen Rechtsraum konzipiert und eigenen sich nur
                    für die Verwendung in der Bundesrepublik Deutschland. Bei
                    einem Auslandsbezug (wie z.B. einem gewöhnlichen Aufenthalt
                    im Ausland oder einer ausländischen Staatsangehörigkeit)
                    bedarf es einer individuellen Rechtsberatung. Die Dienste
                    auf Ninebarc stellen ausdrücklich keine Rechtsberatung dar.{' '}
                  </p>
                  <br />
                  <p>
                    Ninebarc stellt auf der Webseite u.a. eine
                    Vorsorge-Aufgabenliste zur Verfügung, mit dem der Nutzer
                    schnell und einfach die wichtigsten Vorsorge-Unterlagen wie
                    eine Vorsorgevollmacht, eine Patienten- und
                    Betreuungsverfügung, ein Testament etc. erstellen kann. Ein
                    automatisierter Fragebogen berücksichtigt dabei Ihre ganz
                    individuelle Lebenssituation und passt Ihr Dokument
                    entsprechend an.{' '}
                  </p>
                  <br />
                  <p>
                    Die Online-Tools, mit deren Hilfe der Nutzer seine
                    individuellen Vorsorge-Unterlagen erstellen kann, sind für
                    den Nutzer nur als Hilfestellung zu sehen und ersetzen nicht
                    die Beratung durch einen Rechtsanwalt (m/w/d).{' '}
                  </p>
                  <br />
                  <p>
                    Der Nutzer kann seine individuell erstellten Dokumente auf
                    dem Onlineportal digital verwalten. Er kann jederzeit auf
                    die Dokumente zurückgreifen und Änderungen vornehmen. Zudem
                    erhält er die Möglichkeit, Kontakt- bzw. Vertrauenspersonen
                    zu benennen, die bei Tod des Nutzers informiert werden
                    und/oder die die Zugangsdaten des Nutzers erhalten, um
                    dessen Vorsorgeentscheidungen einzusehen.{' '}
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 4 Preise und Versandkosten</strong>
                  </p>
                  <br />
                  <p>
                    (1) Zur Nutzung von Ninebarc ist zunächst eine Registrierung
                    notwendig.
                  </p>
                  <br />
                  <p>
                    (2) Um die Dienstleistungen der Website kaufen zu können,
                    muss der Nutzer sich registrieren und ein Benutzerkonto
                    erstellen.
                  </p>
                  <br />
                  <p>
                    (3) Sofern der Nutzer einen kostenpflichtigen Dienst in
                    Anspruch nehmen möchte, wird er vorher auf die
                    Kostenpflichtigkeit hingewiesen. So werden ihm insbesondere
                    der jeweilige zusätzliche Leistungsumfang, die anfallenden
                    Kosten und die Zahlungsweise aufgeführt.
                  </p>
                  <br />
                  <p>
                    (4) Der Anbieter behält sich das Recht vor, für verschiedene
                    Buchungszeitpunkte und Nutzergruppen und insbesondere für
                    verschiedene Nutzungszeiträume unterschiedliche
                    Entgeltmodelle zu berechnen, wie auch verschiedene
                    Leistungsumfänge anzubieten.
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 5 Zahlungsbedingungen</strong>
                  </p>
                  <br />
                  <p>
                    (1) Ein anfallendes Entgelt ist im Voraus, zum Zeitpunkt der
                    Fälligkeit ohne Abzug an Ninebarc zu entrichten.
                  </p>
                  <br />
                  <p>
                    (2) Mit der Anmeldung, der Angabe der für das
                    Bezahlverfahren notwendigen Informationen sowie der Nutzung
                    des kostenpflichtigen Dienstes erteilt der Nutzer dem
                    Betreiber die Ermächtigung zum Einzug des entsprechenden
                    Betrags.
                  </p>
                  <br />
                  <p>
                    (3) Ein kostenpflichtiger Dienst verlängert sich um den
                    jeweils gebuchten Zeitraum (Abonnement) automatisch, soweit
                    dieser nicht per E-Mail, Fax oder Brief gekündigt wird.
                  </p>
                  <br />
                  <p>
                    (4) Das Abonnement wird zum folgenden Zeitpunkt eingezogen:
                    am ersten Werktag jeden Monats.
                  </p>
                  <br />
                  <p>
                    (5) Bestimmte Zahlungsarten können im Einzelfall von dem
                    Anbieter ausgeschlossen werden.
                  </p>
                  <br />
                  <p>
                    (6) Dem Nutzer ist nicht gestattet, die Dienstleistung durch
                    das Senden von Bargeld oder Schecks zu bezahlen.
                  </p>
                  <br />
                  <p>
                    (7) Sollte der Nutzer ein Online-Zahlungsverfahren wählen,
                    ermächtigt der Nutzer den Anbieter dadurch, die fälligen
                    Beträge zum Zeitpunkt der Bestellung einzuziehen.
                  </p>
                  <br />
                  <p>
                    (8) Sollte der Anbieter die Bezahlung per Vorkasse anbieten
                    und der Nutzer diese Zahlungsart wählen, hat der Nutzer den
                    Rechnungsbetrag innerhalb von fünf Kalendertagen nach
                    Eingang der Bestellung, auf das Konto des Anbieters zu
                    überweisen.
                  </p>
                  <br />
                  <p>
                    (9) Sollte der Anbieter die Bezahlung per Kreditkarte
                    anbieten und der Nutzer diese Zahlungsart wählen, ermächtigt
                    dieser den Anbieter ausdrücklich dazu, die fälligen Beträge
                    einzuziehen.
                  </p>
                  <br />
                  <p>
                    (10) Sollte der Anbieter die Bezahlung per Lastschrift
                    anbieten und der Nutzer diese Zahlungsart wählen, erteilt
                    der Nutzer dem Anbieter ein SEPA Basismandat. Sollte es bei
                    der Zahlung per Lastschrift zu einer Rückbuchung einer
                    Zahlungstransaktion mangels Kontodeckung oder aufgrund
                    falsch übermittelter Daten der Bankverbindung kommen, so hat
                    der Nutzer dafür die Kosten zu tragen.
                  </p>
                  <br />
                  <p>
                    (11) Sollte der Nutzer mit der Zahlung in Verzug kommen, so
                    behält sich der Anbieter die Geltendmachung des
                    Verzugschadens vor.
                  </p>
                  <br />
                  <p>
                    (12) Die Abwicklung kann über folgende Zahlungsmittel
                    erfolgen:
                  </p>
                  <br />
                  <p>- Kreditkarte</p>
                  <p>- Lastschrift</p>
                  <br />
                  <p>
                    Im Falle einer vom Nutzer zu vertretenden Rücklastschrift
                    erhebt die Ninebarc GmbH einen pauschalierten Schadensersatz
                    in Höhe von 3,50 € (drei Euro und fünfzig Cent). Der Nutzer
                    kann nachweisen, dass ein Schaden überhaupt nicht entstanden
                    oder wesentlich niedriger ist als die Pauschale.
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 6 Anmeldung und Kündigung</strong>
                  </p>
                  <br />
                  <p>
                    (1) Ein Nutzerkonto ist für seine/ihre alleinige und
                    persönliche Nutzung bestimmt. Der Nutzer kann aber
                    Vertrauenspersonen benennen, die autorisiert werden, dieses
                    Konto zu nutzen.
                  </p>
                  <br />
                  <p>
                    (2) Ein Nutzer ist, unter Vorbehalt, jederzeit berechtigt,
                    sich ohne Angabe eines Grundes schriftlich per Post, E-Mail
                    oder Fax abzumelden. Gleichzeitig besteht bei die
                    Möglichkeit, innerhalb der Daten und Einstellungen im
                    Nutzer-Account diesen vollständig und eigenhändig zu
                    deaktivieren. Das vorher geschlossene Vertragsverhältnis ist
                    damit beendet.
                  </p>
                  <br />
                  <p>
                    (3) Hat ein Nutzer sich für einen entgeltlichen Dienst
                    angemeldet, so kann er spätestens 1 Tag vor dem
                    Buchungszeitraum kündigen. Wird diese Frist nicht
                    eingehalten, so verlängert sich der kostenpflichtige Dienst
                    je nach gewählter Buchungszeit um diese und die Kündigung
                    wird erst zum Ende des Folgebuchungszeitraumes wirksam. Eine
                    Kündigung ist per Fax, E-Mail oder Brief möglich und wird
                    von uns schriftlich bestätigt. Damit Ihre Kündigung
                    zugeordnet werden kann sollen der vollständige Name, die
                    hinterlegte E-Mail-Adresse und die Anschrift des Kunden
                    angegeben werden.
                  </p>
                  <br />
                  <p>
                    (4) Ninebarc kann den Vertrag nach eigenem Ermessen, mit
                    oder ohne vorherige Ankündigung und ohne Angabe von Gründen,
                    zu jeder Zeit kündigen. Ninebarc hält sich weiterhin das
                    Recht vor, Profile zu entfernen. Falls Ninebarc die
                    Registrierung des Nutzers beendet und/oder Profile oder
                    veröffentliche Inhalte des Nutzers entfernt, besteht für
                    Ninebarc keine Verpflichtung, den Nutzer darüber noch über
                    den Grund der Beendigung oder der Entfernung zu informieren.
                  </p>
                  <br />
                  <p>
                    (5) Im Anschluss an jede Beendigung von jedweder
                    individuellen Nutzung der Services von Ninebarc, hält
                    Ninebarc sich das Recht vor, eine Information hierüber an
                    andere registrierte Nutzer, mit denen Ninebarc annimmt, dass
                    diese in Kontakt mit dem Nutzer standen, zu versenden.
                    Ninebarc's Entscheidung die Registrierung des Nutzers zu
                    beenden und/oder weitere Nutzer zu benachrichtigen mit dem
                    Ninebarc annimmt, dass der Nutzer in Kontakt stand,
                    impliziert nicht bzw. sagt keinesfalls aus, dass Ninebarc
                    Aussagen über den individuellen Charakter, generelle
                    Reputation, persönliche Charakteristika noch über den
                    Lebensstil trifft.
                  </p>
                  <br />
                  <p>
                    (6) Wird der Zugang eines Nutzers wegen schuldhaften
                    Vertragsverstoßes gesperrt und/oder das Vertragsverhältnis
                    aufgelöst, hat der Nutzer für die verbleibende
                    Vertragslaufzeit Schadenersatz in Höhe des vereinbarten
                    Entgelts abzüglich der ersparten Aufwendungenzu zahlen. Die
                    Höhe der ersparten Aufwendungen wird pauschal auf 10% des
                    Entgelts angesetzt. Es bleibt beiden Vertragsparteien
                    unbenommen nachzuweisen, dass der Schaden, und/oder die
                    ersparten Aufwendungen tatsächlich höher oder niedriger
                    sind.
                  </p>
                  <br />
                  <p>
                    (7) Nach Beendigung des Vertragsverhältnisses werden
                    sämtliche Daten des Nutzers von Ninebarc gelöscht.
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 7 Haftungsbegrenzung (Dienstleistungen)</strong>
                  </p>
                  <br />
                  <p>
                    (1) Ninebarc übernimmt keine Verantwortung für den Inhalt
                    und die Richtigkeit der Angaben in den Anmelde- und
                    Profildaten der Nutzer sowie weiteren von den Nutzern
                    generierten Inhalten.
                  </p>
                  <br />
                  <p>
                    (2) In Bezug auf die gesuchte oder angebotene Dienstleistung
                    kommt der Vertrag ausschließlich zwischen den jeweilig
                    beteiligten Nutzern zustande. Daher haftet Ninebarc nicht
                    für Leistungen der teilnehmenden Nutzer. Entsprechend sind
                    alle Angelegenheiten bzgl. der Beziehung zwischen den
                    Nutzern einschließlich, und ohne Ausnahme, der
                    Dienstleistungen, die ein Suchender erhalten hat oder
                    Zahlungen die an Nutzer fällig sind, direkt an die jeweilige
                    Partei des zu richten. Ninebarc kann hierfür nicht
                    verantwortlich gemacht werden und widerspricht hiermit
                    ausdrücklich allen etwaigen Haftungsansprüchen welcher Art
                    auch immer einschließlich Forderungen, Leistungen, direkte
                    oder indirekte Beschädigungen jeder Art, bewusst oder
                    unbewusst, vermutet oder unvermutet, offengelegt oder nicht,
                    in welcher Art auch immer im Zusammenhang mit den genannten
                    Angelegenheiten.
                  </p>
                  <br />
                  <p>
                    (3) Für Schäden aus der Verletzung des Lebens, des Körpers
                    oder der Gesundheit haftet Ninebarc GmbH nur, wenn sie auf
                    einer vorsätzlichen oder fahrlässigen Pflichtverletzung von
                    Ninebarc GmbH oder einer vorsätzlichen oder fahrlässigen
                    Pflichtverletzung eines gesetzlichen Vertreters oder
                    Erfüllungsgehilfen von Ninebarc GmbH beruhen.
                  </p>
                  <br />
                  <p>
                    (4) Für sonstige Schäden, soweit sie nicht auf der
                    Verletzung von Kardinalpflichten (solche Pflichten, deren
                    Erfüllung die ordnungsgemäße Durchführung des Vertrages
                    überhaupt erst ermöglichen und auf deren Einhaltung der
                    Vertragspartner regelmäßig vertrauen darf) beruhen, haftet
                    Ninebarc GmbH nur, wenn sie auf einer vorsätzlichen oder
                    grob fahrlässigen Pflichtverletzung von Ninebarc GmbH oder
                    auf einer vorsätzlichen oder grob fahrlässigen
                    Pflichtverletzung eines gesetzlichen Vertreters oder
                    Erfüllungsgehilfen von Ninebarc GmbH beruhen.
                  </p>
                  <br />
                  <p>
                    (5) Die Schadensersatzansprüche sind auf den vorhersehbaren,
                    vertragstypischen Schaden begrenzt. Sie betragen im Falle
                    des Verzuges höchstens 5% des Auftragswertes.
                  </p>
                  <br />
                  <p>
                    (6) Schadenersatzansprüche, die auf der Verletzung des
                    Lebens, des Körpers oder der Gesundheit oder der Freiheit
                    beruhen, verjähren nach 30 Jahren; im Übrigen nach 1 Jahr,
                    wobei die Verjährung mit dem Schluss des Jahres, in dem der
                    Anspruch entstanden ist und der Gläubiger von den Anspruch
                    begründenden Umständen und der Person des Schuldners
                    Kenntnis erlangt oder ohne grobe Fahrlässigkeit erlangen
                    müsste (§ 199 Abs.1 BGB).
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 8 Aufrechnung und Zurückbehaltungsrecht</strong>
                  </p>
                  <br />
                  <p>
                    (1) Dem Nutzer steht das Recht zur Aufrechnung nur zu, wenn
                    die Gegenforderung des Nutzers rechtskräftig festgestellt
                    worden ist oder von dem Anbieter nicht bestritten wurde.
                  </p>
                  <br />
                  <p>
                    (2) Der Nutzer kann ein Zurückbehaltungsrecht nur ausüben,
                    soweit seine Gegenforderung auf demselben Vertragsverhältnis
                    beruht.
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 9 Widerrufsbelehrung</strong>
                  </p>
                  <br />
                  <p>
                    (1) Ist der Nutzer ein Verbraucher, so hat er ein
                    Widerrufsrecht nach Maßgabe der folgenden Bestimmungen:
                  </p>
                  <br />
                  <p>(2) Widerrufsrecht</p>
                  <br />
                  <p>
                    Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von
                    Gründen diesen Vertrag zu widerrufen.
                  </p>
                  <br />
                  <p>
                    Die Widerrufsfrist für Dienstleistungen beträgt vierzehn
                    Tage ab dem Tag des Vertragsabschlusses.
                  </p>
                  <br />
                  <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns:</p>
                  <br />
                  <p>Ninebarc GmbH</p>
                  <p>Skalitzer Str. 85/86</p>
                  <p>10997 Berlin</p>
                  <p>E-Mail: info@ninebarc.com</p>
                  <br />
                  <p>
                    mittels einer eindeutigen Erklärung (z. B. ein mit der Post
                    versandter Brief, Telefax oder E-Mail) über Ihren
                    Entschluss, diesen Vertrag zu widerrufen, informieren. Sie
                    können dafür das Muster-Widerrufsformular auf unserer
                    Internetseite verwenden oder uns eine andere eindeutige
                    Erklärung übermitteln. Machen Sie von dieser Möglichkeit
                    Gebrauch, so werden wir Ihnen unverzüglich (z.B. per E-Mail)
                    eine Bestätigung über den Eingang eines solchen Widerrufs
                    übermitteln.
                  </p>
                  <br />
                  <p>
                    Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
                    Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf
                    der Widerrufsfrist absenden.
                  </p>
                  <br />
                  <p>(3) Folgen des Widerrufs</p>
                  <br />
                  <p>
                    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle
                    Zahlungen, die wir von Ihnen erhalten haben, einschließlich
                    der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die
                    sich daraus ergeben, dass Sie eine andere Art der Lieferung
                    als die von uns angebotene, günstigste Standardlieferung
                    gewählt haben), unverzüglich und spätestens binnen 14 Tagen
                    ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
                    Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
                    Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie
                    bei der ursprünglichen Transaktion eingesetzt haben, es sei
                    denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart;
                    in keinem Fall werden Ihnen wegen dieser Rückzahlung
                    Entgelte berechnet.
                  </p>
                  <br />
                  <p>
                    Haben Sie verlangt, dass die Dienstleistungen während der
                    Widerrufsfrist beginnen sollen, so haben Sie uns einen
                    angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem
                    Zeitpunkt, zu dem Sie uns von der Ausübung des
                    Widerrufsrechts hinsichtlich dieses Vertrags unterrichten,
                    bereits erbrachten Dienstleistungen im Vergleich zum
                    Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen
                    entspricht.
                  </p>
                  <br />
                  <p>(4) Ausnahmen vom Widerrufsrecht</p>
                  <br />
                  <p>
                    Sie müssen für einen etwaigen Wertverlust der Waren nur
                    aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der
                    Beschaffenheit, Eigenschaften und Funktionsweise der Waren
                    nicht notwendigen Umgang mit ihnen zurückzuführen ist.
                  </p>
                  <br />
                  <p>
                    Das Widerrufsrecht besteht nicht bzw. erlischt bei folgenden
                    Verträgen:
                  </p>
                  <br />
                  <p>
                    - zur Lieferung von Waren, die aus Gründen des
                    Gesundheitsschutzes oder aus Hygienegründen nicht zur
                    Rückgabe geeignet sind und deren Versiegelung nach der
                    Lieferung entfernt wurde oder die nach der Lieferung
                    aufgrund ihrer Beschaffenheit untrennbar mit anderen Gütern
                    vermischt wurden;
                  </p>
                  <p>
                    - zur Lieferung von Ton- oder Videoaufnahmen oder
                    Computersoftware in einer versiegelten Packung, wenn die
                    Versiegelung nach der Lieferung entfernt wurde;
                  </p>
                  <p>
                    - zur Lieferung von Waren, die nach Kundenspezifikation
                    angefertigt werden oder eindeutig auf die persönlichen
                    Bedürfnisse zugeschnitten sind
                  </p>
                  <p>
                    - zur Lieferung von Waren, die schnell verderben können oder
                    deren Verfallsdatum schnell überschritten würde;
                  </p>
                  <p>
                    - bei Dienstleistungen, wenn Ninebarc diese vollständig
                    erbracht hat und Sie vor der Bestellung zur Kenntnis
                    genommen und ausdrücklich zugestimmt haben, dass wir mit der
                    Erbringung der Dienstleistung beginnen können und Sie Ihr
                    Widerrufsrecht bei vollständiger Vertragserfüllung
                    verlieren;
                  </p>
                  <p>
                    - zur Lieferung von Zeitungen, Zeitschriften oder
                    Illustrierte, mit Ausnahme von Abonnement-Verträgen; und
                  </p>
                  <p>
                    - zur Lieferung alkoholischer Getränke, deren Preis beim
                    Abschluss des Kaufvertrags vereinbart wurde, deren Lieferung
                    aber erst nach 30 Tagen erfolgen kann und deren aktueller
                    Wert von Schwankungen auf dem Markt abhängt, auf die der
                    Unternehmer keinen Einfluss hat.
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 10 Datenschutz</strong>
                  </p>
                  <br />
                  <p>
                    (1) Sollten personenbezogene Daten (z.B. Name, Anschrift,
                    E-Mail-Adresse) erhoben werden, verpflichten wir uns dazu,
                    Ihre vorherige Einverständnis einzuholen. Wir verpflichten
                    uns dazu, keine Daten an Dritte weiterzugeben, es sei denn,
                    Sie haben zuvor eingewilligt.
                  </p>
                  <br />
                  <p>
                    (2) Wir weisen darauf hin, dass die Übertragung von Daten im
                    Internet (z. B. per E-Mail) Sicherheitslücken aufweisen
                    kann. Demnach kann ein fehlerfreier und störungsfreier
                    Schutz der Daten Dritter nicht vollständig gewährleistet
                    werden. Diesbezüglich ist unsere Haftung ausgeschlossen.
                  </p>
                  <br />
                  <p>
                    (3) Dritte sind nicht dazu berechtigt, Kontaktdaten für
                    gewerbliche Aktivitäten zu nutzen, sofern der Anbieter den
                    betroffenen Personen vorher eine schriftliche Einwilligung
                    erteilt hat.
                  </p>
                  <br />
                  <p>
                    (4) Sie haben jederzeit das Recht, von Ninebarc über den Sie
                    betreffenden Datenbestand vollständig und unentgeltlich
                    Auskunft zu erhalten.
                  </p>
                  <br />
                  <p>
                    (5) Des Weiteren besteht ein Recht auf Berichtigung/Löschung
                    von Daten/Einschränkung der Verarbeitung für den Nutzer.
                  </p>
                  <br />
                  <p>
                    (6) Weitere Angaben zum Datenschutz sind in der separaten
                    Datenschutzerklärung zu finden.
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 11 Cookies</strong>
                  </p>
                  <br />
                  <p>
                    (1) Zur Anzeige des Produktangebotes kann es vorkommen, dass
                    wir Cookies einsetzen. Bei Cookies handelt es sich um kleine
                    Textdateien, die lokal im Zwischenspeicher des
                    Internet-Browsers des Seitenbesuchers gespeichert werden.
                  </p>
                  <br />
                  <p>
                    (2) Zahlreiche Internetseiten und Server verwenden Cookies.
                    Viele Cookies enthalten eine sogenannte Cookie-ID. Eine
                    Cookie-ID ist eine eindeutige Kennung des Cookies. Sie
                    besteht aus einer Zeichenfolge, durch welche Internetseiten
                    und Server dem konkreten Internetbrowser zugeordnet werden
                    können, in dem das Cookie gespeichert wurde. Dies ermöglicht
                    es den besuchten Internetseiten und Servern, den
                    individuellen Browser der betroffenen Person von anderen
                    Internetbrowsern, die andere Cookies enthalten, zu
                    unterscheiden. Ein bestimmter Internetbrowser kann über die
                    eindeutige Cookie-ID wiedererkannt und identifiziert werden.
                  </p>
                  <br />
                  <p>
                    (3) Durch den Einsatz von Cookies kann den Nutzern dieser
                    Internetseite nutzerfreundlichere Services bereitstellen,
                    die ohne die Cookie-Setzung nicht möglich wären.
                  </p>
                  <br />
                  <p>
                    (4) Wir weisen Sie darauf hin, dass einige dieser Cookies
                    von unserem Server auf Ihr Computersystem überspielt werden,
                    wobei es sich dabei meist um so genannte sitzungsbezogene
                    Cookies handelt. Sitzungsbezogene Cookies zeichnen sich
                    dadurch aus, dass diese automatisch nach Ende der
                    Browser-Sitzung wieder von Ihrer Festplatte gelöscht werden.
                    Andere Cookies verbleiben auf Ihrem Computersystem und
                    ermöglichen es uns, Ihr Computersystem bei Ihrem nächsten
                    Besuch wieder zu erkennen (sog. dauerhafte Cookies).
                  </p>
                  <br />
                  <p>
                    (5) Sie können der Speicherung von Cookies widersprechen,
                    hierzu steht Ihnen ein Banner zu Verfügung dem Sie
                    widersprechen/annehmen können.
                  </p>
                  <br />
                  <p>
                    (6) Selbstverständlich können Sie Ihren Browser so
                    einstellen, dass keine Cookies auf der Festplatte abgelegt
                    werden bzw. bereits abgelegte Cookies wieder gelöscht
                    werden. Die Anweisungen bezüglich der Verhinderung sowie
                    Löschung von Cookies können Sie der Hilfefunktion Ihres
                    Browsers oder Softwareherstellers entnehmen.
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 12 Online-Streitbeilegung</strong>
                  </p>
                  <br />
                  <p>
                    Die Europäische Kommission stellt eine Plattform zur
                    Online-Streitbeilegung (OS) bereit. Die Plattform finden Sie
                    unter https://ec.europa.eu/consumers/odr/
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 13 Gerichtsstand und anwendbares Recht</strong>
                  </p>
                  <br />
                  <p>
                    (1) Auf diesen Vertrag ist das deutsche Recht anzuwenden,
                    unter Ausschluss des Internationalen Privatrechts (IPR) und
                    des UN-Kaufrechts (CISG), soweit dem keine zwingenden
                    Vorschriften entgegenstehen.
                  </p>
                  <br />
                  <p>
                    (2) Gerichtsstand und Erfüllungsort ist der Unternehmenssitz
                    des Anbieters.
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>§ 13 Gerichtsstand und anwendbares Recht</strong>
                  </p>
                  <br />
                  <p>(1) Vertragssprache ist deutsch.</p>
                  <br />
                  <p>
                    (2) Wir bieten keine Produkte oder Dienstleistungen zum Kauf
                    durch Minderjährige an. Unsere Produkte für Kinder können
                    nur von Erwachsenen gekauft werden. Falls Sie unter 18 sind,
                    dürfen Sie Ninebarc nur unter Mitwirkung eines Elternteils
                    oder Erziehungsberechtigten nutzen.
                  </p>
                  <br />
                  <p>
                    (3) Wenn Sie diese AGB verletzen und wir unternehmen
                    hiergegen nichts, sind wir weiterhin berechtigt, von unseren
                    Rechten bei jeder anderen Gelegenheit, in der Sie diese
                    Verkaufsbedingungen verletzen, Gebrauch zu machen.
                  </p>
                  <br />
                  <p>
                    (4) Wir behalten uns das Recht vor, Änderungen an unserer
                    Webseite, Regelwerken, Bedingungen einschließlich dieser AGB
                    jederzeit vorzunehmen. Auf Ihre Bestellung finden jeweils
                    die Verkaufsbedingungen, Vertragsbedingungen und AGB
                    Anwendung, die zu dem Zeitpunkt Ihrer Bestellung in Kraft
                    sind, es sei denn eine Änderung an diesen Bedingungen ist
                    gesetzlich oder auf behördliche Anordnung erforderlich (in
                    diesem Fall finden sie auch auf Bestellungen Anwendung, die
                    Sie zuvor getätigt haben). Falls eine Regelung in diesen
                    Verkaufsbedingungen unwirksam, nichtig oder aus irgendeinem
                    Grund undurchsetzbar ist, gilt diese Regelung als trennbar
                    und beeinflusst die Gültigkeit und Durchsetzbarkeit der
                    verbleibenden Regelungen nicht.
                  </p>
                  <br />
                  <p>
                    (5) Die Unwirksamkeit einer Bestimmung berührt die
                    Wirksamkeit der anderen Bestimmungen aus dem Vertrag nicht.
                    Sollte dieser Fall eintreten, soll die Bestimmung nach Sinn
                    und Zweck durch eine andere rechtlich zulässige Bestimmung
                    ersetzt werden, die dem Sinn und Zweck der unwirksamen
                    Bestimmung entspricht.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default TermsPage;
