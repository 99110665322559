import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResellerPageHeader,
  EndCustomerSection,
  CollaborationBenefitSection,
  ResellerPartnerSection,
  AdvantageSection,
  FeedbackSection,
  CalendarSection,
  FadeInUpAnimation,
} from 'components';

import { LANGUAGES, LOCAL_STORAGE_VARS } from 'constants/common';

import customer1 from 'images/customer-reseller-1.png';
import customer2 from 'images/customer-reseller-2.png';
import customer3 from 'images/customer-reseller-3.png';
import customer4 from 'images/customer-reseller-4.png';
import customer5 from 'images/customer-reseller-5.png';

function B2B2ResellerPage() {
  const isEnglishLang =
    localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE) === LANGUAGES.EN;

  const { t } = useTranslation();

  const testimonials = [
    {
      avatar: customer1,
      author: t('reseller:testimonial_b2b_1_author'),
      description: t('reseller:testimonial_b2b_1_description'),
    },
    {
      avatar: customer2,
      author: t('reseller:testimonial_b2b_2_author'),
      description: t('reseller:testimonial_b2b_2_description'),
    },
    {
      avatar: customer3,
      author: t('reseller:testimonial_b2b_3_author'),
      description: t('reseller:testimonial_b2b_3_description'),
    },
    {
      avatar: customer4,
      author: t('reseller:testimonial_b2b_4_author'),
      description: t('reseller:testimonial_b2b_4_description'),
    },
    {
      avatar: customer5,
      author: t('reseller:testimonial_b2b_5_author'),
      description: t('reseller:testimonial_b2b_5_description'),
    },
  ];

  const sections = [
    <ResellerPageHeader english={isEnglishLang} />,
    <EndCustomerSection />,
    <CollaborationBenefitSection />,
    <ResellerPartnerSection />,
    <AdvantageSection english={isEnglishLang} />,
    <FeedbackSection testimonials={testimonials} />,
    <CalendarSection />,
  ];

  return (
    <div className="t-my-0 t-mx-auto t-max-w-xl t-px-2.5">
      {sections.map((section, idx) => (
        <FadeInUpAnimation key={`reseller-page-section-${idx}`}>
          {section}
        </FadeInUpAnimation>
      ))}
    </div>
  );
}

export default B2B2ResellerPage;
