import React from 'react';
import { useTranslation } from 'react-i18next';
import { InlineWidget } from 'react-calendly';

const CalendarSection = () => {
  const { t } = useTranslation();
  const [screenWidth, setScreenWidth] = React.useState(window.screen.width);
  const [iframeHeight, setIframeHeight] = React.useState(660);

  const getWindowSize = () => {
    setScreenWidth(window.innerWidth);
  };

  window.onresize = getWindowSize;
  window.onload = getWindowSize;

  React.useEffect(() => {
    if (screenWidth < 1024) {
      setIframeHeight(1000);
    }
  }, [screenWidth]);

  return (
    <div className="t-mt-16 md:t-mt-40 -t-scroll-mb-20" id="FeedbackSection">
      <p className="Typography Typography--landingMainTitle u-weight-700 t-mb-10 t-text-center">
        {t('reseller:calendar_section_title')}
      </p>

      <div>
        <InlineWidget
          url="https://calendly.com/corey-ninebarc/30-minutes?hide_gdpr_banner=1"
          styles={{
            height: `${iframeHeight}px`,
          }}
        />
      </div>
    </div>
  );
};

export default CalendarSection;
