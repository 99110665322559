import React from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { motion } from 'framer-motion';

import { scrollToElementId } from 'helpers/common';

import {
  IconFlexibleDesign,
  IconIndividualFeatures,
  IconProfit,
  IconRetention,
} from 'components';

import { ReactComponent as IconChecked } from 'images/icon-circle-checked.svg';
import { ReactComponent as IconUnchecked } from 'images/icon-circle-unchecked.svg';

const BENEFIT_KEYS = {
  RETENTION: 'retention',
  PROFIT: 'profit',
  FLEXIBLE_DESIGN: 'flexibleDesign',
  INDIVIDUAL_FEATURES: 'individualFeatures',
};

const CollaborationBenefitSection = () => {
  const { t } = useTranslation();

  const [selectedContentKey, setSelectedContentKey] = React.useState(
    BENEFIT_KEYS.RETENTION
  );
  const firstContentRef = React.useRef<HTMLInputElement>(null);
  const lastContentRef = React.useRef<HTMLInputElement>(null);
  const [height, setHeight] = React.useState(0);
  const [screenWidth, setScreenWidth] = React.useState(window.screen.width);
  const [hasViewed, setHasViewed] = React.useState(false);

  const getWindowSize = () => {
    setScreenWidth(window.innerWidth);
  };

  window.onresize = getWindowSize;
  window.onload = getWindowSize;

  const benefits = {
    [BENEFIT_KEYS.RETENTION]: {
      title: t('reseller:collaboration_section_benefit_1_title'),
      description: t('reseller:collaboration_section_benefit_1_description'),
      icon: <IconRetention className="t-max-w-28 md:t-max-w-48" />,
    },
    [BENEFIT_KEYS.PROFIT]: {
      title: t('reseller:collaboration_section_benefit_2_title'),
      description: t('reseller:collaboration_section_benefit_2_description'),
      icon: <IconProfit className="t-max-w-28 md:t-max-w-48" />,
    },
    [BENEFIT_KEYS.FLEXIBLE_DESIGN]: {
      title: t('reseller:collaboration_section_benefit_3_title'),
      description: t('reseller:collaboration_section_benefit_3_description'),
      icon: <IconFlexibleDesign className="t-max-w-28 md:t-max-w-60" />,
    },
    [BENEFIT_KEYS.INDIVIDUAL_FEATURES]: {
      title: t('reseller:collaboration_section_benefit_4_title'),
      description: t('reseller:collaboration_section_benefit_4_description'),
      icon: <IconIndividualFeatures className="t-max-w-28 md:t-max-w-48" />,
    },
  };

  React.useLayoutEffect(() => {
    const delay = setInterval(() => {
      if (
        lastContentRef?.current?.getBoundingClientRect &&
        firstContentRef?.current?.getBoundingClientRect
      ) {
        const topPosition = firstContentRef.current.getBoundingClientRect().top;
        const bottomPosition =
          lastContentRef.current.getBoundingClientRect().top;

        const calculatedHeight = Math.floor(bottomPosition - topPosition) + 1;
        setHeight(calculatedHeight);
      }
    }, 200);

    return () => clearInterval(delay);
  }, [selectedContentKey, firstContentRef, lastContentRef, screenWidth]);

  React.useEffect(() => {
    if (screenWidth < 768 && hasViewed) {
      scrollToElementId(selectedContentKey);
    }
    if (hasViewed) {
    }
  }, [selectedContentKey]);

  const keyList = Object.keys(benefits);

  const handleChange = (key: string) => {
    setSelectedContentKey(key);
    setHasViewed(true);
  };

  return (
    <div className="t-mt-16 md:t-mt-40 t-rounded t-bg-beta-100">
      <div className="t-flex t-px-4 lg:t-pl-12 lg:t-pr-16 t-pt-11 t-pb-16">
        <div>
          <p className="Typography Typography--landingMainTitle u-weight-700">
            {t('reseller:collaboration_section_title')}
          </p>
          <div className="t-mt-16 t-relative">
            <div className="t-absolute t-top-6 -t-bottom-6 t-left-2.5 t-h-full t-pb-10">
              <div
                className="t-bg-alpha-150 t-z-0 t-w-1"
                style={{ height: `${height}px` }}
              />
            </div>

            {keyList.map((key) => {
              const content = benefits[key];
              const isSelected = key === selectedContentKey;
              return (
                <div className="t-flex t-mt-16" key={key}>
                  <div className="t-mr-5 t-mt-4 t-z-10">
                    <div
                      className="t-w-6 t-h-6 t-flex t-justify-center t-items-center t-cursor-pointer t-z-10"
                      onClick={() => {
                        handleChange(key);
                      }}
                      ref={
                        key === BENEFIT_KEYS.INDIVIDUAL_FEATURES
                          ? lastContentRef
                          : key === BENEFIT_KEYS.RETENTION
                          ? firstContentRef
                          : null
                      }
                    >
                      {isSelected ? (
                        <IconChecked className="t-w-6 t-h-6" />
                      ) : (
                        <IconUnchecked className="t-w-3.5 t-h-3.5" />
                      )}
                    </div>
                  </div>

                  <div className="t-flex t-flex-col t-w-full">
                    <div className="t-flex t-items-center t-w-full">
                      <p
                        className={`Typography Typography--cardTitle t-ml-5 t-whitespace-nowrap t-cursor-pointer hover:t-text-alpha-600 ${
                          isSelected ? 't-font-semibold t-text-alpha-600' : ''
                        }`}
                        onClick={() => {
                          handleChange(key);
                        }}
                        id={key}
                      >
                        {content.title}
                      </p>
                      {isSelected && (
                        <div className="t-h-1 t-mt-2 t-border-t-2 t-border-dashed t-border-alpha-600 t-ml-5 t-flex-grow t-hidden md:t-block" />
                      )}
                    </div>
                    {isSelected && (
                      <div className="md:t-hidden">
                        <div className="t-flex t-justify-center t-items-center t-w-full t-mt-8">
                          {benefits[selectedContentKey].icon}
                        </div>
                        <p
                          className="Typography Typography--landingQuote t-mt-5 t-pl-5"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              benefits[selectedContentKey].description
                            ),
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="t-w-128 t-bg-beta-100 t-flex-grow t-py-14 t-border-2 t-border-dashed t-border-alpha-600 t-rounded t-hidden md:t-block">
          <motion.div
            key={selectedContentKey}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1, animationFillMode: 'forwards' }}
            transition={{ duration: 1 }}
          >
            <div className="t-flex t-justify-center t-items-center t-h-72 t-w-full">
              {benefits[selectedContentKey].icon}
            </div>
            <p
              className="Typography Typography--landingQuote t-text-center t-mt-5 t-px-3"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  benefits[selectedContentKey].description
                ),
              }}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default CollaborationBenefitSection;
