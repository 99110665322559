import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  HomePageHeader,
  WhySection,
  WhatSection,
  HowSection,
  FeedbackSection,
  NewsSection,
  FadeInUpAnimation,
  LearnMoreSection,
} from 'components';

import { LANGUAGES, LOCAL_STORAGE_VARS } from 'constants/common';

import { ReactComponent as ImgIncident } from 'images/img-report-incident.svg';

function HomePage() {
  const { t } = useTranslation();
  const isEnglishLang =
    localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE) === LANGUAGES.EN;

  const sections = [
    <HomePageHeader english={isEnglishLang} />,
    <WhySection />,
    <LearnMoreSection />,
    <WhatSection />,
    <HowSection english={isEnglishLang} />,
    <FeedbackSection />,
    <NewsSection />,
  ];

  return (
    <div className="t-my-0 t-mx-auto t-max-w-xl t-px-5">
      {sections.map((section, idx) => (
        <FadeInUpAnimation key={`homepage-section-${idx}`}>
          {section}
        </FadeInUpAnimation>
      ))}
      <div className="t-absolute t-right-0 t-top-24 t-hidden lg:t-inline-block">
        <a
          href="https://app.ninebarc.de/incident-report"
          className="card t-p-3 t-flex t-border-beta-200 t-cursor-pointer t-rounded-r-none t-outline-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ImgIncident />
          <p className="typo-epsilon t-ml-3 t-text-delta-700">
            {t('home_page_report_incident')}
          </p>
        </a>
      </div>
    </div>
  );
}

export default HomePage;
