import React from 'react';
import { useTranslation } from 'react-i18next';

import { Carousel } from 'components';

import customer1 from 'images/customer1.png';
import customer2 from 'images/customer2.png';
import customer3 from 'images/customer3.png';
import customer4 from 'images/customer4.jpg';

type Testimonial = {
  avatar: string;
  author: string;
  description: string;
};

interface FeedbackSectionProps {
  testimonials?: Testimonial[];
}

const FeedbackSection = ({ testimonials }: FeedbackSectionProps) => {
  const { t } = useTranslation();

  const customerFeedbacks = [
    {
      avatar: customer1,
      author: t('slide1_author'),
      description: t('slide1_description'),
    },
    {
      avatar: customer2,
      author: t('slide2_author'),
      description: t('slide2_description'),
    },
    {
      avatar: customer3,
      author: t('slide3_author'),
      description: t('slide3_description'),
    },
    {
      avatar: customer4,
      author: t('slide4_author'),
      description: t('slide4_description'),
    },
  ];

  const carouselBreakPoints = [
    {
      width: 1,
      itemsToShow: 1,
      itemsToScroll: 1,
      initialActiveIndex: 0,
    },
    {
      width: 992,
      itemsToShow: 1.24,
      itemsToScroll: 1,
      initialActiveIndex: 0,
    },
  ];

  const [screenWidth, setScreenWidth] = React.useState(window.screen.width);

  const getWindowSize = () => {
    setScreenWidth(window.innerWidth);
  };

  window.onresize = getWindowSize;
  window.onload = getWindowSize;

  return (
    <div className="t-mt-16 md:t-mt-40">
      <p className="Typography Typography--landingMainTitle u-weight-700 t-mb-10">
        {t('feedback_section_title')}
      </p>
      <Carousel
        breakPoints={carouselBreakPoints}
        adjustedBtnTop={64}
        adjustedBtnSide={8}
        adjustedBtnSideLeft={0.5}
      >
        {(testimonials || customerFeedbacks).map(
          ({ avatar, author, description }, idx) => (
            <div
              className="card t-shadow-lg t-bg-gamma-400 t-flex t-flex-col md:t-flex-row t-py-3 md:t-pt-16 md:t-pb-10 md:t-pl-16 t-mb-4 md:t-mr-8 t-mx-3 t-w-full t-mt-3"
              key={author + `-${idx}`}
            >
              <div className="t-w-full t-flex t-justify-center md:t-w-auto md:t-block t-mt-3 md:t-mt-0">
                <img
                  src={avatar}
                  alt={author}
                  className="t-h-32 t-w-32 md:t-mr-10 t-rounded-full"
                />
              </div>
              <div className="t-w-full t-px-10 md:t-px-12 t-mt-4 md:t-mt-0">
                <div className="t-text-left t-font-primary t-text-xl">
                  <p
                    className={
                      screenWidth < 768
                        ? 'typo-delta'
                        : 'Typography--normalText'
                    }
                  >
                    {description}
                  </p>

                  <span className="Quote-author t-text-center md:t-text-left t-mb-1">
                    <span className="Typography Typography--landingMain u-weight-400 t-text-delta-700">
                      {author}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )
        )}
      </Carousel>
    </div>
  );
};

export default FeedbackSection;
