import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import imgHelvetia from 'images/clientLogos/logo-helvetia.png';
import imgIDEAL from 'images/clientLogos/logo-ideal.png';
import imgHDH from 'images/clientLogos/logo-hdh.png';
import imgNeodigital from 'images/clientLogos/logo-neodigital.png';
import imgSparkassen from 'images/clientLogos/logo-sparkassen-versicherung.png';
import imgSuedvers from 'images/clientLogos/logo-suedvers.png';
import imgZeitreicher from 'images/clientLogos/logo-zeitreicher.png';
import imgDeinepflege from 'images/clientLogos/logo-deinepflege.png';
import imgMehrwerk from 'images/clientLogos/logo-mehrwerk.png';
import imgDSG from 'images/clientLogos/logo-dsg.png';
import imgSchauenburg from 'images/clientLogos/logo-schauenburg.png';
import imgRuhebaum from 'images/clientLogos/logo-ruhebaum.png';

const partners = [
  {
    name: 'helvetia',
    logo: imgHelvetia,
    link: 'https://www.helvetia.com/',
  },
  {
    name: 'IDEAL',
    logo: imgIDEAL,
    link: 'https://www.ideal-versicherung.de/',
  },
  {
    name: 'HDH',
    logo: imgHDH,
    link: 'https://www.sterbegeld-hdh.de/',
  },
  {
    name: 'Neo Digital',
    logo: imgNeodigital,
    link: 'https://neodigital.de/',
  },
  {
    name: 'Sparkassen Versicherung',
    logo: imgSparkassen,
    link: 'https://www.sparkassenversicherung.de/',
  },
  {
    name: 'Suedvers',
    logo: imgSuedvers,
    link: 'https://www.suedvers.de/',
  },
  {
    name: 'Zeitreicher',
    logo: imgZeitreicher,
    link: 'https://zeitreicher.de/',
  },
  {
    name: 'Deine Pflege',
    logo: imgDeinepflege,
    link: 'https://www.deinepflege.de/',
  },
  {
    name: 'Mehrwerk',
    logo: imgMehrwerk,
    link: 'https://www.mehrwerk.de',
  },
  {
    name: 'DSG',
    logo: imgDSG,
    link: 'https://www.deutsche-schutzbrief.de',
  },
  {
    name: 'Schauenburg',
    logo: imgSchauenburg,
    link: 'https://schauenburg-international.com',
  },
  {
    name: 'Ruhebaum',
    logo: imgRuhebaum,
    link: 'https://meinruhebaum.de/',
  },
];

const ResellerPartnerSection = () => {
  const { t } = useTranslation();

  return (
    <div className="t-mt-16 md:t-mt-40">
      <p className="Typography Typography--landingMainTitle u-weight-700 t-mb-10">
        {t('reseller:partner_section_title')}
      </p>
      <div className="row t-w-full">
        <div className="t-w-full">
          <ul className="t-flex t-items-center t-justify-center lg:t-justify-around t-flex-wrap">
            {partners.map(({ name, logo, link }) => (
              <motion.li
                className="t-mx-10 t-w-28 md:t-w-56 t-mb-10 t-flex t-justify-center t-items-center"
                key={name}
                whileHover={{ scale: 1.2 }}
              >
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={name}
                >
                  <img src={logo} alt={name} />
                </a>
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ResellerPartnerSection;
