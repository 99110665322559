import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Cross } from 'images/cross-icon.svg';
import beneficiaryContentImg from 'images/beneficiary-content.png';

const BeneficiaryHowToPage = () => {
  const { t } = useTranslation();

  const [showAdvertisePanel, setShowAdvertisePanel] = React.useState(true);

  return (
    <main className="Page-content">
      <div className="Section Section--gin2">
        {showAdvertisePanel && (
          <div className="w-100 beneficiary-advertise-pannel u-pt-10 u-pb-10">
            <div className="Section-content d-flex justify-content-between align-items-center">
              <a
                className="beneficiary-panel-link"
                href="https://app.ninebarc.de/register"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="Typography Typography--landingMain u-weight-700 ">
                  {t('beneficiary_advertise_bold')}
                </span>
                <span className="Typography Typography--landingMain ">
                  {t('beneficiary_advertise_normal')}
                </span>
              </a>
              <button
                className="beneficiary-panel-button"
                onClick={() => setShowAdvertisePanel(false)}
              >
                <Cross className="u-color-white" />
              </button>
            </div>
          </div>
        )}
        <div
          className={`Section-content u-pt-md-30 ${
            showAdvertisePanel ? 'u-pt-60' : 'u-pt-100'
          }`}
        >
          <div className="row u-pt-md-10">
            <div className="col-lg-8">
              <h1 className="Typography Typography--landingMainTitle u-weight-700">
                {t('beneficiary_title')}
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 u-pr-10 u-pr-md-10">
              <h3 className="Typography Typography--landingSubTitle u-pt-40 u-pb-75">
                {t('beneficiary_description')}
              </h3>
              <h2 className="Typography Typography--landingQuote u-weight-700 u-color-amazon u-pb-16">
                {t('beneficiary_question')}
              </h2>
              <p className="Typography Typography--landingMain u-weight-400 u-pb-16">
                {t('beneficiary_answer')}
              </p>
              <p className="Typography Typography--landingMain u-weight-400 u-pb-6">
                {t('beneficiary_subAnswer')}
              </p>
              <p className="Typography Typography--landingMain u-weight-400 u-pb-6">
                {t('beneficiary_subAnswer1')}
                <a
                  href="https://app.ninebarc.de/zugang"
                  className="u-weight-600 u-noUnderline u-color-ship-gray"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('beneficiary_access_page')}
                </a>
              </p>
              <p className="Typography Typography--landingMain u-weight-400 u-pb-6">
                {t('beneficiary_subAnswer2')}
              </p>
              <p className="Typography Typography--landingMain u-weight-400 u-pb-24">
                {t('beneficiary_subAnswer3')}
              </p>
              <div className="d-flex">
                <a
                  className="Button Button--primary"
                  href="https://app.ninebarc.de/zugang"
                >
                  {t('beneficiary_button')}
                </a>
              </div>
            </div>

            <div className="col-md-6">
              <img
                src={beneficiaryContentImg}
                alt="beneficiary"
                className="beneficiary-left-section-image"
              />
              <div className="beneficiary-left-section-text">
                <p className="Typography Typography--main u-color-ship-gray u-pb-16">
                  {t('beneficiary_help_text')}
                </p>
                <p className="u-pb-16">
                  <a
                    href="mailto:info@ninebarc.com"
                    className="Typography Typography--subTitle u-weight-700 u-noUnderline u-color-ship-gray"
                  >
                    info@ninebarc.com
                  </a>
                </p>
                <p className="Typography Typography--subTitle u-weight-700 u-pb-16">
                  Tel.: 030 515652680
                </p>
              </div>
            </div>
          </div>
          <div className={`d-flex u-pb-20`}></div>
        </div>
      </div>
    </main>
  );
};

export default BeneficiaryHowToPage;
