import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LANGUAGES, LOCAL_STORAGE_VARS } from 'constants/common';

import en from 'localization/translations/en.json';
import de from 'localization/translations/de.json';
import faq_en from 'localization/translations/faq_en.json';
import faq_de from 'localization/translations/faq_de.json';
import pricing_en from 'localization/translations/pricing_en.json';
import pricing_de from 'localization/translations/pricing_de.json';
import b2c_how_page_en from 'localization/translations/b2c_how_page_en.json';
import b2c_how_page_de from 'localization/translations/b2c_how_page_de.json';
import reseller_en from 'localization/translations/reseller_en.json';
import reseller_de from 'localization/translations/reseller_de.json';
import release_notes_en from 'localization/translations/release_notes_en.json';
import release_notes_de from 'localization/translations/release_notes_de.json';

const lng =
  localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE) || LANGUAGES.DE;
const fallbackLng = lng === LANGUAGES.EN ? LANGUAGES.DE : LANGUAGES.EN;

i18n.use(initReactI18next).init({
  lng,
  fallbackLng,
  debug: false,
  keySeparator: ' ',
  resources: {
    en: {
      translation: en,
      faq: faq_en,
      pricing: pricing_en,
      b2cHowPage: b2c_how_page_en,
      reseller: reseller_en,
      releaseNotes: release_notes_en,
    },
    de: {
      translation: de,
      faq: faq_de,
      pricing: pricing_de,
      b2cHowPage: b2c_how_page_de,
      reseller: reseller_de,
      releaseNotes: release_notes_de,
    },
  },
});

export default i18n;
