import React from 'react';
import { motion } from 'framer-motion';

interface IconIndividualFeaturesProps {
  className?: string;
}

const IconIndividualFeatures = ({ className }: IconIndividualFeaturesProps) => {
  return (
    <svg
      width="216"
      height="239"
      viewBox="0 0 216 239"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M108 214.289C167.174 214.289 215.144 166.319 215.144 107.144C215.144 47.9702 167.174 0 108 0C48.8257 0 0.855469 47.9702 0.855469 107.144C0.855469 166.319 48.8257 214.289 108 214.289Z"
        fill="#DEE9F3"
      />
      <mask
        id="mask0_15421_24940"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="216"
        height="215"
      >
        <path
          d="M108 214.289C167.174 214.289 215.144 166.319 215.144 107.144C215.144 47.9702 167.174 0 108 0C48.8257 0 0.855469 47.9702 0.855469 107.144C0.855469 166.319 48.8257 214.289 108 214.289Z"
          fill="#D6E8F6"
        />
      </mask>
      <motion.g
        mask="url(#mask0_15421_24940)"
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{
          duration: 3,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatDelay: 1.5,
        }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.842 4.48828C148.737 4.48828 147.842 5.38371 147.842 6.48828V18.413L135.914 18.413C134.809 18.413 133.914 19.3085 133.914 20.413V34.9798C133.914 36.0843 134.809 36.9798 135.914 36.9798H147.842V48.9051C147.842 50.0097 148.737 50.9051 149.842 50.9051H164.409C165.513 50.9051 166.409 50.0097 166.409 48.9051V36.9798H178.331C179.435 36.9798 180.331 36.0843 180.331 34.9798V20.413C180.331 19.3085 179.435 18.413 178.331 18.413L166.409 18.413V6.48828C166.409 5.38371 165.513 4.48828 164.409 4.48828H149.842Z"
          fill="#2B7B54"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M196.875 46.2598C195.771 46.2598 194.875 47.1552 194.875 48.2598V54.615H188.52C187.415 54.615 186.52 55.5105 186.52 56.615V63.7551C186.52 64.8596 187.415 65.7551 188.52 65.7551H194.875V72.1099C194.875 73.2144 195.771 74.1099 196.875 74.1099H204.015C205.12 74.1099 206.015 73.2144 206.015 72.1099V65.7551H212.37C213.474 65.7551 214.37 64.8596 214.37 63.7551V56.615C214.37 55.5105 213.474 54.615 212.37 54.615H206.015V48.2598C206.015 47.1552 205.12 46.2598 204.015 46.2598H196.875Z"
          fill="#40946A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.6254 30.7891C56.5208 30.7891 55.6254 31.6845 55.6254 32.7891V39.1443H49.2695C48.165 39.1443 47.2695 40.0397 47.2695 41.1443V48.2844C47.2695 49.3889 48.165 50.2844 49.2695 50.2844H55.6254V56.6392C55.6254 57.7437 56.5208 58.6392 57.6254 58.6392H64.7654C65.87 58.6392 66.7654 57.7437 66.7654 56.6392V50.2844H73.1196C74.2242 50.2844 75.1196 49.3889 75.1196 48.2844V41.1443C75.1196 40.0397 74.2242 39.1443 73.1196 39.1443H66.7654V32.7891C66.7654 31.6845 65.87 30.7891 64.7654 30.7891H57.6254Z"
          fill="#40946A"
        />
      </motion.g>
      <path
        d="M184.792 92.3897V96.7137C184.831 97.5062 184.555 98.2821 184.025 98.8723C183.495 99.4626 182.753 99.8195 181.961 99.8653H80.5968C80.2021 99.8462 79.815 99.7495 79.4576 99.5808C79.1002 99.4121 78.7796 99.1746 78.514 98.882C78.2484 98.5893 78.0431 98.2472 77.9098 97.8752C77.7765 97.5031 77.7177 97.1085 77.737 96.7137V92.3897C77.737 86.0466 74.8257 81.0133 69.1118 78.6625C68.9596 78.5975 68.8335 78.4837 68.7533 78.339C68.6731 78.1943 68.6435 78.027 68.6691 77.8636C68.6947 77.7002 68.7741 77.5499 68.8946 77.4367C69.0152 77.3234 69.1701 77.2536 69.3348 77.2383H168.582C168.689 77.244 168.796 77.244 168.903 77.2383H169.795C171.47 77.2354 173.133 77.5178 174.714 78.0734L174.937 78.1535C177.836 79.2376 180.334 81.1826 182.095 83.7273C183.857 86.272 184.798 89.2947 184.792 92.3897Z"
        fill="#1E5D8F"
      />
      <path
        d="M174.825 78.1242C172.96 78.0427 171.1 78.3706 169.376 79.0851C167.651 79.7995 166.104 80.8831 164.844 82.2595C162.713 84.748 161.577 87.9356 161.652 91.2107V223.992C161.652 229.551 158.1 234.356 152.941 236.621C150.842 237.531 148.578 238.001 146.289 237.999H43.8445C43.6924 238.004 43.5414 237.973 43.4027 237.911C43.264 237.849 43.1413 237.756 43.0437 237.639C42.9324 237.513 42.8526 237.362 42.8107 237.199C42.7689 237.036 42.7663 236.866 42.8032 236.702C42.84 236.538 42.9152 236.385 43.0227 236.255C43.1301 236.126 43.2667 236.024 43.4212 235.957C48.9006 233.549 51.9607 229.666 51.9607 223.992V91.2107C51.9607 83.4778 58.8242 77.209 67.3294 77.209H167.137C167.584 77.209 168.03 77.209 168.47 77.209C168.577 77.2147 168.684 77.2147 168.79 77.209H169.683C171.437 77.2055 173.179 77.5155 174.825 78.1242Z"
        fill="#4B81AC"
      />
      <path
        d="M86.707 101.763C86.707 101.403 87.0209 101.103 87.4216 101.092C87.5455 101.088 99.4699 100.699 106.458 95.3697C106.748 95.1449 107.173 95.1411 107.462 95.366L107.466 95.3697C114.455 100.702 126.35 101.092 126.47 101.096C126.867 101.107 127.185 101.403 127.185 101.767V101.819C127.185 111.083 125.516 124.034 117.288 133.107C112.307 138.6 107.371 140.047 107.161 140.107L106.938 140.174L106.706 140.107C106.496 140.047 101.589 138.6 96.6075 133.107C88.3757 124.03 86.707 111.083 86.707 101.815V101.763Z"
        fill="#DEE9F3"
      />
      <path
        d="M153.068 236.625C150.969 237.535 148.705 238.004 146.416 238.003H46.3797C45.2944 238.003 44.2124 237.882 43.1538 237.643C39.9589 236.915 37.0869 235.168 34.9702 232.667C32.8535 230.165 31.6065 227.044 31.4171 223.773C31.3942 223.456 31.3827 223.136 31.3827 222.812V218.488C31.3628 218.093 31.421 217.698 31.5541 217.326C31.6872 216.954 31.8925 216.612 32.1583 216.32C32.4241 216.027 32.7451 215.791 33.1028 215.623C33.4605 215.455 33.8478 215.359 34.2426 215.342H135.595C135.99 215.359 136.377 215.455 136.735 215.623C137.092 215.791 137.413 216.027 137.679 216.32C137.945 216.612 138.15 216.954 138.283 217.326C138.416 217.698 138.475 218.093 138.455 218.488V222.812C138.448 224.299 138.631 225.78 138.998 227.222C139.597 229.743 140.907 232.04 142.773 233.839C144.002 235.029 145.454 235.964 147.046 236.59C147.101 236.615 147.159 236.636 147.217 236.653C149.135 237.166 151.156 237.156 153.068 236.625Z"
        fill="#1E5D8F"
      />
      <path
        d="M148.852 166.462H62.6966C62.0747 166.462 61.4782 166.215 61.0384 165.775C60.5986 165.335 60.3516 164.738 60.3516 164.117C60.3516 163.495 60.5986 162.898 61.0384 162.458C61.4782 162.019 62.0747 161.771 62.6966 161.771H148.852C149.474 161.771 150.07 162.019 150.51 162.458C150.95 162.898 151.197 163.495 151.197 164.117C151.197 164.738 150.95 165.335 150.51 165.775C150.07 166.215 149.474 166.462 148.852 166.462Z"
        fill="#DEE9F3"
      />
      <path
        d="M62.6983 170.945H82.597C83.2189 170.945 83.8154 171.192 84.2552 171.632C84.695 172.072 84.9421 172.668 84.9421 173.29C84.9421 173.912 84.695 174.509 84.2552 174.948C83.8154 175.388 83.2189 175.635 82.597 175.635H62.807C62.4817 175.64 62.1588 175.579 61.8573 175.457C61.5559 175.335 61.2819 175.154 61.0516 174.924C60.8213 174.694 60.6393 174.421 60.5165 174.119C60.3936 173.818 60.3323 173.495 60.3361 173.17C60.3655 172.568 60.6255 172.001 61.0621 171.585C61.4988 171.17 62.0785 170.939 62.6812 170.939L62.6983 170.945Z"
        fill="#DEE9F3"
      />
      <path
        d="M151.197 173.399C151.163 174 150.902 174.565 150.466 174.979C150.031 175.394 149.453 175.626 148.852 175.63H91.9583C91.3364 175.63 90.7399 175.383 90.3001 174.943C89.8604 174.503 89.6133 173.907 89.6133 173.285C89.6133 172.663 89.8604 172.066 90.3001 171.627C90.7399 171.187 91.3364 170.94 91.9583 170.94H148.726C149.051 170.935 149.373 170.995 149.674 171.117C149.975 171.239 150.249 171.42 150.479 171.649C150.709 171.878 150.892 172.151 151.015 172.452C151.138 172.752 151.2 173.074 151.197 173.399Z"
        fill="#DEE9F3"
      />
      <path
        d="M148.852 184.799H62.6966C62.0747 184.799 61.4782 184.552 61.0384 184.113C60.5986 183.673 60.3516 183.076 60.3516 182.454C60.3516 181.832 60.5986 181.236 61.0384 180.796C61.4782 180.356 62.0747 180.109 62.6966 180.109H148.852C149.474 180.109 150.07 180.356 150.51 180.796C150.95 181.236 151.197 181.832 151.197 182.454C151.197 183.076 150.95 183.673 150.51 184.113C150.07 184.552 149.474 184.799 148.852 184.799Z"
        fill="#DEE9F3"
      />
      <path
        d="M62.6966 189.287H122.467C123.089 189.287 123.685 189.534 124.125 189.974C124.565 190.414 124.812 191.01 124.812 191.632C124.812 192.254 124.565 192.851 124.125 193.29C123.685 193.73 123.089 193.977 122.467 193.977H62.6966C62.0747 193.977 61.4782 193.73 61.0384 193.29C60.5986 192.851 60.3516 192.254 60.3516 191.632C60.3516 191.01 60.5986 190.414 61.0384 189.974C61.4782 189.534 62.0747 189.287 62.6966 189.287Z"
        fill="#DEE9F3"
      />
      <path
        d="M107.546 133.539C116.103 133.539 123.041 126.601 123.041 118.044C123.041 109.486 116.103 102.549 107.546 102.549C98.9881 102.549 92.0508 109.486 92.0508 118.044C92.0508 126.601 98.9881 133.539 107.546 133.539Z"
        fill="#DEE9F3"
      />
      <motion.path
        d="M114.769 112.722C115.636 113.431 115.693 114.737 114.89 115.517L106.32 123.85L101.118 119.328C100.348 118.659 100.347 117.463 101.116 116.792C101.749 116.241 102.69 116.241 103.323 116.791L105.808 118.951L112.294 112.812C112.979 112.163 114.039 112.125 114.769 112.722Z"
        fill="#4B81AC"
        animate={{ scale: [0, 1.5, 0], opacity: [0, 1, 0] }}
        transition={{
          duration: 2,
          ease: 'linear',
          repeat: Infinity,
        }}
      />
    </svg>
  );
};

export default IconIndividualFeatures;
