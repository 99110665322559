import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import homePageDE from 'images/img-application-screenshot-de.png';
import homePageEN from 'images/img-application-screenshot-en.png';
import { ImageEnlargement } from 'components';

interface HomePageHeaderProps {
  english: boolean;
}

const HomePageHeader = ({ english }: HomePageHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="t-flex t-items-center t-mt-5 md:t-mt-10">
      <motion.div
        className="md:t-w-144"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        animate={{ y: 100 }}
        viewport={{ once: true }}
      >
        <p className="Typography Typography--landingMainTitle u-weight-700">
          {t('home_page_header_title')}
        </p>
        <p className="Typography Typography--landingSubTitle t-mt-6 md:t-mr-36">
          {t('home_page_header_subtitle')}
        </p>

        <ImageEnlargement
          src={english ? homePageEN : homePageDE}
          alt="homepage"
          className="t-z-10 md:t-hidden t-mt-10"
        />

        <div className="t-flex t-justify-center md:t-justify-start t-mt-10">
          <a
            href="https://app.ninebarc.de/register"
            className="Button Button--primary Button--large"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('provision_button')}
          </a>
        </div>
      </motion.div>

      <div className="t-hidden t-justify-center t-relative t-ml-5 md:t-flex t-w-full">
        <ImageEnlargement
          src={english ? homePageEN : homePageDE}
          alt="homepage"
          className="t-z-10"
        />
        <div className="t-absolute t-top-1/2 t-bottom-0 -t-translate-y-1/2 t-w-full t-h-72">
          <motion.div
            className="t-w-full t-h-full t-bg-delta-100 t-rounded"
            transition={{
              duration: 2,
            }}
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePageHeader;
