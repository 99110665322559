import React from 'react';
import { useTranslation } from 'react-i18next';

import { scrollToElementId } from 'helpers/common';

import release from 'images/release.png';

interface ReleaseNote {
  date: string;
  note: string;
}

const numberOfNotesPerPage = 10;

export default function ReleaseNotes() {
  const { t } = useTranslation(['releaseNotes']);

  const [headerHeight, setHeaderHeight] = React.useState(0);
  const headRef = React.useRef<HTMLDivElement>(null);

  const getHeight = () => {
    if (headRef?.current?.clientHeight) {
      setHeaderHeight(headRef.current?.clientHeight);
    }
  };

  React.useLayoutEffect(() => {
    getHeight();
  }, []);

  window.onresize = getHeight;
  window.onload = getHeight;

  const releaseNotes = (
    t('releaseNotes:notes', {
      returnObjects: true,
    }) as ReleaseNote[]
  ).reverse();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [displayedNotes, setDisplayedNotes] = React.useState(
    releaseNotes.slice(0, numberOfNotesPerPage)
  );

  const goToNextPage = () => {
    setDisplayedNotes(
      releaseNotes.slice(
        currentPage * numberOfNotesPerPage,
        currentPage * numberOfNotesPerPage + numberOfNotesPerPage
      )
    );
    setCurrentPage(currentPage + 1);
    scrollToElementId('release-notes');
  };

  const goToPrevPage = () => {
    setDisplayedNotes(
      releaseNotes.slice(
        (currentPage - 2) * numberOfNotesPerPage,
        (currentPage - 1) * numberOfNotesPerPage
      )
    );
    setCurrentPage(currentPage - 1);
    scrollToElementId('release-notes');
  };

  const nextButtonDisplayed =
    currentPage * numberOfNotesPerPage < releaseNotes.length;

  const prevButtonDisplayed = currentPage > 1;

  return (
    <main className="Page-content">
      <div className="Section Section--white" ref={headRef}>
        <div
          className="t-absolute t-left-0 t-bg-alpha-100 t-w-full t-h-full"
          style={{ height: `${headerHeight}px` }}
        />
        <div className="Section-content t-relative">
          <div className="FirstBlock">
            <div className="row t-h-full">
              <div className="col-lg-6 u-pt-10 u-pb-10 t-flex t-flex-col t-justify-center t-h-full">
                <h1 className="Typography Typography--landingMainTitle u-weight-700">
                  {t('releaseNotes:release_notes_page_title')}
                </h1>
                <p className="Typography Typography--normalText t-text-beta-700 t-mt-2 lg:t-pr-40">
                  {t('releaseNotes:release_notes_page_desc')}
                </p>
              </div>

              <div className="col-lg-6 t-hidden lg:t-flex t-justify-end t-h-full">
                <div className="FirstBlock-image -t-bottom-3 t-flex t-items-start">
                  <img src={release} className="t-h-full" alt="Release notes" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Section Section--white t-pt-8" id="release-notes">
        <div className="Section-content">
          {!!displayedNotes.length &&
            displayedNotes.map(({ date, note }) => (
              <div className="t-mt-8">
                <p className="Typography--title t-text-delta-700 t-font-semibold">
                  {date}
                </p>
                <p className="Typography--subTitle t-mt-3">{note}</p>
              </div>
            ))}

          <div className="t-flex t-justify-end t-py-10">
            {!!prevButtonDisplayed && (
              <button
                className={`Button Button--primary typo-eta t-text-gamma-400 ${
                  nextButtonDisplayed ? 't-mr-6' : ''
                }`}
                onClick={goToPrevPage}
              >
                {t('releaseNotes:previous_button')}
              </button>
            )}
            {!!nextButtonDisplayed && (
              <button
                className="Button Button--primary typo-eta t-text-gamma-400"
                onClick={goToNextPage}
              >
                {t('releaseNotes:next_button')}
              </button>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
