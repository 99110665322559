import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

import { FadeInUpAnimation } from 'components';

import imgKeyHolder from 'images/img_key_holder_page_header.png';

import imgStep1 from 'images/img_key_holder_step_1.png';
import imgStep2 from 'images/img_key_holder_step_2.png';
import imgStep3 from 'images/img_key_holder_step_3.png';
import imgStep4 from 'images/img_key_holder_step_4.png';
import imgStep5 from 'images/img_key_holder_step_5.png';

const numberOfDescTexts = 3;
const stepImages = [imgStep1, imgStep2, imgStep3, imgStep4, imgStep5];

export default function KeyHolderPage() {
  const { t } = useTranslation();

  const [headerHeight, setHeaderHeight] = React.useState(0);

  const headRef = React.useRef<HTMLDivElement>(null);

  const getHeight = () => {
    if (headRef?.current?.clientHeight) {
      setHeaderHeight(headRef.current?.clientHeight);
    }
  };

  window.onresize = getHeight;
  window.onload = getHeight;

  React.useLayoutEffect(() => {
    getHeight();
  }, []);

  return (
    <FadeInUpAnimation>
      <main className="Page-content">
        <FadeInUpAnimation>
          <div className="Section Section--white" ref={headRef}>
            <div
              className="t-absolute t-left-0 t-bg-alpha-100 t-w-full t-h-full"
              style={{ height: `${headerHeight}px` }}
            />
            <div className="Section-content t-relative">
              <div className="FirstBlock">
                <div className="row u-h-100">
                  <div className="col-lg-6 u-pt-10 u-pb-10 d-flex flex-column justify-content-center u-h-100">
                    <h1 className="Typography Typography--landingMainTitle u-weight-700 u-pb-30">
                      {t('key_holder_page_title')}
                    </h1>

                    <p className="Typography Typography--landingSubTitle">
                      {t('key_holder_page_description')}
                    </p>
                  </div>

                  <div className="col-lg-6 t-justify-end t-hidden lg:t-flex">
                    <div className="FirstBlock-image t-bottom-0 t-flex t-items-start">
                      <img
                        src={imgKeyHolder}
                        className="t-h-full"
                        alt="Key Holder"
                        role="presentation"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInUpAnimation>

        <FadeInUpAnimation className="Section-content">
          <div className="t-mt-20">
            {[...Array(numberOfDescTexts)].map((_, idx) => {
              const text = t(`key_holder_page_text_${idx + 1}`);
              return (
                <p
                  key={text}
                  className="Typography Typography--landingSubTitle t-mt-8"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(text),
                  }}
                />
              );
            })}
          </div>
        </FadeInUpAnimation>

        <FadeInUpAnimation className="Section-content t-mt-20">
          <p className="Typography Typography--cardTitle t-text-delta-700 t-font-semibold">
            {t('how_does_it_work')}
          </p>
          <div className="t-relative">
            {stepImages.map((img, idx) => (
              <div
                className="t-flex t-flex-col t-items-center sm:t-flex-row sm:t-justify-start sm:t-items-center t-mt-20"
                key={`step_${idx}`}
              >
                <img
                  src={img}
                  alt={`Contact info ${idx}`}
                  className="t-w-32 t-py-1.5 t-h-32 t-z-50 t-bg-gamma-400"
                />
                <p className="Typography Typography--landingSubTitle t-text-center sm:t-text-left t-mt-5 sm:t-mt-0 sm:t-ml-14">
                  {t(`key_holder_page_step_${idx + 1}`)}
                </p>
              </div>
            ))}
            <div className="t-absolute t-h-full t-py-1.5 t-top-0 t-left-16 t-z-0 t-hidden sm:t-inline">
              <div className="t-h-full t-w-0 t-border-l-2 t-border-solid t-border-beta-200" />
            </div>
          </div>
        </FadeInUpAnimation>

        <FadeInUpAnimation className="Section-content t-mt-28 t-mb-16">
          <div className="t-rounded t-bg-delta-900 t-w-full t-p-10 t-flex t-flex-col md:t-flex-row t-justify-between t-items-center">
            <p className="Typography Typography--landingSubTitle t-text-gamma-400">
              {t('key_holder_page_contact_us_text')}
            </p>
            <Link
              to="/contact"
              className="Button Button--primary t-whitespace-nowrap t-mt-5 md:t-mt-0"
            >
              {t('safe_contact_button')}
            </Link>
          </div>
        </FadeInUpAnimation>
      </main>
    </FadeInUpAnimation>
  );
}
