import React from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { motion } from 'framer-motion';

import { scrollToElementId } from 'helpers/common';
import { FadeInUpAnimation, ImageEnlargement } from 'components';

import imgHelvetia from 'images/clientLogos/logo-helvetia-gray.png';
import imgIDEAL from 'images/clientLogos/logo-ideal-gray.png';
import imgHDH from 'images/clientLogos/logo-hdh-gray.png';
import imgSparkassen from 'images/clientLogos/logo-sparkassen-versicherung-gray.png';
import imgSuedvers from 'images/clientLogos/logo-suedvers-gray.png';
import imgZeitreicher from 'images/clientLogos/logo-zeitreicher-gray.png';
import imgDeinepflege from 'images/clientLogos/logo-deinepflege-gray.png';
import imgDSG from 'images/clientLogos/logo-dsg-gray.png';
import imgRuhebaum from 'images/clientLogos/logo-ruhebaum-gray.png';

import imgHelvetiaColor from 'images/clientLogos/logo-helvetia.png';
import imgIDEALColor from 'images/clientLogos/logo-ideal.png';
import imgHDHColor from 'images/clientLogos/logo-hdh.png';
import imgSparkassenColor from 'images/clientLogos/logo-sparkassen-versicherung.png';
import imgSuedversColor from 'images/clientLogos/logo-suedvers.png';
import imgZeitreicherColor from 'images/clientLogos/logo-zeitreicher.png';
import imgDeinepflegeColor from 'images/clientLogos/logo-deinepflege.png';
import imgDSGColor from 'images/clientLogos/logo-dsg.png';
import imgRuhebaumColor from 'images/clientLogos/logo-ruhebaum.png';

import screenshotDSGEn from 'images/screenshot_dsg_en.png';
import screenshotDSGDe from 'images/screenshot_dsg_de.png';
import screenshotZeitreicherEn from 'images/screenshot_zeitreicher_en.png';
import screenshotZeitreicherDe from 'images/screenshot_zeitreicher_de.png';
import screenshotClientEn from 'images/screenshot_client_en.png';
import screenshotClientDe from 'images/screenshot_client_de.png';

import imgBgBanking from 'images/img-bg-banking.png';
import imgBgNurse from 'images/img-bg-nurse.png';
import imgBgConsultant from 'images/img-bg-consultant.png';

import { ReactComponent as IconBank } from 'images/iconBank.svg';
import { ReactComponent as IconNurse } from 'images/iconNurse.svg';
import { ReactComponent as IconTrust } from 'images/iconTrust.svg';

interface AdvantageSectionProps {
  english: boolean;
}

const AdvantageSection = ({ english }: AdvantageSectionProps) => {
  const { t } = useTranslation();
  const [overIcon, setOverIcon] = React.useState('');

  const handleOnClick = (to: string) => {
    scrollToElementId(to);
  };

  const advantages = [
    {
      text: t('reseller:advantage_section_item_1_text'),
      buttonText: t('reseller:advantage_section_item_1_button'),
      toSectionId: 'insurerAndBanks',
      title: t('reseller:advantage_section_insurance_and_bank_title'),
      description: t(
        'reseller:advantage_section_insurance_and_bank_description'
      ),
      texts: [
        t('reseller:advantage_section_insurance_and_bank_text_1'),
        t('reseller:advantage_section_insurance_and_bank_text_2'),
        t('reseller:advantage_section_insurance_and_bank_text_3'),
      ],
      cardBg: imgBgBanking,
      icon: <IconBank className="t-w-8" />,
      partners: [
        {
          name: 'DSG',
          logo: imgDSG,
          logoColor: imgDSGColor,
          link: 'https://www.deutsche-schutzbrief.de',
        },
        {
          name: 'Sparkassen Versicherung',
          logo: imgSparkassen,
          logoColor: imgSparkassenColor,
          link: 'https://www.sparkassenversicherung.de/',
        },

        {
          name: 'IDEAL',
          logo: imgIDEAL,
          logoColor: imgIDEALColor,
          link: 'https://www.ideal-versicherung.de/',
        },
        {
          name: 'HDH',
          logo: imgHDH,
          logoColor: imgHDHColor,
          link: 'https://www.sterbegeld-hdh.de/',
        },
      ],
      screenShoot: english ? screenshotDSGEn : screenshotDSGDe,
    },
    {
      text: t('reseller:advantage_section_item_2_text'),
      buttonText: t('reseller:advantage_section_item_2_button'),
      toSectionId: 'FuneralCareService',
      title: t('reseller:advantage_section_funeral_home_care_services_title'),
      description: t(
        'reseller:advantage_section_funeral_home_care_services_description'
      ),
      texts: [
        t('reseller:advantage_section_funeral_home_care_services_text_1'),
        t('reseller:advantage_section_funeral_home_care_services_text_2'),
        t('reseller:advantage_section_funeral_home_care_services_text_3'),
      ],
      cardBg: imgBgNurse,
      icon: <IconNurse className="t-w-6" />,
      partners: [
        {
          name: 'Zeitreicher',
          logo: imgZeitreicher,
          logoColor: imgZeitreicherColor,
          link: 'https://zeitreicher.de/',
        },
        {
          name: 'Ruhebaum',
          logo: imgRuhebaum,
          logoColor: imgRuhebaumColor,
          link: 'https://meinruhebaum.de/',
        },
        {
          name: 'Suedvers',
          logo: imgSuedvers,
          logoColor: imgSuedversColor,
          link: 'https://www.suedvers.de/',
        },
        {
          name: 'Deine Pflege',
          logo: imgDeinepflege,
          logoColor: imgDeinepflegeColor,
          link: 'https://www.deinepflege.de/',
        },
      ],
      screenShoot: english ? screenshotZeitreicherEn : screenshotZeitreicherDe,
    },
    {
      text: t('reseller:advantage_section_item_3_text'),
      buttonText: t('reseller:advantage_section_item_3_button'),
      toSectionId: 'AgentsAndConsultants',
      title: t('reseller:advantage_section_agents_consultants_title'),
      description: t(
        'reseller:advantage_section_agents_consultants_description'
      ),
      texts: [
        t('reseller:advantage_section_agents_consultants_text_1'),
        t('reseller:advantage_section_agents_consultants_text_2'),
        t('reseller:advantage_section_agents_consultants_text_3'),
      ],
      cardBg: imgBgConsultant,
      icon: <IconTrust className="t-w-10" />,
      partners: [
        {
          name: 'helvetia',
          logo: imgHelvetia,
          logoColor: imgHelvetiaColor,
          link: 'https://www.helvetia.com/',
        },
      ],
      screenShoot: english ? screenshotClientEn : screenshotClientDe,
    },
  ];

  return (
    <div className="t-mt-16 md:t-mt-40">
      <p className="Typography Typography--landingMainTitle u-weight-700 t-mb-10">
        {t('reseller:advantage_section_title')}
      </p>

      <div className="t-flex t-flex-col t-items-center md:t-flex-row md:t-justify-between t-flex-wrap">
        {advantages.map(({ text, buttonText, toSectionId, cardBg, icon }) => (
          <motion.div
            className="t-w-72 sm:t-w-96 t-bg-delta-900 t-rounded t-pt-14 t-pb-10 t-mr-2 t-mt-10 md:t-mt-0 t-relative"
            key={text}
            whileHover={{ scale: 1.2 }}
            style={{
              backgroundImage: `url(${cardBg})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="t-absolute -t-top-7 t-w-full t-flex t-justify-center">
              <div className="t-rounded-full t-w-14 t-h-14 t-bg-delta-100 t-flex t-justify-center t-items-center">
                {icon}
              </div>
            </div>
            <p className="Typography Typography--cardTitle t-font-semibold t-text-gamma-400 t-text-center t-px-4">
              {text}
            </p>
            <div className="t-flex t-justify-center t-mt-8">
              <button
                className="Button Button--primary Button--large t-uppercase"
                onClick={() => handleOnClick(toSectionId)}
              >
                {buttonText}
              </button>
            </div>
          </motion.div>
        ))}
      </div>

      {advantages.map(
        (
          { title, description, texts, partners, screenShoot, toSectionId },
          idx
        ) => (
          <div
            id={toSectionId}
            className={`t-px-10 t-py-28 t-relative ${
              idx % 2 ? 't-bg-beta-100 t-rounded' : ''
            }`}
            key={title}
          >
            <FadeInUpAnimation>
              <p className="Typography Typography--landingMainTitle u-weight-700 t-mb-10">
                {title}
              </p>

              <ul className="t-flex t-items-center t-justify-center lg:t-justify-around t-flex-wrap">
                {partners.map(({ name, logo, logoColor, link }) => (
                  <motion.li
                    className="t-mx-10 t-w-28 md:t-w-56 t-mb-10 t-flex t-justify-center t-items-center"
                    key={name}
                    whileHover={{ scale: 1.2 }}
                    onMouseOver={() => setOverIcon(name)}
                    onMouseOut={() => setOverIcon('')}
                  >
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={name}
                    >
                      <img
                        src={overIcon === name ? logoColor : logo}
                        alt={name}
                        className="t-max-h-20"
                      />
                    </a>
                  </motion.li>
                ))}
              </ul>

              <p
                className="Typography Typography--landingSubTitle t-text-center t-mt-5 md:t-px-10"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(description),
                }}
              />

              <div className="t-flex t-justify-center">
                <hr className="t-border t-border-beta-500 t-bg-beta-500 t-w-20 t-my-10" />
              </div>

              <div className="t-flex t-flex-col md:t-flex-row">
                <div className="t-max-w-md t-mr-5 t-z-50">
                  <ImageEnlargement src={screenShoot} alt={title} />
                </div>
                <div className="t-flex t-flex-col t-justify-around t-px-10 t-mt-8 md:t-mt-0">
                  {texts.map((text) => (
                    <p
                      className="Typography Typography--landingSubTitle t-font-semibold t-text-delta-900 t-text-center"
                      key={text}
                    >
                      {text}
                    </p>
                  ))}
                </div>
              </div>

              <div className="t-absolute t-left-0 t-right-0 -t-bottom-8 t-w-full t-flex t-justify-center t-z-30">
                <button
                  className="Button Button--primary Button--large t-uppercase"
                  onClick={() => {
                    scrollToElementId('FeedbackSection');
                  }}
                >
                  {t('reseller:schedule_demo_button')}
                </button>
              </div>
            </FadeInUpAnimation>
          </div>
        )
      )}
    </div>
  );
};

export default AdvantageSection;
