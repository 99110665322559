import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { range } from 'lodash';

import faq from 'images/faq.png';
import { ContextAwareToggle } from 'components';

function FaqPage() {
  const { t } = useTranslation(['translation', 'faq']);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const headRef = React.useRef<HTMLDivElement>(null);

  const getHeight = () => {
    if (headRef?.current?.clientHeight) {
      setHeaderHeight(headRef.current?.clientHeight);
    }
  };

  React.useLayoutEffect(() => {
    getHeight();
  }, []);

  window.onresize = getHeight;
  window.onload = getHeight;

  return (
    <main className="Page-content">
      <div className="Section Section--white" ref={headRef}>
        <div
          className="t-absolute t-left-0 t-bg-alpha-100 t-w-full t-h-full"
          style={{ height: `${headerHeight}px` }}
        />
        <div className="Section-content t-relative">
          <div className="FirstBlock">
            <div className="row u-h-100">
              <div className="col-lg-6 u-pt-10 u-pb-10 d-flex flex-column justify-content-center u-h-100">
                <h1 className="Typography Typography--landingMainTitle u-weight-700">
                  {t('faq:title')}
                </h1>
              </div>

              <div className="col-lg-6 t-hidden lg:t-flex justify-content-end u-h-100">
                <div className="FirstBlock-image md:-t-bottom-14 xl:-t-bottom-16 t-flex t-items-start">
                  <img
                    src={faq}
                    className="t-h-full"
                    alt="Different green leaves and big letters: F A Q"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Section Section--white">
        <div className="Section-content u-pt-100 u-pt-md-0">
          <Accordion defaultActiveKey="0" className="Accordion">
            <FaqTab tabNumber="1" faqCount={3} t={t} />
            <FaqTab tabNumber="2" faqCount={4} t={t} />
            <FaqTab tabNumber="3" faqCount={3} t={t} />
            <FaqTab tabNumber="5" faqCount={3} t={t} />
            <FaqTab tabNumber="6" faqCount={3} t={t} />
            <FaqTab tabNumber="7" faqCount={5} t={t} />
            <FaqTab tabNumber="4" faqCount={8} t={t} />
          </Accordion>
        </div>
      </div>

      <div className="Section Section--white">
        <div className="Section-content u-pt-60 u-pb-120 u-pt-md-30 u-pb-md-30">
          <div className="FaqContact">
            <h2 className="Typography Typography--landingQuote u-weight-700">
              {t('faq:contact_title')}
            </h2>

            <p className="Typography Typography--landingQuote u-weight-700 u-color-viridian">
              {t('faq:contact_description')}
            </p>

            <div className="FaqContact-button">
              <Link
                to="/contact"
                className="Button Button--primary d-inline-flex"
              >
                {t('faq:contact_button').toUpperCase()}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default FaqPage;

function FaqTab({ tabNumber, faqCount, t }) {
  const eventKey = tabNumber - 1;

  return (
    <div className="Accordion-item">
      <div className="Accordion-title">
        <h2 className="Accordion-titleText">
          {t(`faq:accordion_tab${tabNumber}`)}
        </h2>

        <div className="Button Button--secondary">
          <ContextAwareToggle eventKey={`${eventKey}`} />
        </div>
      </div>
      <Accordion.Collapse eventKey={`${eventKey}`}>
        <div className="u-pb-50">
          {range(faqCount).map((i) => (
            <React.Fragment key={i}>
              <h3 className="Typography Typography--landingQuote u-weight-700 u-pt-50 u-pb-24 u-color-amazon">
                {t(`faq:accordion_tab${tabNumber}_title${i + 1}`)}
              </h3>
              <p className="Typography Typography--landingMain u-preline">
                {t(`faq:accordion_tab${tabNumber}_title${i + 1}_description`)}
              </p>
            </React.Fragment>
          ))}
        </div>
      </Accordion.Collapse>
    </div>
  );
}
