import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import imgEndCustomer from 'images/img-end-customers.png';
import { ReactComponent as IconCheck } from 'images/icon-rounded-check.svg';

const EndCustomerSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const endCustomerFeatures = [
    t('reseller:end_customer_section_feature_1'),
    t('reseller:end_customer_section_feature_2'),
    t('reseller:end_customer_section_feature_3'),
  ];

  const navigateToHowPage = () => {
    navigate('/how-ninebarc-works');
  };

  return (
    <div className="t-mt-16 md:t-mt-40">
      <p className="Typography Typography--landingMainTitle u-weight-700">
        {t('reseller:end_customer_section_title')}
      </p>
      <div className="t-flex t-flex-col md:t-flex-row md:t-justify-between t-mt-12">
        <div className="md:t-ml-10 t-flex t-justify-center t-items-center">
          <div className="t-max-w-72 lg:t-max-w-96">
            <img src={imgEndCustomer} alt="End customers" />
          </div>
        </div>

        <div className="t-ml-4">
          <p className="Typography Typography--landingSubTitle t-text-delta-700 t-font-semibold t-mt-10 md:t-mt-0">
            {t('reseller:end_customer_section_subtitle')}
          </p>

          {endCustomerFeatures.map((feature) => (
            <div className="t-flex t-mt-8" key={feature}>
              <div className="t-mr-4 t-mt-0.5">
                <IconCheck className="t-w-8 t-h-8" />
              </div>
              <p className="Typography Typography--landingSubTitle t-max-w-sm">
                {feature}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="t-flex t-mt-20 t-justify-center md:t-justify-start">
        <button
          className="Button Button--primary Button--large t-uppercase"
          onClick={navigateToHowPage}
        >
          {t('reseller:end_customer_section_button')}
        </button>
      </div>
    </div>
  );
};

export default EndCustomerSection;
