import React from 'react';
import { Trans } from 'react-i18next';

interface SubscriptionButtonProps {
  onClick: () => void;
  dataToken?: string;
  isVisible?: boolean;
}

const SubscriptionButton = ({
  onClick,
  isVisible,
  dataToken,
}: SubscriptionButtonProps) => {
  return (
    <>
      <data
        id="mj-w-res-data"
        data-token={dataToken}
        className="mj-w-data"
        data-apikey="6eO0"
        data-w-id="HmB"
        data-lang="de_DE"
        data-base="https://app.mailjet.com"
        data-width="640"
        data-height="525.994"
        data-statics="statics"
      ></data>

      <div>
        <button
          className="mj-w-button mj-w-btn Button Button--primary"
          data-token={dataToken}
          onClick={onClick}
          type="button"
          style={{
            ...styleMjwBtn,
            display: isVisible ? 'inline-block' : 'none',
          }}
        >
          <div style={{ display: 'table', height: '34px' }}>
            <div style={{ display: ' table-cell', verticalAlign: 'middle' }}>
              <div className="mj-w-button-content" style={styleBtnContent}>
                <Trans i18nKey="subscribe_button1" />
              </div>
            </div>
          </div>
        </button>
      </div>
    </>
  );
};

export default SubscriptionButton;

const styleMjwBtn = {
  fontFamily: 'Helvetica',
  color: 'white',
  padding: '0px 25px',
  backgroundColor: 'rgb(63, 147, 106)',
  textAlign: 'center',
  verticalAlign: 'middle',
  borderRadius: '10px',
} as React.CSSProperties;

const styleBtnContent = {
  fontFamily: 'Helvetica',
  display: 'inline-block',
  textAlign: 'center',
  fontSize: '16px',
  verticalAlign: 'middle',
} as React.CSSProperties;
