import React from 'react';
import Carousel from 'react-elastic-carousel';
import 'components/common/Carousel/Carousel.scss';
import { ArrowButton } from 'components';

interface CarouselProps {
  children: React.ReactNode | React.ReactNode[];
  itemsToShow?: number;
  breakPoints?: any;
  initialActiveIndex?: number;
  adjustedBtnTop?: number;
  adjustedBtnSide?: number;
  adjustedBtnSideLeft?: number;
  enableAutoPlay?: boolean;
  isAnimated?: boolean;
  edgeButtonEnabled?: boolean;
}

export default function NinebarcCarousel({
  children,
  itemsToShow,
  breakPoints,
  initialActiveIndex,
  adjustedBtnTop,
  adjustedBtnSide,
  adjustedBtnSideLeft,
  enableAutoPlay,
  isAnimated,
  edgeButtonEnabled,
}: CarouselProps) {
  const defaultArrow = ({ type, onClick, isEdge }) => {
    const isPrev = type === 'PREV';
    return (
      <ArrowButton
        onClick={onClick}
        disabled={edgeButtonEnabled ? false : isEdge}
        isRight={!isPrev}
        extraClass="carousel-arrow t-absolute t-z-10 t-self-center"
        adjustedBtnTop={adjustedBtnTop}
        adjustedBtnSide={adjustedBtnSide}
        adjustedBtnSideLeft={adjustedBtnSideLeft}
        isAnimated={isAnimated}
      />
    );
  };

  const activeClassName = 't-w-2.5 t-h-2.5 t-bg-alpha-600';
  const inactiveClassName = 't-w-1.5 t-h-1.5 t-bg-alpha-200';

  return (
    <div>
      <Carousel
        isRTL={false}
        renderArrow={defaultArrow}
        initialActiveIndex={initialActiveIndex}
        itemsToShow={itemsToShow}
        breakPoints={breakPoints}
        enableAutoPlay={!!enableAutoPlay}
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <div className="t-flex t-mt-7 t-items-center">
              {pages.map((page) => {
                const isActivePage = activePage === page;
                return (
                  <div
                    className={`t-rounded-full t-mr-3 t-cursor-pointer ${
                      isActivePage ? activeClassName : inactiveClassName
                    }`}
                    onClick={() => onClick(page.toString())}
                  />
                );
              })}
            </div>
          );
        }}
      >
        {children}
      </Carousel>
    </div>
  );
}
