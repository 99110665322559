import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { ReactComponent as IconArrowDown } from 'images/icon-arrow-down.svg';

const liVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

type NavObject = {
  liClassName?: string;
  elClassName?: string;
  type?: string;
  text?: string;
  to?: string;
  href?: string;
};

interface DesktopNavProps {
  navElements: NavObject[];
  subNavElements: NavObject[];
}

function DesktopNav({ navElements, subNavElements }: DesktopNavProps) {
  const { t } = useTranslation();

  const [showSubMenu, setShowSubMenu] = useState(false);

  const onShowSubMenu = () => {
    setShowSubMenu(true);
  };

  const onCloseSubMenu = () => {
    setShowSubMenu(false);
  };

  return (
    <>
      <nav className="Header-navigation t-hidden xl:t-block">
        <ul className="Header-navigationList">
          {navElements.map(
            ({ liClassName, elClassName, type, text, to }, index) => (
              <motion.li
                className={liClassName}
                key={`nav-${index}`}
                variants={liVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                {type === 'link' && !!to && (
                  <Link to={to} className={elClassName}>
                    {text}
                  </Link>
                )}
              </motion.li>
            )
          )}
          <motion.div
            className="Header-navigationListItem"
            variants={liVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onMouseEnter={onShowSubMenu}
            onMouseLeave={onCloseSubMenu}
          >
            <button
              className="t-flex t-items-center t-appearance-none t-bg-transparent t-text-alpha-600 t-h-9 t-justify-center t-outline-none t-tracking-widest t-px-3 t-text-md t-mt-0.5"
              onClick={onShowSubMenu}
            >
              {t('nav_more')}
              <IconArrowDown className="t-ml-0.5 t-text-alpha-600 t-w-4 t-h-4" />
            </button>
          </motion.div>
        </ul>
      </nav>

      {showSubMenu && (
        <motion.div
          className="t-absolute -t-bottom-12 t-w-full t-pt-5 t-bg-transparent t-z-50 t-hidden lg:t-inline"
          initial={{ scale: 0.8, opacity: 0.5 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            duration: 0.3,
            ease: 'easeInOut',
          }}
          onMouseEnter={onShowSubMenu}
          onMouseLeave={onCloseSubMenu}
        >
          <ul className="t-bg-beta-50 t-z-50 t-w-full t-flex t-justify-center t-py-3 t-px-16 t-rounded">
            {subNavElements.map(
              ({ liClassName, type, text, to, href }, index) => (
                <motion.li
                  className={liClassName}
                  key={`nav-${index}`}
                  variants={liVariants}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {type === 'link' && !!to && (
                    <Link
                      to={to}
                      className="t-flex t-items-center t-appearance-none t-text-alpha-600 t-outline-none t-tracking-widest t-px-3 t-py-2 t-text-md t-mt-0.5 t-ml-2"
                      onClick={onCloseSubMenu}
                    >
                      {text}
                    </Link>
                  )}
                  {type === 'anchor' && (
                    <a
                      href={href}
                      className="t-flex t-items-center t-appearance-none t-text-alpha-600 t-outline-none t-tracking-widest t-px-3 t-py-2 t-text-md t-mt-0.5 t-ml-2"
                      onClick={onCloseSubMenu}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {text}
                    </a>
                  )}
                </motion.li>
              )
            )}
          </ul>
        </motion.div>
      )}
    </>
  );
}

export default DesktopNav;
