import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import {
  Carousel,
  IconDataSecurity,
  IconEmergencyAccess,
  IconLoveOnes,
  IconPersonalizedSupport,
} from 'components';

import { ReactComponent as CheckMark } from 'images/icon-checkmark.svg';

import ssl from 'images/logos/ssl.png';
import makeInGermany from 'images/logos/made-in-germany.png';
import allianz from 'images/logos/allianz-for-cyber-security.png';
import familie from 'images/logos/familie.png';
import bundesNotarkammer from 'images/logos/bundesnotarkammer.svg';

const securities = [
  {
    name: 'SSL SICHERE Verbindung',
    logo: ssl,
    className: 't-h-20',
  },
  {
    name: 'Made In Germany',
    logo: makeInGermany,
    className: 't-h-20',
  },
  {
    name: 'Allianz für Cyber-Sicherheit',
    logo: allianz,
    className: 't-h-20',
  },
  {
    name: 'Erfolgsfaktor Familie',
    logo: familie,
    className: 't-h-20',
  },
];

const WhatSection = () => {
  const { t } = useTranslation();

  const carouselBreakPoints = [
    {
      width: 1,
      itemsToShow: 1,
      itemsToScroll: 1,
      initialActiveIndex: 0,
    },
    {
      width: 768,
      itemsToShow: 1.32,
      itemsToScroll: 1,
      initialActiveIndex: 0,
    },
  ];

  const whatContentItems = [
    {
      title: 'what_section_text_item_1_title',
      textItems: [
        'what_section_text_item_1_1',
        'what_section_text_item_1_2',
        'what_section_text_item_1_3',
        'what_section_text_item_1_4',
      ],
      Icon: <IconDataSecurity className="t-max-w-60" />,
      learnMoreLink: '/security',
    },
    {
      title: 'what_section_text_item_2_title',
      description: 'what_section_text_item_2_description',
      textItems: [
        'what_section_text_item_2_1',
        'what_section_text_item_2_2',
        'what_section_text_item_2_3',
      ],
      Icon: <IconLoveOnes className="t-max-w-60" />,
    },
    {
      title: 'what_section_text_item_3_title',
      textItems: [
        'what_section_text_item_3_1',
        'what_section_text_item_3_2',
        'what_section_text_item_3_3',
      ],
      Icon: <IconEmergencyAccess className="t-max-w-60" />,
      corporationImage: bundesNotarkammer,
    },
    {
      title: 'what_section_text_item_4_title',
      textItems: [
        'what_section_text_item_4_1',
        'what_section_text_item_4_2',
        'what_section_text_item_4_3',
      ],
      Icon: <IconPersonalizedSupport className="t-max-w-60" />,
    },
  ];

  return (
    <div className="t-w-full t-mt-16 md:t-mt-40">
      <p className="Typography Typography--landingMainTitle u-weight-700 t-mb-6">
        {t('what_section_title')}
      </p>

      <Carousel
        breakPoints={carouselBreakPoints}
        adjustedBtnTop={64}
        adjustedBtnSide={8}
        adjustedBtnSideLeft={0.5}
      >
        {whatContentItems.map(
          (
            {
              title,
              description,
              textItems,
              Icon,
              corporationImage,
              learnMoreLink,
            },
            idx
          ) => (
            <div
              className="card t-shadow-lg t-w-full t-bg-gamma-400 t-flex t-flex-col md:t-flex-row t-px-3 t-py-5 md:t-pt-16 md:t-pb-10 md:t-pl-16 t-mb-4 t-mx-3 t-mt-3"
              key={title + `-${idx}`}
            >
              <div className="t-flex t-justify-center md:t-flex-none t-mt-2 md:t-mt-0">
                {Icon}
              </div>

              <div className="t-mx-8 t-mt-2 md:t-mt-0">
                <p className="t-text-left typo-alpha t-mt-2 md:t-mt-0">
                  {t(title)}
                </p>
                {description && (
                  <p className="t-mb-3 t-text-left typo-delta">
                    {t(description)}
                  </p>
                )}
                <div className="t-mb-3 t-whitespace-normal">
                  {textItems.map((text, i) => (
                    <p
                      key={text + `-${i}`}
                      className="typo-delta t-flex t-mt-2"
                    >
                      <CheckMark className="t-text-alpha-600 t-mr-1.5" />
                      <span>{t(text)}</span>
                    </p>
                  ))}
                </div>
                {corporationImage && (
                  <div className="t-mt-4">
                    <p className="typo-eta t-text-beta-700">
                      {t('what_section_corporation_with')}
                    </p>
                    <img
                      src={corporationImage}
                      className="t-h-8 t-mt-2"
                      alt="Ninebarc's partner"
                    />
                  </div>
                )}
                {learnMoreLink && (
                  <a
                    className="button-primary t-mt-3"
                    href={learnMoreLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('learn_more')}
                  </a>
                )}
              </div>
            </div>
          )
        )}
      </Carousel>

      <div className="t-mt-10">
        <p className="typo-alpha t-text-delta-900 t-text-center">
          {t('what_section_security_title')}
        </p>
        <div className="row t-w-full t-mt-5 md:t-mt-10">
          <div className="t-w-full">
            <ul className="t-flex t-items-center t-justify-center lg:t-justify-around t-flex-wrap">
              {securities.map(({ name, logo, className }) => (
                <li className="t-my-5 md:t-my-0 t-mx-5" key={name}>
                  <motion.img
                    src={logo}
                    alt={name}
                    className={className}
                    whileHover={{ scale: 1.5 }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatSection;
